export enum TalonEstatusEnum {
  NOTIMBRADO = 'Talón no timbrado',
  TIMBRADO = 'Talón timbrado',
  SINXML = 'Timbrado sin XML',
  NOCONTEMPLADO = 'Caso no contemplado',
  NOAPTO = 'No apto para timbrar',
  CONSTIMBRADO = 'Consecutivo timbrado',
  CONSNOTIMBRADO = 'Consecutivo no timbrado',
  CONSCANCELADO = 'Consecutivo cancelado',
  ERROR = 'Error de timbrado'
}
