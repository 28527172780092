import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DescuentoCorte } from '../../../interfaces/nomina-estibadores/descuento_corte';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AuthService } from '../../../authentication/login/auth.service';
import UtilsValidateFormat from '../../helpers/utils-validate-format';

const SISTEMA: number = 13;
const MODULO: number = 75;

@Component({
  selector: 'app-dialog-descuento-corte',
  templateUrl: './dialog-descuento-corte.component.html',
  styleUrls: ['./dialog-descuento-corte.component.css']
})
export class DialogDescuentoCorteComponent {
  private permisos;

  descuentoFormGroup: FormGroup;
  descuentoCorte: DescuentoCorte;

  constructor(public dialogRef: MatDialogRef<DialogDescuentoCorteComponent>,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { descuentoCorte: DescuentoCorte }) {
    this.permisos = this.authService.obtenerPermisosModulo(SISTEMA, MODULO);

    this.descuentoCorte = data.descuentoCorte;

    this.descuentoFormGroup = this.formBuilder.group({
      montoDescuentoControl: [this.descuentoCorte.montoDescuento, Validators.required],
      comentarioGeneraControl: [this.descuentoCorte.comentarioGenera, Validators.required]
    });

    if (this.descuentoCorte.idDescuento != null) {
      this.descuentoFormGroup.controls['montoDescuentoControl'].disable();
      this.descuentoFormGroup.controls['comentarioGeneraControl'].disable();
    }
  }

  cerrarDialogo(): void {
    const array = {
      generar: false
    };
    this.dialogRef.close(array);
  }

  Generar(): void {
    this.descuentoCorte.montoDescuento = this.descuentoFormGroup.controls['montoDescuentoControl'].value;
    this.descuentoCorte.comentarioGenera = this.descuentoFormGroup.controls['comentarioGeneraControl'].value;

    const array = {
      generar: true,
      descuentoCorte: this.descuentoCorte
    };
    this.dialogRef.close(array);
  }

  eliminar(): void {
    const array = {
      eliminar: true
    };
    this.dialogRef.close(array);
  }

  getDate(date: string): string {
    let fecha: Date = new Date(date);
    return fecha.toLocaleString('es-MX',
      {
        weekday: 'long',
        day: '2-digit'
      }
    );
  }

  getDateTime(date: string): string {
    let fecha: Date = new Date(date);
    return fecha.toLocaleString('es-MX',
      {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      }
    );
  }

  validateNumberFormatKeyPress(event: any) {
    UtilsValidateFormat.validateNumberFormatEvent(event);
  }

}
