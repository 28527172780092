import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatBottomSheet, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { takeUntil } from "rxjs/operators";
import Swal from 'sweetalert2';
import * as moment from 'moment';
//INTERFACES
import { CFDI } from './../../../interfaces/talones-oficina/cfdi';
import { Tr } from './../../../interfaces/talones-oficina/tr';
import { ErrorType } from "./../../../interfaces/correccion-timbrado/error-type";
//SERVICE
import { TalonesErrorService } from '../../../services/talones-error.service';
//COMPONENTS
import { CorreccionTimbradoLogComponent } from '../correccion-timbrado-log/correccion-timbrado-log.component';
import { BaseComponent } from "../../helpers/base.component";
//MODALS
import { ConfirmationDialogComponent } from './../../modals/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-correcion-timbrado',
  templateUrl: './correcion-timbrado.component.html',
  styleUrls: ['./correcion-timbrado.component.css']
})
export class CorrecionTimbradoComponent extends BaseComponent implements OnInit {

  private errorTimbrado: String = '';
  public errorcp128: String ='CP128 - El valor registrado en el  atributo “Ubicaciones:Ubicacion:RFCRemitenteDestinatario“ es incorrecto o no se encuentra en la lista de RFC inscritos no cancelados del SAT (l_RFC).';
  private errorXML: String = '';
  private messageStatusTalon: string = '';
  private rfcrem128='rfcremitente';
  private rfcdes128='rfcdestinatario';
  private errorType: ErrorType;
  private cfdi: CFDI;
  private showErrorTimbrado: boolean = false;
  private showLoading: Boolean = false;
  private noConnectionOffice: Boolean = false;
  private conditionalChangeStatus: boolean = false;
  private cfdiEstatusCero: boolean = false;
  private noErrorType: boolean = false;
  private showForm: boolean = false;
  private talonTimbradoSinXml: boolean = false;
  private talonSinCorregir: boolean = false;
  //-- Cookies
  private dataSession = JSON.parse(sessionStorage.getItem('usuario'));
  // Variable para sacar diferencia entre horas
  private get diffHours() {
    const now = moment();
    return now.diff(this.data.fecha, 'hours');
  }

  // Declaramos el Form group para usar nuestras validaciones
  formFixTalones: FormGroup = this.fb.group({
    fix: ['', [Validators.required, Validators.maxLength(50), Validators.minLength(1)]],
  });

  formFixTalonesrem128: FormGroup = this.fb.group({
    fixorigen: ['', [Validators.required, Validators.maxLength(50), Validators.minLength(1)]],
  });

  formFixTalonesdes128: FormGroup = this.fb.group({
    fixdestino: ['', [Validators.required, Validators.maxLength(50), Validators.minLength(1)]],
  });

  constructor(
    private dialogRef: MatDialogRef<CorrecionTimbradoComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialog: MatDialog,
    private talonesErrorService: TalonesErrorService,
    private fb: FormBuilder,
    private _bottomSheet: MatBottomSheet,
    snackBar: MatSnackBar
  ) { super(snackBar); }

  ngOnInit() {
    this.findErrorsByTalon();
  }

  /**
   * findErrorsByTalon: Consume el servicio para encontrar el error del talón o el motivo de no estar timbrado.
   *
   * @void
   * @author Moises Lopez Arrona [moisesarrona] 
   * @updated Alma Laura Meza M. [almalaurameza]
   * @since 2022-03-31
   */
  private findErrorsByTalon(): void {
    this.showLoading = true;
    const data = {
      claTalon: this.data.claTalon,
      idOficina: this.dataSession.claveOficina,
      claveOficina: this.data.claveoficina == '1543' ? '1548'
                                              :this.data.claveoficina == '1546' ? '1548'
                                              :this.data.claveoficina,
      consCcp: this.data.consCcp
    };

    this.talonesErrorService.findErrorsByTalon(data).pipe(takeUntil(this.unsubscribe$)).subscribe(
      (arg: any) => {
        this.cfdi = arg.cfdi;
        this.errorType = arg.tipoError;
        this.errorTimbrado = arg.talonError;
        this.errorXML = arg.xmlError;
      },
      (error: any) => {
        if (error == 'noConnection') {
          this.alertBase('error', 'Sin conexión a la oficina', error)
          this.noConnectionOffice = true;
        } else {
          this.openSnackBar('Problemas al cargar el error, íntentalo más tarde', '⛔', 5000);
        }
      }).add(() => {
        this.validations();
        this.showLoading = false;
      })
  }

  /**
   * validations: Función para validar los mensajes y opciones mostrados al usuario.
   *
   * @void
   * @author Alma Laura Meza M. [almalaurameza]
   * @since 2022-03-30
   */
  private validations(): void {
    if (this.cfdi) {

      switch (this.cfdi.estatus) {
        case 2:
          this.messageStatusTalon = 'Talón timbrado.';
          break;
        case 3:
          this.messageStatusTalon = 'El talón está pendiente de timbrar.';
          // Valida que el talón este pendiente de timbrar (3) y pase de las 72hrs
          this.conditionalChangeStatus = this.diffHours > 72 ? true : false;
          break;
      }

      if (this.errorType) {
        if (this.cfdi.estatus != 2) {
          this.cfdiEstatusCero = true;
          this.showForm = this.errorType.nombre != 'estatus4' && this.errorType.nombre != 'estatus3' ? true : false;
          this.talonTimbradoSinXml = this.errorType.nombre == 'estatus4' ? true : false;
          this.talonSinCorregir = this.errorType.nombre == 'estatus3' ? true : false;
        }
      } else {
        this.noErrorType = true;
      }

    } else {
      this.messageStatusTalon = this.noConnectionOffice ? 'No se puede establecer conexión con la oficina.'
        : 'El talón no se ha intentado timbrar ó algo esta fallando en el sistema, dirigirse con Tecnologías de la Información.';
    }
  }

  /**
   * fixErrorTalones: Consume el servicio para actualizar el campo del formulario por error detectado y posible de corregir.
   *
   * @params dataForm(rfcremitente ó rfcreceptor ó rfcdestinatario ó rfcoperador ó claveunidad ó licencia ó placa)
   * @void
   * @author Moises Lopez Arrona [moisesarrona] 
   * @updated Alma Laura Meza M. [almalaurameza]
   * @since 2022-03-31
   */
  private async fixErrorTalones(dataForm: any): Promise<any> {
    const proceedToUpdate = await this.confirmUpdate();
    if (!proceedToUpdate) { return; }

    this.formFixTalones.markAllAsTouched();
    this.formFixTalonesrem128.markAllAsTouched();
    this.formFixTalonesdes128.markAllAsTouched();

    if(this.formFixTalonesrem128.valid){
      const datarem128 = {
        idper_Fac: this.data.claTalon,
        rfc: dataForm.fixorigen,
        tipo: this.rfcrem128,
        idOficina: this.dataSession.claveOficina,
        claveOficina: this.data.claveoficina == '1543' ? '1548'
                     :this.data.claveoficina == '1546' ? '1548'
                     :this.data.claveoficina,
        cons_ccp: this.data.consCcp,
        idPersonal: this.dataSession.id,
        tipoPago:this.data.tipoPago,
        errorTimbrado:this.errorTimbrado,
        idtipo: this.cfdi.idtipo
      };
      const fieldRemitente = " el RFC Remitente "
      this.talonesErrorService.fixErrorRFC(datarem128).pipe(takeUntil(this.unsubscribe$)).subscribe(
        next => { this.alertaSuccess(fieldRemitente, dataForm.fixorigen); },
        error => { this.alertError(fieldRemitente, dataForm.fix, error); }
      );

    }

    if(this.formFixTalonesdes128.valid){
      const datades128 = {
        idper_Fac: this.data.claTalon,
        rfc: dataForm.fixdestino,
        tipo: this.rfcdes128,
        idOficina: this.dataSession.claveOficina,
        claveOficina: this.data.claveoficina == '1543' ? '1548'
                     :this.data.claveoficina == '1546' ? '1548'
                     :this.data.claveoficina,
        cons_ccp: this.data.consCcp,
        idPersonal: this.dataSession.id,
        tipoPago:this.data.tipoPago,
        errorTimbrado:this.errorTimbrado,
        idtipo: this.cfdi.idtipo
      };

      const fieldDestino = " el RFC Destinatario "

      this.talonesErrorService.fixErrorRFC(datades128).pipe(takeUntil(this.unsubscribe$)).subscribe(
        next => { this.alertaSuccess(fieldDestino,dataForm.fixdestino); },
        error => { this.alertError(fieldDestino, dataForm.fix, error); }
      );
    }
    
    if (this.formFixTalones.valid) {
      // Const data global
      const data = {
        idper_Fac: this.data.claTalon,
        rfc: dataForm.fix,
        claveunidad: dataForm.fix,
        licencia: dataForm.fix,
        placa: dataForm.fix,
        tipo: this.errorType.nombre,
        idOficina: this.dataSession.claveOficina,
        claveOficina: this.data.claveoficina == '1543' ? '1548'
                     :this.data.claveoficina == '1546' ? '1548'
                     :this.data.claveoficina,
        cons_ccp: this.data.consCcp,
        idPersonal: this.dataSession.id,
        tipoPago:this.data.tipoPago,
        errorTimbrado:this.errorTimbrado,
        idtipo: this.cfdi.idtipo
      };

      // Valida si el formulario corresponde a los RFC de ser así actualiza el campo
      if (this.errorType.nombre === 'rfcremitente' || this.errorType.nombre === 'rfcreceptor' || this.errorType.nombre === 'rfcdestinatario' || this.errorType.nombre === 'rfcoperador') {
        const field = "el RFC"
        this.talonesErrorService.fixErrorRFC(data).pipe(takeUntil(this.unsubscribe$)).subscribe(
          next => { this.alertaSuccess(field, dataForm.fix); },
          error => { this.alertError(field, dataForm.fix, error); }
        );
      }

      // Valida si el formulario corresponde Clave unidad de ser así actualiza el campo
      if (this.errorType.nombre === 'claveunidad') {
        const field = "la Clave Unidad"
        this.talonesErrorService.fixErrorClaveUnidad(data).pipe(takeUntil(this.unsubscribe$)).subscribe(
          next => { this.alertaSuccess(field, dataForm.fix) },
          error => { this.alertError(field, dataForm.fix, error) }
        );
      }

      // Valida si el formulario a Numero de Licencia de ser así actualiza el campo
      if (this.errorType.nombre === 'licencia') {
        const field = "el Número de Licencia"
        this.talonesErrorService.fixErrorLicencia(data).pipe(takeUntil(this.unsubscribe$)).subscribe(
          next => { this.alertaSuccess(field, dataForm.fix) },
          error => { this.alertError(field, dataForm.fix, error) }
        );
      }

      // Valida si el formulario a Numero de Licencia de ser así actualiza el campo
      if (this.errorType.nombre === 'placa') {
        const field = "la Placa"
        this.talonesErrorService.fixErrorPlaca(data).pipe(takeUntil(this.unsubscribe$)).subscribe(
          next => { this.alertaSuccess(field, dataForm.fix) },
          error => { this.alertError(field, dataForm.fix, error) }
        );
      }

    }
  }

  /**
   * alertaSuccess: Alerta para respuesta satisfactoria en el servicio de modifiar un dato del talón.
   *
   * @params fiel
   * @params fix
   * @void
   * @author Alma Laura Meza M. [almalaurameza]
   * @since 2022-Febrero
   */
  private alertaSuccess(field, fix) {
    Swal.fire({
      icon: 'success',
      title: 'Se editó ' + field,
      text: 'Se editó ' + field + ': ' + fix + ' para el Talón: ' + this.data.claTalon + ' Verifica la información en 5 min',
      showConfirmButton: true
    });
    this.dialogRef.close();
  }

  /**
   * alertError: Alerta para respuesta erronea en el servicio de modificar un dato del talón.
   *
   * @params fiel
   * @params fix
   * @params exception
   * @void
   * @author Alma Laura Meza M. [almalaurameza]
   * @since 2022-Febrero
   */
  private alertError(field, fix, exception): void {
    Swal.fire({
      icon: 'error',
      title: 'No se editó ' + field,
      text: 'No se editó' + field + ', intentalo más tarde los detalles son: ' + exception.message,
      showConfirmButton: true
    });
    this.openSnackBar('Problemas al editar ' + fix + ' Intentalo más tarde', '⛔', 5000);
  }

  /**
   * changeStatusTalon: Llama al servicio para cambiar el estatus a 3 del CFDI.
   *
   * @void
   * @author Moises Lopez Arrona [moisesarrona] 
   * @updated Alma Laura Meza M. [almalaurameza]
   * @since 2022-03-31
   */
  private async changeStatusTalon(): Promise<void> {
    const proceedToUpdate = await this.confirmUpdate();

    if (!proceedToUpdate) { return; }

    this.showLoading = true;

    const data = {
      idper_Fac: this.data.claTalon,
      claveOficina: this.data.idoficina,
    };

    this.talonesErrorService.changeStatusTalon(data).pipe(takeUntil(this.unsubscribe$)).subscribe(
      next => {
        this.findErrorsByTalon();
        this.alertBase('success', 'Todo correcto!', 'Se editó el estatus del talón: ' + data.idper_Fac);
      },
      error => {
        this.alertBase('error', 'Algo falló!', 'No se pudo editar el estatus del talón, intentalo más tarde');
      }).add(() => {
        this.showLoading = false;
      })
  }

  /**
   * changeStatusTalonXML: Llama al servicio para cambiar el estatus a 4 del CFDI de los talones timbrados sin XML.
   *
   * @void
   * @author Moises Lopez Arrona [moisesarrona]
   * @updated Alma Laura Meza M. [almalaurameza]
   * @since 2022-03-31
   */
  private async changeStatusTalonXML(): Promise<void> {
    const proceedToUpdate = await this.confirmUpdate();
    if (!proceedToUpdate) { return; }

    if (this.diffHours < 72) {

      this.showLoading = true;

      const data = {
        idper_Fac: this.data.claTalon,
        claveOficina: this.data.idoficina,
        idOficina: this.dataSession.claveOficina,
        cons_ccp: this.data.consCcp,
        idPersonal: this.dataSession.id
      };

      this.talonesErrorService.changeStatusTalonXML(data).pipe(takeUntil(this.unsubscribe$)).subscribe(
        next => {
          this.findErrorsByTalon();
          this.alertBase('success', 'Todo correcto!', 'Se editó el estatus del talón: ' + data.idper_Fac)
        },
        error => {
          this.alertBase('error', 'Algo falló!', 'No se pudo editar el estatus del talón, intentalo más tarde');
        }).add(() => {
          this.showLoading = false;
        });
    }
  }

  // Modal para confirmar las acciones
  private async confirmUpdate(): Promise<boolean> {
    if (!this.cfdi) {
      return true;
    }
    if (this.cfdi.estatus !== 3) {
      return true;
    }
    let confirmation = false;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'El talón aún se encuentra pendiente de timbrar',
        message: '¿Desea volver a intentarlo?',
        cancelButtonText: 'Cancelar',
        acceptButtonText: 'Sí'
      }
    });

    confirmation = await dialogRef.afterClosed().toPromise();
    return confirmation;
  }

  // Refresca la petición de econtrar detalles en el timbrado
  private refresh(): void {
    this.findErrorsByTalon();
  }
  // Mensajes de validaciones
  private getErrorForm() {
    return this.formFixTalones.get('fix').hasError('required') ? 'El campo es requerido' : ''
  }

  // Abre modal de histórico de correcciones
  protected openBottomSheet(): void {
    this._bottomSheet.open(CorreccionTimbradoLogComponent, {
      data: {
        claTalon: this.data.claTalon,
        typeSearch: "one"
      },
      panelClass: 'mat-bottom-sheet-custom'
    });
  }

  ngOnDestroy() {
    //Destruye las subscripciones cuando se destruye el componente
    super.ngOnDestroy();
  }
}