import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppsettingsComponent } from '../app-settings/appsettings.component';
import { PDFComplemento } from '../interfaces/PDFComplemento';
import { Trip } from '../interfaces/trip';
/*
* Título                    : complemento-carta-porte.service.ts
* Descripción               : Service para el apartado de Complemento Carta Porte
* Compañía                  : Transportes Castores de Baja California S.A. de C.V. – Área de Desarrollo
* Fecha de creación         : 11-11-2021
* Autor                     : Alan A. López M. [desarrolloti21]
* Versión                   : 1.0.0
* ID Requerimiento/Ticket   : NULL
*/

@Injectable({
  providedIn: 'root'
})
export class ComplementoCartaPorteService {

  constructor(private appsettings: AppsettingsComponent, private http: HttpClient) { }

  /*
  * Título                    : getTripFileByUnit
  * Descripción               : Obtención del pdf correspondiente a los complementos del viaje según su unidad.
  * Fecha de creación         : 11-11-2021
  * Autor                     : Alan A. López M. [desarrolloti21]
  * Versión                   : 1.0.0
  * ID Requerimiento/Ticket   : NULL
  */
  async getTripFileByUnit(unidad: string) {
    let result = null;
    try {
      const trip: Trip = await this.http.get<Trip>(this.appsettings.API_ENDPOINT + `talones/getLastTrip/${unidad}`).toPromise();
      const file = await this.getTripFile(trip.fileRoute);

      if (!file) { throw new Error('Error getting file.'); }

      trip.tripFile = file;

      result = trip;
    } catch (error) {
      console.error(error);
      result = null;
    } finally {
      return result;
    }
  }

  /*
  * Título                    : getTripBy
  * Descripción               : Obtención del pdf correspondiente a los complementos del viaje.
  * Fecha de creación         : 11-11-2021
  * Autor                     : Alan A. López M. [desarrolloti21]
  * Versión                   : 1.0.0
  * ID Requerimiento/Ticket   : NULL
  */
  async getTripByTrackingNumber(trackingNumber: string) {
    let result = null;
    try {
      const trip = await this.http.get<Trip>(this.appsettings.API_ENDPOINT + `talones/getTripByTrackingNo/${trackingNumber}`).toPromise();

      const file = await this.getTripFile(trip.fileRoute);

      if (!file) { throw new Error('Error getting file.'); }

      trip.tripFile = file;
      result = trip;
    } catch (error) {
      console.error(error);
      result = null;
    } finally {
      return result;
    }
  }

  async getTripFilepdf(factura: string,consecutivo:string) {
    let result = null;
    try {
      const formData = new FormData();
      const idoficina ="1100"
      const filepdf = await this.http.get<[{ b64: string; factura: string }]>(
        this.appsettings.WSFACTURACION + `/getPDFFacturas?facturas=${factura}&consecutivo=${consecutivo}&idoficina=${idoficina}`
      ).toPromise()
      if (!filepdf[0].b64) { throw new Error('Error Base64 no Encontrado.'); }
      result = filepdf[0].b64;
    } catch (error) {
      console.error(error);
      result = null;
    } finally {
      return result;
    }
  }


  /*
  * Título                    : getTripFile
  * Descripción               : Obtención del pdf según du ruta en el PDF.
  * Fecha de creación         : 29-11-2021
  * Autor                     : Alan A. López M. [desarrolloti21]
  * Versión                   : 1.0.0
  * ID Requerimiento/Ticket   : NULL
  */
  async getTripFile(fileRoute: string) {
    let result = null;
    try {
      const formData = new FormData();
      formData.append('route', fileRoute);
      const file = await this.http.post<{ file: string }>(
        this.appsettings.API_ENDPOINT + `archivos/ccp/getBase64FileFromSFTPServer`,
        formData
      ).toPromise();

      if (!file.file) { throw new Error('Error getting file.'); }

      result = file.file;
    } catch (error) {
      console.error(error);
      result = null;
    } finally {
      return result;
    }
  }

  /*
   * Título                    : receivePDFWhatsApp
   * Descripción               : Envia los parametros para enviar el PDF por whatsapp.
   * Fecha de creación         : 2021-12-2
   * Autor                     : Moises Arrona. [moisesarrona]
   * Versión                   : 1.0.0
   * ID Requerimiento/Ticket   : NULL
   */
  receivePDFWhatsApp(pdfComplemento: PDFComplemento): Observable<PDFComplemento> {
    return this.http.post(this.appsettings.API_ENDPOINT + 'archivos/receivePDFWhatsApp', pdfComplemento).pipe(
      map((response: any) => response as PDFComplemento),
      catchError(e => {
        // Badrequest validacion desde el backend
        if (e.status === 400) {
          return throwError(e);
        }
        if (e.error.mensaje) {
          console.error(e.error.mensaje);
        }
        return throwError(e);
      })
    );
  }

  /*
   * Título                    : getAllTalones
   * Descripción               : Obtiene todos los talones mas reciente en un rango de 10.
   * Fecha de creación         : 2021-12-6
   * Autor                     : Moises Arrona. [moisesarrona]
   * Versión                   : 1.0.0
   * ID Requerimiento/Ticket   : NULL
   */
  getAllTalones(talones): Observable<any> {
    return this.http.post(this.appsettings.API_ENDPOINT + 'talones/talonesFilteredByTr', talones).pipe(
      map((response: any) => response as any),
      catchError(e => {
        // Badrequest validacion desde el backend
        if (e.status === 400) {
          return throwError(e);
        }
        if (e.error.mensaje) {
          console.error(e.error.mensaje);
        }
        return throwError(e);
      })
    );
  }
}
