import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
  selector: "app-alerta-solicitud-eye",
  templateUrl: "./alerta-solicitud-eye.component.html",
  styleUrls: ["./alerta-solicitud-eye.component.css"],
})
export class AlertaSolicitudEyeComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AlertaSolicitudEyeComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      mensaje: string;
      aceptar: string;
      cerrar: string;
      icono: string;
      clase: string;
    }
  ) {}

  ngOnInit() {}

  aceptar() {
    this.dialogRef.close("aceptar");
  }

  cerrar() {
    this.dialogRef.close("cerrar");
  }
}
