import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'safeCurrency'
})
export class SafeCurrencyPipe implements PipeTransform {
  transform(value: number, currencyCode: string = 'MXN', display: string | boolean = 'symbol'): string {
    if (isNaN(value)) {
      return '0';
    }
    return new Intl.NumberFormat('es-MX', { style: 'currency', currency: currencyCode }).format(value);
  }
}