import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { Materiales } from "../../../interfaces/paqueteria/materiales";
import { EyeService } from "../../../services/eye.service";
import { Oficina } from "../../../interfaces/oficina";
import { OficinaService } from "../../../services/oficina.service";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { AuthService } from "../../../authentication/login/auth.service";
import { Usuario } from "../../../interfaces/usuario";
import { UsuarioService } from "../../../services/usuario.service";
import Swal from "sweetalert2";
import { ExistenciaMaterial } from "../../../interfaces/paqueteria/datos-inventario";
import { MovimientosKardexEyE } from "../../../interfaces/paqueteria/movimiento-kardex-eye";
import { UnidadMedida } from "../../../interfaces/paqueteria/unidad-medida";
import { EntradaMaterial } from "../../../interfaces/paqueteria/entrada-material";
import { Personal } from "../../../interfaces/paqueteria/personal";
import { SalidaMaterial } from "../../../interfaces/paqueteria/salida-material";
import { MaterialesInventario } from "../../../interfaces/paqueteria/materiales-inventario";

@Component({
  selector: "app-agregar-entrada",
  templateUrl: "./agregar-entrada.component.html",
  styleUrls: ["./agregar-entrada.component.css"],
})
export class AgregarEntradaComponent implements OnInit {
  formulario: FormGroup;
  lstMateriales: Materiales[] = [];
  lstPuntosVenta: Observable<Oficina[]>;
  lstOficinas: any[] = [];
  materialSeleccionado: Materiales;
  usuario: Usuario;
  deshabilitarGuardar = true;
  unidadMedida: string = "";
  lstUnidadesMedida: UnidadMedida[] = [];
  loading: boolean = false;
  tipoMovimiento: string = "";
  isCorporativo: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<AgregarEntradaComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { modo: string; pv: string; entrada: ExistenciaMaterial },
    private formBuilder: FormBuilder,
    private eyeService: EyeService,
    private oficinaService: OficinaService,
    private authService: AuthService,
    private usuarioService: UsuarioService
  ) {
    this.isCorporativo = this.authService.usuario.claveOficina == "1100" ? true : false;
    this.crearFormulario();
    this.getMateriales();
    this.getPuntosVenta();
    this.getUnidadesMedida();
    this.crearListeners();

    this.materialSeleccionado = {
      idMaterial: 0,
      claveMaterial: 0,
      nombre: "",
      descripcion: "",
      idUnidadMedida: 0,
      precioCompra: 0,
      precioVenta: 0,
      idPersonal: 0,
      fechaMod: "",
      horaMod: "",
      estatus: 0,
      medidaCompras: 0,
      medidaConversion: 0,
      validaExistencia: 0,
    };
  }

  ngOnInit() {
    this.obtenerUsuario();
    this.cargarDatosFormulario();
  }

  private _lstPuntosVenta(value: string): Oficina[] {
    const filterValue = value.toLowerCase();
    return this.lstOficinas.filter(
      (oficina) => oficina.plaza.toLowerCase().indexOf(filterValue) === 0
    );
  }

  crearFormulario() {
    const validadorNumerosRealesPositivos = Validators.pattern(/^[1-9]\d*(\.\d+)?$/);
    const validadorNumerosReales = Validators.pattern(/^-?[1-9]\d*(\.\d+)?$/);

    if (this.isCorporativo) {
      this.formulario = this.formBuilder.group({
        tipoMaterial: ["", Validators.required],
        cantidad: [
          "",
          [Validators.required, this.data.modo === "agregar" ? validadorNumerosRealesPositivos : validadorNumerosReales],
        ],
        cantidadModificada: [
          "",
          [Validators.required, validadorNumerosRealesPositivos],
        ],
        tipoMovimiento: ["", Validators.required],
        puntoVenta: ["", Validators.required],
      });
    } else {
      this.formulario = this.formBuilder.group({
        tipoMaterial: ["", Validators.required],
        cantidad: [
          "",
          [Validators.required, this.data.modo === "agregar" ? validadorNumerosRealesPositivos : validadorNumerosReales],
        ],
        tipoMovimiento: ["", Validators.required],
        puntoVenta: ["", Validators.required],
      });
    }
  }

  getMateriales() {
    this.eyeService.getMateriales().subscribe((response: Materiales[]) => {
      response.forEach((element) => {
        this.lstMateriales.push(element);
      });
    });
  }

  getUnidadesMedida() {
    this.eyeService
      .getUnidadesMedida()
      .subscribe((lstUnidades: UnidadMedida[]) => {
        this.lstUnidadesMedida = lstUnidades;
      });
  }

  getPuntosVenta() {
    this.oficinaService.findOficinasByAll().subscribe((response: any[]) => {
      response.forEach((element) => {
        this.lstOficinas.push(element);
      });
    });
  }

  crearListeners() {
    this.lstPuntosVenta = this.formulario.get("puntoVenta").valueChanges.pipe(
      startWith(""),
      map((oficina) =>
        oficina ? this._lstPuntosVenta(oficina) : this.lstOficinas.slice()
      )
    );
  }

  obtenerUsuario() {
    this.usuarioService
      .getUsuarioByIdUsuario(this.authService.usuario.id)
      .subscribe((response: Usuario) => {
        this.usuario = response;
      });
  }

  cargarDatosFormulario() {
    if (this.data.modo == "agregar") {
      this.formulario.reset({
        puntoVenta: this.data.pv,
      });
    } else {
      this.formulario.reset({
        tipoMaterial: this.data.entrada.material,
        cantidad: this.data.entrada.cantidad,
        puntoVenta: this.data.pv,
      });

      this.unidadMedida = this.data.entrada.unidadMedida;
    }
  }

  compararMateriales(material1: string, material2: string) {
    return material1 && material2 && material1 == material2;
  }

  get materialValido() {
    return (
      this.formulario.get("tipoMaterial").invalid &&
      this.formulario.get("tipoMaterial").touched
    );
  }

  get cantidadValida() {
    return (
      this.formulario.get("cantidad").invalid &&
      this.formulario.get("cantidad").touched
    );
  }

  get movimientoValido() {
    return (
      this.formulario.get("tipoMovimiento").invalid &&
      this.formulario.get("tipoMovimiento").touched
    );
  }

  get puntoVentaValido() {
    return (
      this.formulario.get("puntoVenta").invalid &&
      this.formulario.get("puntoVenta").touched
    );
  }

  onSelect(nombreMaterial: string) {
    const material: Materiales = this.lstMateriales.find((resp) => {
      return resp.nombre === nombreMaterial;
    });
    const unidad: UnidadMedida = this.lstUnidadesMedida.find((resp) => {
      return resp.idUnidadMedida == material.idUnidadMedida;
    });

    this.materialSeleccionado = material;
    this.unidadMedida =
      unidad != undefined
        ? this.eyeService.convertirUnidadMedida(
            material.idMaterial,
            unidad.nombre
          )
        : "";
    this.deshabilitarGuardar = false;
  }

  onSubmit() {
    if (this.formulario.invalid) return;

    const puntoVentaForm = this.formulario.get("puntoVenta").value;
    const puntoVentas = this.lstOficinas.find((result) => {
      return result.plaza === puntoVentaForm;
    });

    if (puntoVentas == undefined) {
      this.formulario.get("puntoVenta").setErrors({ incorrect: true });
      return;
    }

    Swal.fire({
      title: "Confirmar acción",
      text: `¿Seguro que desea guardar los cambios para el punto de venta ${puntoVentas.plaza}?`,
      icon: "warning",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: "#588157",
      cancelButtonColor: "#c9184a",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then((response) => {
      if (!response.isConfirmed) return;

      this.guardarEntradaSalida(puntoVentas);
    });
  }

  guardarMovimiento(puntoVenta: any, tipoMov: number) {
    const movimiento: MovimientosKardexEyE = {
      idMovimiento: 0,
      idOficina: puntoVenta.idOficina,
      idTipoMovimiento: tipoMov,
      idMaterial: this.materialSeleccionado.idMaterial,
      cantidad: parseInt(this.formulario.get("cantidad").value),
      idPersonal: this.usuario.personal.idPersonal,
      fecha: new Date().toLocaleDateString(),
      hora: new Date().toLocaleTimeString(),
    };

    this.eyeService
      .guardarMovimiento(movimiento)
      .subscribe((movimientoGuardado: MovimientosKardexEyE) => {
        Swal.fire({
          title: "La entrada se agregó correctamente!",
          icon: "success",
          showConfirmButton: true,
        }).then((response) => this.dialogRef.close());
      });
  }

  onChange() {
    this.deshabilitarGuardar = false;
  }

  cancelar() {
    this.dialogRef.close();
  }

  guardarEntradaSalida(pv: any) {
    this.loading = true;
    var cantidadModificada: number = this.isCorporativo
      ? this.formulario.get("cantidadModificada").value
      : this.formulario.get("cantidad").value;
    var cantEntrada = 0;
    var cantSalida = 0;

    if (this.formulario.get("tipoMovimiento").value == "3") {
      this.tipoMovimiento = "Entrada por ajuste";
      cantEntrada = cantidadModificada;
    } else if (this.formulario.get("tipoMovimiento").value == "7") {
      this.tipoMovimiento = "Salida por ajuste";
      cantSalida = cantidadModificada;
    } else if (this.formulario.get("tipoMovimiento").value == "8") {
      this.tipoMovimiento = "Entrada recepción de material";
      cantEntrada = this.eyeService.convertirCantidad(
        this.materialSeleccionado.nombre,
        cantidadModificada,
        this.unidadMedida
      );
    }

    if (this.data.modo == "agregar") {
      this.eyeService
        .getMaterialesInventarioById(
          pv.clave,
          this.materialSeleccionado.idMaterial
        )
        .subscribe(
          (materialInv: MaterialesInventario) => {
            if (materialInv.idMaterial == 0) {
              this.guardarMaterialesInventario(
                pv,
                cantEntrada,
                cantSalida,
                cantidadModificada
              );
            } else {
              this.actualizarMaterialesInventario(
                pv,
                cantEntrada,
                cantSalida,
                cantidadModificada
              );
            }
          },
          (error) => {
            Swal.fire({
              title: "Error!",
              text: `Ocurrió un error al validar los datos del material. ${error.message}`,
              icon: "error",
              showConfirmButton: true,
            }).then((resp) => {
              this.loading = false;
              this.dialogRef.close();
            });
          }
        );
    } else {
      this.actualizarMaterialesInventario(
        pv,
        cantEntrada,
        cantSalida,
        cantidadModificada
      );
    }
  }

  guardarMaterialesInventario(puntoVenta: any,cantidadEntrada: number,cantidadSalida: number,nuevaCantidad: number) {
    const materialInv: MaterialesInventario = {
      idMaterial: this.materialSeleccionado.idMaterial,
      cantEntrada: cantidadEntrada,
      cantSalida: cantidadSalida,
      idOficina: puntoVenta.clave,
    };

    this.eyeService
      .guardarMaterialesInventario(puntoVenta.clave, materialInv)
      .subscribe(
        (response: boolean) => {
          if (response) {
            this.guardarEntradaMaterial(nuevaCantidad);
          } else {
            Swal.fire({
              title: "Algo salió mal!",
              text: `No fue posible guardar la ${this.tipoMovimiento}`,
              icon: "warning",
              showConfirmButton: true,
            }).then((response) => {
              this.loading = false;
              this.dialogRef.close();
            });
          }
        },
        (error) => {
          Swal.fire({
            title: "Error!",
            text: `Ocurrió un error al guardar la ${this.tipoMovimiento}. ${error.message}`,
            icon: "error",
            showConfirmButton: true,
          }).then((response) => {
            this.loading = false;
            this.dialogRef.close();
          });
        });
  }

  actualizarMaterialesInventario(puntoVenta: any,cantidadEntrada: number,cantidadSalida: number,nuevaCantidad: number) {
    const idMaterial = this.data.entrada == null? this.materialSeleccionado.idMaterial: this.data.entrada.idMaterial;

        if (['2', '3', '8'].includes(this.formulario.get("tipoMovimiento").value)) {
          this.guardarEntradaMaterial(cantidadEntrada);
        } else if (this.formulario.get("tipoMovimiento").value == "7") {
          this.guardarSalidaMaterial(nuevaCantidad);
        }

  }

  guardarEntradaMaterial(nuevaCantidad: number) {
    var precioCompra = 0;
    const puntoVenta: any = this.lstOficinas.find((response) => {
      return response.plaza === this.data.pv;
    });

    if (this.materialSeleccionado.idMaterial == 0) {
      precioCompra = this.data.entrada.precioUnitario;
    } else {
      const material: Materiales = this.lstMateriales.find((response) => {
        return response.idMaterial == this.materialSeleccionado.idMaterial;
      });
      precioCompra = material.precioCompra;
    }

    this.eyeService
      .getPersonalCorpoByIdUsuario(this.authService.usuario.username)
      .subscribe((personal: Personal) => {
        const entrada: EntradaMaterial = {
          idEntrada: 0,
          idMaterial: this.materialSeleccionado.idMaterial == 0? this.data.entrada.idMaterial: this.materialSeleccionado.idMaterial,
          cantidad: nuevaCantidad,
          idPersonal: personal.idPersonal,
          fechaMod: new Date().toLocaleDateString(),
          horaMod: new Date().toLocaleTimeString(),
          precioCompra: precioCompra,
          idTipoMovimiento: this.formulario.get("tipoMovimiento").value,
          noNota: "0",
          idPoliza: -2,
          idOficina: puntoVenta.clave,
        };

        this.eyeService.guardarEntradaMaterial(puntoVenta.clave, entrada).subscribe(
          (response: boolean) => {
            if (!response) {
              Swal.fire({
                title: "Algo salió mal!",
                text: `No fue posible guardar la ${this.tipoMovimiento}`,
                icon: "warning",
                showConfirmButton: true,
              }).then((response) => {
                this.loading = false;
              });
            }
          },
          (error) => {
            this.dialogRef.close();
            Swal.fire({
              title: "Error",
              text: `Ocurrió un error al guardar la ${this.tipoMovimiento}`,
              icon: "error",
              showConfirmButton: true,
            }).then((response) => {
              this.loading = false;
            });
          },
          () => {
            this.dialogRef.close();
          }
        );
      });
  }

  guardarSalidaMaterial(nuevaCantidad) {
    const puntoVenta: any = this.lstOficinas.find((response) => {
      return response.plaza === this.data.pv;
    });

    const material: Materiales = this.lstMateriales.find((response) => {
      return (
        response.idMaterial ==
        (this.materialSeleccionado.idMaterial == 0
          ? this.data.entrada.idMaterial
          : this.materialSeleccionado.idMaterial)
      );
    });

    this.eyeService
      .getPersonalCorpoByIdUsuario(this.authService.usuario.username)
      .subscribe((personal: Personal) => {
        const salida: SalidaMaterial = {
          idSalidaMaterial: 0,
          idMaterial:
            this.materialSeleccionado.idMaterial == 0
              ? this.data.entrada.idMaterial
              : this.materialSeleccionado.idMaterial,
          cantidad: nuevaCantidad,
          idEmbalaje: -2,
          idOficinaEmbalaje: puntoVenta.clave,
          idEmbalajeCo: -2,
          idDetalle: -2,
          idEmbalajeAdicional: 0,
          idPersonal: personal.idPersonal,
          fechaMod: new Date().toLocaleDateString(),
          horaMod: new Date().toLocaleTimeString(),
          idPoliza: -2,
          precioVenta: material.precioVenta,
          idTipoMovimiento: this.formulario.get("tipoMovimiento").value,
          precioCompra: material.precioCompra,
        };

        this.eyeService
          .guardarSalidaMaterial(puntoVenta.clave.toString(), salida)
          .subscribe(
            (response: boolean) => {
              if (!response) {
                Swal.fire({
                  title: "Algo salió mal!",
                  text: `No fue posible guardar la ${this.tipoMovimiento}`,
                  icon: "warning",
                  showConfirmButton: true,
                }).then((response) => {
                  this.loading = false;
                });
              }
            },
            (error) => {
              Swal.fire({
                title: "Error",
                text: `Ocurrió un error al guardar la ${this.tipoMovimiento}`,
                icon: "error",
                showConfirmButton: true,
              }).then((response) => {
                this.loading = false;
              });
            },
            () => {
              this.dialogRef.close();
            }
          );
      });
  }
}
