
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../auth.service';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
/** Pass untouched request through to the next request handler. */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) { }
  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(e => {
      /*  alert(e.status)
        if (e.status == 500) {
          alert("Vuelve a intertarlo, no hubo respuesta del servidor")
        }*/

        if (e.status == 401) {
          if (this.authService.isAuthenticated()) {
            this.authService.cerrarSesion();
          }
          this.router.navigate(['/login'])

        }
        if (e.status == 403) {
          console.log('error 403');
          //swal.fire('Permisos invalidos', 'Tu usuario no tiene ese permiso', 'warning');
          this.router.navigate(['/dashboard'])

        }
        return throwError(e);

      })
    );
  }
}
