import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from "@angular/material";
import { iSolicitudEyE } from "../../../interfaces/paqueteria/solicitud-material-eye";
import { EyeService } from "../../../services/eye.service";
import { Materiales } from "../../../interfaces/paqueteria/materiales";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "../../../authentication/login/auth.service";
import { OficinaService } from "../../../services/oficina.service";
import { OficinaCompleta } from "../../../interfaces/paqueteria/Oficina";
import { UnidadMedida } from "../../../interfaces/paqueteria/unidad-medida";
import { Talones } from "../../../interfaces/paqueteria/talones";
import { Tr } from "../../../interfaces/talones-oficina/tr";
import Swal from "sweetalert2";
import { TalonEnviadoEye } from "../../../interfaces/paqueteria/talon-enviado";
import { Solicitudes_Eye } from "../../../interfaces/paqueteria/solicitudes-eye";
import { TipoEmpaque } from "../../../interfaces/paqueteria/tipo-empaque";
import { CantidadesMaterialPermitido } from "../../../interfaces/paqueteria/cantidades-material-permitido";
import { EmpaquesUtilizados } from "../../../interfaces/paqueteria/empaques-utilizados";
import { cadenaMaterialesReacondicionamientoOficina2, cadenaMaterialesReacondicionamientoOficina3, cadenaMaterialesReacondicionamientoTodas, cadenaMaterialesVenta } from "../../helpers/helpers";

@Component({
  selector: "app-agregar-material-eye",
  templateUrl: "./agregar-material-eye.component.html",
  styleUrls: ["./agregar-material-eye.component.css"],
})
export class AgregarMaterialEyeComponent implements OnInit {
  @ViewChild("fileInput", { static: true }) fileInput: ElementRef;

  modo: string;
  forma: FormGroup;
  lstMateriales: Materiales[] = [];
  tipoSolicitud: string;
  mostrarInputTalon: boolean = true;
  unidadMedida: string;
  archivoAnexado: File = null;
  desactivarObservaciones: boolean;
  existeAnexo: boolean;
  labelAnexo: string;
  oficina: OficinaCompleta = {} as OficinaCompleta;
  lstUnidadesMedida: UnidadMedida[] = [];
  accion: string = "";
  progressBar: boolean = false;
  lstTipoEmpaque: TipoEmpaque[] = [];

  constructor(
    public dialogRef: MatDialogRef<AgregarMaterialEyeComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dialogo: {
      tipoSolicitud: number;
      datosMaterial: iSolicitudEyE;
      materialesAgregados: iSolicitudEyE[];
      modo: string;
    },
    private formBuilder: FormBuilder,
    private eyeService: EyeService,
    private authService: AuthService,
    private oficinaService: OficinaService,
    private snack: MatSnackBar
  ) {
    this.crearFormulario();
    this.crearListeners();
  }

  ngOnInit() {
    if (this.dialogo.tipoSolicitud == 1) {
      this.tipoSolicitud = "venta";
      this.cargarDatosAlFormulario();
    } else {
      this.tipoSolicitud = "reacondicionamiento";
      this.getTipoEmpaque();
    }

    this.modo = this.dialogo.modo;
    this.getOficina();
  }

  get talonNoValido() {
    return this.forma.get("talon").invalid && this.forma.get("talon").touched;
  }

  get empaqueNoValido() {
    return (
      this.forma.get("empaque").invalid && this.forma.get("empaque").touched
    );
  }

  get cantidadEmpaqueNoValida() {
    return (
      this.forma.get("cantidadEmpaque").invalid &&
      this.forma.get("cantidadEmpaque").touched
    );
  }

  get materialNoValido() {
    return (
      this.forma.get("materiales").invalid &&
      this.forma.get("materiales").touched
    );
  }

  get cantidadNoValida() {
    return (
      this.forma.get("cantidad").invalid && this.forma.get("cantidad").touched
    );
  }

  get observacionesNoValidas() {
    return (
      this.forma.get("observaciones").invalid &&
      this.forma.get("observaciones").touched
    );
  }

  compararEmpaques(empaque1: TipoEmpaque, empaque2: TipoEmpaque) {
    return (
      empaque1 && empaque2 && empaque1.idTipoEmpaque == empaque2.idTipoEmpaque
    );
  }

  compararMateriales(material1: Materiales, material2: Materiales) {
    return (
      material1 && material2 && material1.idMaterial == material2.idMaterial
    );
  }

  crearFormulario() {
    if (this.dialogo.tipoSolicitud == 1) {
      this.mostrarInputTalon = false;

      this.forma = this.formBuilder.group({
        materiales: [null, Validators.required],
        cantidad: [
          "",
          [Validators.required, Validators.pattern(/^[1-9]\d*(\.\d+)?$/)],
        ],
        observaciones: ["", Validators.required],
        anexo: [""],
      });
    } else {
      this.forma = this.formBuilder.group({
        talon: [
          "",
          [
            Validators.required,
            Validators.minLength(10),
            Validators.pattern(/^-?(0|[0-9]\d*)?$/),
          ],
        ],
        empaque: ["", Validators.required],
        cantidadEmpaque: [
          "",
          [Validators.required, Validators.pattern(/^[1-9]\d*(\.\d+)?$/)],
        ],
        materiales: ["", Validators.required],
        cantidad: [
          "",
          [Validators.required, Validators.pattern(/^[1-9]\d*(\.\d+)?$/)],
        ],
        observaciones: ["", Validators.required],
        anexo: [""],
      });
    }
  }

  crearListeners() {
    this.forma
      .get("materiales")
      .valueChanges.subscribe((valorMaterial: Materiales) => {
        if (valorMaterial) {
          const unidad: UnidadMedida = this.lstUnidadesMedida.find((resp) => {
            return resp.idUnidadMedida == valorMaterial.idUnidadMedida;
          });

          if (this.dialogo.tipoSolicitud == 1) {
            this.unidadMedida =
              unidad != undefined && unidad.idUnidadMedida != 5 || valorMaterial.idMaterial == 7
                ? this.eyeService.convertirUnidadMedida(
                    valorMaterial.idMaterial,
                    unidad.nombre
                  )
                : unidad.nombre;
          } else {
            this.unidadMedida = unidad != undefined ? unidad.nombre : "";
          }
        }
      });

    if (this.dialogo.tipoSolicitud === 2) {
      this.forma.get("talon").valueChanges.subscribe((valorTalon: string) => {
        if (valorTalon != null && valorTalon.length >= 10) {
          this.getMateriales();
        }
      });
    }
  }

  getTipoEmpaque() {
    this.eyeService.getTipoEmpaque().subscribe((lstTipoEmp: TipoEmpaque[]) => {
      this.lstTipoEmpaque = lstTipoEmp;
      this.cargarDatosAlFormulario();
    });
  }

  getOficina() {
    this.oficinaService
      .findOficinaByClave(this.authService.usuario.claveOficina)
      .subscribe((oficina: OficinaCompleta) => {
        this.oficina = oficina;
        this.getMateriales();
      });
  }

  getMateriales() {
    let cadenaMateriales: string[] = [];
    if (!this.oficina) { 
      this.snack.open(`No se pudo obtener la información de la oficina`, "⚠️", {
        duration: 3000,
      });
      this.dialogRef.close();
      return;
    }
    if (this.dialogo.tipoSolicitud == 1) {
      //Tipo venta
      cadenaMateriales = cadenaMaterialesVenta;
    } else {
      // Tipo Reacondicionamiento
      if (this.oficina.idTipoOficina == 2) {
        cadenaMateriales = cadenaMaterialesReacondicionamientoOficina2;
      } else if (this.oficina.idTipoOficina == 3) {
        cadenaMateriales = cadenaMaterialesReacondicionamientoOficina3;
      } else {
        cadenaMateriales = cadenaMaterialesReacondicionamientoTodas;
      }
    }

    this.eyeService
      .getMaterialesByNombre(cadenaMateriales)
      .subscribe((lstMaterialesBd: Materiales[]) => {
        this.lstMateriales = [];
        const materialesAgregados: Materiales[] = [];

        if (this.dialogo.materialesAgregados.length > 0) {
          this.dialogo.materialesAgregados.forEach((value) => {
            if (this.dialogo.tipoSolicitud === 1) {
              materialesAgregados.push(value.material);
            } else {
              if (value.talon === this.forma.get("talon").value.toString()) {
                materialesAgregados.push(value.material);
              }
            }
          });

          lstMaterialesBd.forEach((materialBd) => {
            const materialAgregado = materialesAgregados.find((value) => {
              return materialBd.idMaterial === value.idMaterial;
            });

            if (materialAgregado === undefined) {
              this.lstMateriales.push(materialBd);
            } else if (
              materialAgregado.idMaterial ===
              this.dialogo.datosMaterial.material.idMaterial
            ) {
              this.lstMateriales.push(materialBd);
            }
          });
        } else {
          this.lstMateriales = lstMaterialesBd;
        }

        this.getUnidadMedida();
      });
  }

  getUnidadMedida() {
    this.eyeService
      .getUnidadesMedida()
      .subscribe((lstUnidades: UnidadMedida[]) => {
        this.lstUnidadesMedida = lstUnidades;
      });
  }

  cargarDatosAlFormulario() {
    if (this.dialogo.modo == "agregar") {
      this.forma.reset({
        talon: this.dialogo.datosMaterial.talon,
        observaciones: this.dialogo.datosMaterial.observaciones,
        anexo: this.dialogo.datosMaterial.anexo,
      });

      if (this.forma.get("observaciones").value.length > 0) {
        this.desactivarObservaciones = true;
      }

      if (this.forma.get("anexo").value.length > 0) {
        this.existeAnexo = true;
        this.labelAnexo = "Archivo anexado:";
      } else {
        this.existeAnexo = false;
        this.labelAnexo = "Anexar archivo (opcional)";
      }
    } else {
      //Modo Editar
      if (this.dialogo.tipoSolicitud == 1) {
        this.forma.reset({
          materiales: this.dialogo.datosMaterial.material,
          cantidad: this.dialogo.datosMaterial.cantidadMaterial,
          observaciones: this.dialogo.datosMaterial.observaciones,
          anexo: this.dialogo.datosMaterial.anexo.includes("http") ? this.dialogo.datosMaterial.anexo.split("/").pop() : this.dialogo.datosMaterial.anexo,
        });
      } else {
        const tipoEmpaque: TipoEmpaque = this.lstTipoEmpaque.find((resp) => {
          return (
            resp.idTipoEmpaque ==
            this.dialogo.datosMaterial.empaque.idTipoEmpaque
          );
        });

        this.forma.reset({
          talon: this.dialogo.datosMaterial.talon,
          empaque: tipoEmpaque,
          cantidadEmpaque: this.dialogo.datosMaterial.empaque.cantidad,
          materiales: this.dialogo.datosMaterial.material,
          cantidad: this.dialogo.datosMaterial.cantidadMaterial,
          observaciones: this.dialogo.datosMaterial.observaciones,
          anexo: this.dialogo.datosMaterial.anexo.includes("http") ? this.dialogo.datosMaterial.anexo.split("/").pop() : this.dialogo.datosMaterial.anexo,
        });
      }

      if (this.forma.get("anexo").value.length > 0) {
        this.labelAnexo = "Archivo anexado:";
      } else {
        this.labelAnexo = "Anexar archivo (opcional)";
      }

      this.unidadMedida = this.dialogo.datosMaterial.unidadMedida;
    }
  }

  subirArchivo() {
    if (this.existeAnexo) return;

    this.fileInput.nativeElement.click();
  }

  onFileSelected(archivo: File[]) {
    this.forma.get("anexo").setValue(archivo[0].name.includes("http") ? archivo[0].name.split("/").pop() : archivo[0].name);
    this.archivoAnexado = archivo[0];
  }

  agregarMaterial() {
    if (this.forma.invalid) return;

    this.progressBar = true;

    if (this.dialogo.tipoSolicitud == 1) {
      this.agregar();
    } else {
      this.accion = "agregar";
      this.validarTalon(this.forma.get("talon").value.toString());
    }
  }

  validarTalon(claTalon: string) {
    this.eyeService.getTalonByClaTalon(claTalon).subscribe((talon: Talones) => {
      if (talon.claTalon == null) {
        this.progressBar = false;
        this.forma.get("talon").setErrors(Validators);
      } else {
        this.eyeService
          .getTrByClaTalon(talon.tabla.toString(), talon.claTalon.toString())
          .subscribe((tr: Tr) => {
            const fechaTr = new Date(
              `${tr.fecha.toString()}T${tr.hora.toString()}`
            );
            const resta = Math.round(
              (new Date().getTime() - fechaTr.getTime()) / (1000 * 60 * 60 * 24)
            );

            if (resta > 90) {
              Swal.fire({
                position: "center",
                icon: "warning",
                title: `El talón ${tr.claTalon.toString()} tiene una vigencia mayor a 90 días`,
                showConfirmButton: true,
              }).then((resp) => {
                this.progressBar = false;
                this.forma.get("talon").setErrors(Validators);
              });
            } else {
              this.getTalonEnviadoByNumeroTalon(claTalon);
            }
          });
      }
    });
  }

  getTalonEnviadoByNumeroTalon(numeroTalon: string) {
    this.eyeService
      .getTalonEnviadoByNumeroTalon(numeroTalon)
      .subscribe((talonEnv: TalonEnviadoEye) => {
        if (talonEnv.idTalonEnviado > 0) {
          this.eyeService
            .getSolicitudById(talonEnv.idSolicitud)
            .subscribe((solicitud: Solicitudes_Eye) => {
              Swal.fire({
                position: "center",
                icon: "warning",
                title: `El talón ${numeroTalon} ya fue utilizado en la solicitud con folio ${solicitud.folio}`,
                showConfirmButton: true,
              }).then((resp) => {
                this.progressBar = false;
                this.forma.get("talon").setErrors(Validators);
              });
            });
        } else {
          if (this.accion === "agregar") {
            this.validarCantidadesPermitidas();
          }
        }
      });
  }

  validarCantidadesPermitidas() {
    const material: Materiales = this.forma.get("materiales").value;
    const tipoEmpaque: TipoEmpaque = this.forma.get("empaque").value;
    this.eyeService
      .getCantidadMaterialPermitido(
        material.idMaterial,
        tipoEmpaque.idTipoEmpaque
      )
      .subscribe(
        (cantidadMaterialPer: CantidadesMaterialPermitido) => {
          const cantMaterial: number = this.forma.get("cantidad").value;
          const cantEmpaque: number = this.forma.get("cantidadEmpaque").value;
          const cantPermitida: number =
            cantEmpaque * cantidadMaterialPer.cantidad;

          if (cantMaterial > cantPermitida) {
            Swal.fire({
              title: "Cantidad no permitida",
              text: "La cantidad del material no es válida, verifíquelo por favor",
              icon: "warning",
              showConfirmButton: true,
            }).then((resp) => {
              this.progressBar = false;
            });
          } else {
            this.agregar();
          }
        },
        (error) => {
          this.snack.open(
            `El material o empaque seleccionados no están autorizados para reacomodo`,
            "⚠️",
            {
              duration: 3000,
            }
          );
          this.progressBar = false;
        }
      );
  }

  agregar() {
    var datosFormulario: iSolicitudEyE;

    if (this.dialogo.tipoSolicitud == 1) {
      datosFormulario = {
        idSolicitud: this.dialogo.datosMaterial.idSolicitud,
        idSalida: this.dialogo.datosMaterial.idSalida,
        tipoSolicitud: this.tipoSolicitud,
        talon: "",
        empaque: null,
        material: this.forma.get("materiales").value,
        unidadMedida: this.unidadMedida,
        cantidadMaterial: this.forma.get("cantidad").value,
        observaciones: this.forma.get("observaciones").value.trim(),
        anexo: this.forma.get("anexo").value,
        archivo: this.archivoAnexado,
      };
    } else {
      const tipoEmpaque: TipoEmpaque = this.forma.get("empaque").value;
      const empaque: EmpaquesUtilizados = this.dialogo.datosMaterial.empaque;
      empaque.idTipoEmpaque = tipoEmpaque.idTipoEmpaque;
      empaque.cantidad = this.forma.get("cantidadEmpaque").value;

      datosFormulario = {
        idSolicitud: this.dialogo.datosMaterial.idSolicitud,
        idSalida: this.dialogo.datosMaterial.idSalida,
        tipoSolicitud: this.tipoSolicitud,
        talon: this.forma.get("talon").value,
        empaque: empaque,
        material: this.forma.get("materiales").value,
        unidadMedida: this.unidadMedida,
        cantidadMaterial: this.forma.get("cantidad").value,
        observaciones: this.forma.get("observaciones").value.trim(),
        anexo: this.forma.get("anexo").value,
        archivo: this.archivoAnexado,
      };
    }

    this.progressBar = false;
    this.dialogRef.close(datosFormulario);
    this.forma.reset();
    this.unidadMedida = "";
  }

  cancelar() {
    this.forma.reset();
    this.unidadMedida = "";
    this.dialogRef.close();
  }
}
