import * as $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { AppHeaderComponent } from './layouts/full/header/header.component';
import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component';
import { LayoutPrintComponent } from './layouts/layout-print/layout-print.component';
import { LayoutPrintHeaderComponent } from './layouts/layout-print/layout-print-header/layout-print-header.component';
import { LayoutPrintFooterComponent } from './layouts/layout-print/layout-print-footer/layout-print-footer.component';
import { AppBreadcrumbComponent } from './layouts/full/breadcrumb/breadcrumb.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DemoMaterialModule } from './base-material-module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MatDatepickerModule }  from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeEsMx from '@angular/common/locales/es-Mx';
import { TokenInterceptor } from './authentication/login/interceptors/token.interceptor';
import { AuthInterceptor } from './authentication/login/interceptors/auth.interceptor';
import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';
import { PrintPolizaPagadosComponent } from './systems/contabilidad/poliza-pagados/print-poliza-pagados/print-poliza-pagados.component';
import { PrintDetallePolizaPagadosComponent } from './systems/contabilidad/poliza-pagados/print-detalle-poliza-pagados/print-detalle-poliza-pagados.component';
import { PrintService } from './services/print.service';
import { OficinaService } from './services/oficina.service';
import { FechaService } from './services/fecha.service';
import { AgenciaService } from './services/agencia.service';

import { MatDialogModule } from '@angular/material/dialog';
import { DialogCambioOficinaComponent } from './shared/modals/cambio-oficina/dialog-cambio-oficina.component';
import { DialogCambioAgenciaComponent } from './shared/modals/agencias-por-oficina/dialog-cambio-agencia.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatChipsModule } from '@angular/material/chips';
import { MatListModule } from '@angular/material/list';


registerLocaleData(localeEsMx, 'es-Mx');

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    AppHeaderComponent,
    SpinnerComponent,
    AppBlankComponent,
    AppSidebarComponent,
    LayoutPrintComponent,
    LayoutPrintHeaderComponent,
    LayoutPrintFooterComponent,
    PrintPolizaPagadosComponent,
    PrintDetallePolizaPagadosComponent,
	  AppBreadcrumbComponent,
    DialogCambioOficinaComponent,
    DialogCambioAgenciaComponent

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatDatepickerModule, MatMomentDateModule,
    DemoMaterialModule,
    FormsModule,
    FlexLayoutModule,
    HttpClientModule,
    PerfectScrollbarModule,
    SharedModule,
    NgMultiSelectDropDownModule.forRoot(),
    RouterModule.forRoot(AppRoutes),
    MatDialogModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    MatChipsModule,
    MatListModule
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,

    },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'es-Mx'},
    PrintService,
    OficinaService,
    FechaService,
    AgenciaService,
  ],
  entryComponents:[
    DialogCambioOficinaComponent,
    DialogCambioAgenciaComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
