import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { InformacionValesCorte } from '../../../interfaces/nomina-estibadores/informacion-vales-corte';
import { DesgloseDiarioCorte } from '../../../interfaces/nomina-estibadores/desglose-diario-corte';
import { CorteEstibador } from '../../../interfaces/nomina-estibadores/corte-estibador';
import { DesgloseDiarioCorteEstibador } from '../../../interfaces/nomina-estibadores/desglose-diario-corte-estibador';
import { Personal } from '../../../interfaces/personal';
import { DesgloseEstibadorDetalle } from '../../../interfaces/nomina-estibadores/desglose-estibador-detalle.interface';
import { DesgloseEstibadorCabecera } from '../../../interfaces/nomina-estibadores/desglose-estibador-cabecera.interface';

@Component({
  selector: 'app-dialog-desglose-diario-corte',
  templateUrl: './dialog-desglose-diario-corte.component.html',
  styleUrls: ['./dialog-desglose-diario-corte.component.css']
})
export class DialogDesgloseDiarioCorteComponent {

  public initColumns: DesgloseEstibadorCabecera[] = [];
  public displayedColumns: any[] = [];
  private dataSourceManiobrasNaturales: any = new MatTableDataSource();
  private dataSourceManiobrasEspeciales: any = new MatTableDataSource();

  public lstDesgloseDiarioCorteEstibadorManiobrasNaturales: DesgloseEstibadorDetalle[] = [];
  public lstDesgloseDiarioCorteEstibadorManiobrasEspeciales: DesgloseEstibadorDetalle[] = [];

  private lstCorteEstibador: CorteEstibador[] = [];
  private lstDesgloseDiarioCorte: DesgloseDiarioCorte[] = [];

  @ViewChild('TablaManiobrasNaturalesPaginator', { static: false }) set tablaManiobrasNaturalesPaginator(tablaManiobrasNaturalesPaginator: MatPaginator) {
    if (tablaManiobrasNaturalesPaginator != undefined && tablaManiobrasNaturalesPaginator != null) {
      this.dataSourceManiobrasNaturales.paginator = tablaManiobrasNaturalesPaginator;
      this.dataSourceManiobrasNaturales.paginator._intl.itemsPerPageLabel = 'Resultados por página';
    }
  }

  @ViewChild('TablaManiobrasNaturalesSort', { static: false }) set tablaManiobrasNaturalesSort(tablaManiobrasNaturalesSort: MatSort) {
    if (tablaManiobrasNaturalesSort != undefined && tablaManiobrasNaturalesSort != null) {
      this.dataSourceManiobrasNaturales.sort = tablaManiobrasNaturalesSort;
    }
  }

  @ViewChild('TablaManiobrasEspecialesPaginator', { static: false }) set tablaManiobrasEspecialesPaginator(tablaManiobrasEspecialesPaginator: MatPaginator) {
    if (tablaManiobrasEspecialesPaginator != undefined && tablaManiobrasEspecialesPaginator != null) {
      this.dataSourceManiobrasEspeciales.paginator = tablaManiobrasEspecialesPaginator;
      this.dataSourceManiobrasEspeciales.paginator._intl.itemsPerPageLabel = 'Resultados por página';
    }
  }

  @ViewChild('TablaManiobrasEspecialesSort', { static: false }) set tablaManiobrasEspecialesSort(tablaManiobrasEspecialesSort: MatSort) {
    if (tablaManiobrasEspecialesSort != undefined && tablaManiobrasEspecialesSort != null) {
      this.dataSourceManiobrasEspeciales.sort = tablaManiobrasEspecialesSort;
    }
  }

  constructor(
    public dialogRef: MatDialogRef<DialogDesgloseDiarioCorteComponent>,
    breakpointObserver: BreakpointObserver,
    @Inject(MAT_DIALOG_DATA) public data: { lstDesgloseDiarioCorte: DesgloseDiarioCorte[], lstCorteEstibador: CorteEstibador[] }) {

    this.lstDesgloseDiarioCorte = data.lstDesgloseDiarioCorte as DesgloseDiarioCorte[];
    this.lstCorteEstibador = data.lstCorteEstibador as CorteEstibador[];
    this.formatTable();

    this.dataSourceManiobrasNaturales = new MatTableDataSource<DesgloseEstibadorDetalle>(this.lstDesgloseDiarioCorteEstibadorManiobrasNaturales);
    this.dataSourceManiobrasNaturales.sort = this.tablaManiobrasNaturalesSort;

    this.dataSourceManiobrasEspeciales = new MatTableDataSource<DesgloseEstibadorDetalle>(this.lstDesgloseDiarioCorteEstibadorManiobrasEspeciales);
    this.dataSourceManiobrasEspeciales.sort = this.tablaManiobrasEspecialesSort;
  }

  cerrarDialogo(): void {
    const array = {
      'respuesta': false,
    };
    this.dialogRef.close(array);
  }

  formatTable() {
    this.lstCorteEstibador.forEach((corteEstibador: CorteEstibador, i: number): void => {
      var desgloseEstibadorDetalleManiobrasNaturales: DesgloseEstibadorDetalle = { estibador: "", clasificacion: "", orden: "", total: 0 };
      var desgloseEstibadorDetalleManiobrasEspeciales: DesgloseEstibadorDetalle = { estibador: "", clasificacion: "", orden: "", total: 0 };
      desgloseEstibadorDetalleManiobrasNaturales.estibador = corteEstibador.personalEstibador.nombreCompleto;
      desgloseEstibadorDetalleManiobrasNaturales.clasificacion = corteEstibador.estibadorClasificacion;
      desgloseEstibadorDetalleManiobrasNaturales.orden = corteEstibador.estibadorTipoOrden + (corteEstibador.estibadorCuadrilla ? " - " + corteEstibador.estibadorCuadrilla : "")
      desgloseEstibadorDetalleManiobrasEspeciales.estibador = corteEstibador.personalEstibador.nombreCompleto;
      desgloseEstibadorDetalleManiobrasEspeciales.clasificacion = corteEstibador.estibadorClasificacion;
      desgloseEstibadorDetalleManiobrasEspeciales.orden = corteEstibador.estibadorTipoOrden + (corteEstibador.estibadorCuadrilla ? " - " + corteEstibador.estibadorCuadrilla : "");

      if (i == 0) {
        this.initColumns.push({ name: 'estibador', display: 'Estibador', description: 'Nombre del Estibador' });
        this.initColumns.push({ name: 'clasificacion', display: 'Clasificación', description: 'Clasificación del Estibador', fxFlex: "10" });
        this.initColumns.push({ name: 'orden', display: 'Orden', description: 'Orden del Estibador', fxFlex: "10" });
      }

      this.lstDesgloseDiarioCorte.forEach((desgloseDiarioCorte: DesgloseDiarioCorte, j: number): void => {
        let column: string = 'dia' + (j + 1);
        desgloseEstibadorDetalleManiobrasNaturales[column] = this.getSueldoManiobrasNaturalesEstibador(this.getDesgloseDiaEstibador(desgloseDiarioCorte, corteEstibador.personalEstibador.idPersonal));
        desgloseEstibadorDetalleManiobrasNaturales.total += desgloseEstibadorDetalleManiobrasNaturales[column];
        desgloseEstibadorDetalleManiobrasEspeciales[column] = this.getDesgloseDiaEstibador(desgloseDiarioCorte, corteEstibador.personalEstibador.idPersonal).montoManiobrasEspeciales;
        desgloseEstibadorDetalleManiobrasEspeciales.total += desgloseEstibadorDetalleManiobrasEspeciales[column];
        if (i == 0) this.initColumns.push({ name: column, display: this.getShortDate(desgloseDiarioCorte.fechaPeriodo), description: this.getLongDate(desgloseDiarioCorte.fechaPeriodo), fxFlex: "8", right: true, currency: true });
      });

      if (i == 0) this.initColumns.push({ name: 'total', display: 'Total', description: "Total del Estibador en el Periodo", fxFlex: "9", right: true, currency: true });

      this.lstDesgloseDiarioCorteEstibadorManiobrasNaturales.push(desgloseEstibadorDetalleManiobrasNaturales);
      desgloseEstibadorDetalleManiobrasEspeciales.total > 0 ? this.lstDesgloseDiarioCorteEstibadorManiobrasEspeciales.push(desgloseEstibadorDetalleManiobrasEspeciales) : null;
    });
    this.displayedColumns = this.initColumns.map(col => col.name);
  }

  getDesgloseDiaEstibador(desgloseDiarioCorte: DesgloseDiarioCorte, idPersonal: number): DesgloseDiarioCorteEstibador {
    return desgloseDiarioCorte.lstDesgloseDiarioCorteEstibador.find(desgloseDiarioCorteEstibador => desgloseDiarioCorteEstibador.personalEstibador.idPersonal == idPersonal);
  }

  getSueldoManiobrasNaturalesEstibador(desgloseDiarioCorteEstibador: DesgloseDiarioCorteEstibador): number {
    return desgloseDiarioCorteEstibador.montoManiobrasNaturales + desgloseDiarioCorteEstibador.montoConceptosEspeciales;
  }

  getShortDate(date: string): string {
    let fecha: Date = new Date(date);
    return fecha.toLocaleString('es-MX',
      {
        weekday: 'short',
        day: '2-digit'
      }
    );
  }

  getLongDate(date: string): string {
    let fecha: Date = new Date(date);
    return fecha.toLocaleString('es-MX',
      {
        weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: '2-digit'
      }
    );
  }

}
