import {HttpClient,HttpHeaders,HttpParams,HttpResponse} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { Materiales } from "../interfaces/paqueteria/materiales";
import { AppsettingsComponent } from "../app-settings/appsettings.component";
import { Solicitudes_Eye } from "../interfaces/paqueteria/solicitudes-eye";
import { TalonesSolicitudesEyE } from "../interfaces/paqueteria/talones-solicitudes-eye";
import { SalidaAlmacenEyE } from "../interfaces/paqueteria/salida-almacen-eye";
import { Anexo } from "../interfaces/paqueteria/anexo";
import { ReporteSolicitudesMaterial } from "../interfaces/paqueteria/reporte-solicitudes-material";
import { TalonesMaterial } from "../interfaces/paqueteria/talones-material";
import { DatosSeguimiento, DatosSeguimientoSap } from "../interfaces/paqueteria/datos-seguimiento";
import { SeguimientoSolicitudesEyE } from "../interfaces/paqueteria/seguimiento-solicitudes-eye";
import { PermisosEyE } from "../interfaces/paqueteria/permisos-eye";
import { InventarioEye } from "../interfaces/paqueteria/inventario-eye";
import { ExistenciaMaterial } from "../interfaces/paqueteria/datos-inventario";
import { MovimientosKardexEyE } from "../interfaces/paqueteria/movimiento-kardex-eye";
import { DatosMovimientosKardex } from "../interfaces/paqueteria/datos-movimientos-kardex";
import { catchError, map } from "rxjs/operators";
import { Talones } from "../interfaces/paqueteria/talones";
import { Tr } from "../interfaces/talones-oficina/tr";
import { CdOrigenDestino } from "../interfaces/paqueteria/cd-origen-destino";
import { TalonesParametros } from "../interfaces/paqueteria/talones-parametros";
import { MetodoPago } from "../interfaces/paqueteria/metodopago";
import { Detatr } from "../interfaces/paqueteria/detatr";
import { CatalogoConceptoImporte } from "../interfaces/paqueteria/catalogo-concepto-importe";
import { Co } from "../interfaces/paqueteria/co";
import { UnidadMedida } from "../interfaces/paqueteria/unidad-medida";
import { AuthService } from "../authentication/login/auth.service";
import { TalonEnviadoEye } from "../interfaces/paqueteria/talon-enviado";
import { SolicitudSap } from "../interfaces/paqueteria/solicitud-sap";
import { MaterialesInventario } from "../interfaces/paqueteria/materiales-inventario";
import { EntradaMaterial } from "../interfaces/paqueteria/entrada-material";
import { Personal } from "../interfaces/paqueteria/personal";
import { SalidaMaterial } from "../interfaces/paqueteria/salida-material";
import { ReporteVentasDetalle } from "../interfaces/paqueteria/reporte-de-ventas";
import { TipoEmpaque } from "../interfaces/paqueteria/tipo-empaque";
import { EmpaquesUtilizados } from "../interfaces/paqueteria/empaques-utilizados";
import { CantidadesMaterialPermitido } from "../interfaces/paqueteria/cantidades-material-permitido";

@Injectable({
  providedIn: "root",
})
export class EyeService {
  constructor(
    private http: HttpClient,
    private appSettings: AppsettingsComponent,
    private authService: AuthService
  ) {}

  //====GET====

  getPermisosByIdPuesto(idPuesto: number): Observable<PermisosEyE> {
    const params = new HttpParams().set("idPuesto", idPuesto.toString());
    return this.http.get<PermisosEyE>(
      this.appSettings.API_ENDPOINT +
        "personal/permisoseye/getPermisosByIdPuesto",
      { params }
    );
  }

  getMateriales(): Observable<Materiales[]> {
    return this.http.get<Materiales[]>(
      this.appSettings.SOLICITUDEYE_ENDPOINT + "materialeseye/getMateriales"
    );
  }

  getMaterialesByNombre(lstMateriales: string[]): Observable<Materiales[]> {
    const params = new HttpParams().append(
      "lstMateriales",
      lstMateriales.join(", ")
    );

    return this.http.get<Materiales[]>(
      this.appSettings.SOLICITUDEYE_ENDPOINT +
        "materialeseye/getMaterialesByNombre",
      { params }
    );
  }

  getUnidadesMedida(): Observable<UnidadMedida[]> {
    return this.http.get<UnidadMedida[]>(
      this.appSettings.SOLICITUDEYE_ENDPOINT + "materialeseye/getUnidadesMedida"
    );
  }

  getSolicitudes(): Observable<Solicitudes_Eye[]> {
    return this.http.get<Solicitudes_Eye[]>(
      this.appSettings.SOLICITUDEYE_ENDPOINT + `solicitudeseye/getSolicitudes`
    );
  }

  getSolicitudById(idSolicitud: number): Observable<Solicitudes_Eye> {
    const params = new HttpParams().set("idSolicitud", idSolicitud.toString());
    return this.http.get<Solicitudes_Eye>(
      this.appSettings.SOLICITUDEYE_ENDPOINT +
        "solicitudeseye/getSolicitudById",
      { params }
    );
  }

  getUltimoIdSolicitud(): Observable<number> {
    return this.http.get<number>(
      this.appSettings.SOLICITUDEYE_ENDPOINT +
        `solicitudeseye/getUltimoIdSolicitud`
    );
  }

  getReporteSolicitudesMaterial(idOficina: number): Observable<ReporteSolicitudesMaterial[]> {
    const params = new HttpParams().set("idOficina", idOficina.toString());
    return this.http.get<ReporteSolicitudesMaterial[]>(
      this.appSettings.SOLICITUDEYE_ENDPOINT +
        `solicitudeseye/getReporteSolicitudes`,
      { params }
    );
  }

  getReporteSolicitudesMaterialByFecha(fechaInicio: string,fechaFin: string,idOficina: number): Observable<ReporteSolicitudesMaterial[]> {
    const params = new HttpParams()
      .set("fechaInicio", fechaInicio)
      .set("fechaFin", fechaFin)
      .set("idOficina", idOficina.toString());

    return this.http.get<ReporteSolicitudesMaterial[]>(
      this.appSettings.SOLICITUDEYE_ENDPOINT +
        "solicitudeseye/getReporteSolicitudesByFecha",
      { params }
    );
  }

  getTalonesMaterial(idSolicitud: number,tipo: string): Observable<TalonesMaterial[]> {
    const params = new HttpParams()
      .set("idSolicitud", idSolicitud.toString())
      .set("tipo", tipo);

    return this.http.get<TalonesMaterial[]>(
      this.appSettings.SOLICITUDEYE_ENDPOINT +
        "solicitudeseye/getTalonesMaterial",
      { params }
    );
  }

  getMaterialesSolicitud(idSolicitud: number,tipo: string): Observable<TalonesMaterial[]> {
    const params = new HttpParams()
      .set("idSolicitud", idSolicitud.toString())
      .set("tipo", tipo);

    return this.http.get<TalonesMaterial[]>(
      this.appSettings.SOLICITUDEYE_ENDPOINT +
        "solicitudeseye/getTalonesMaterial",
      { params }
    );
  }

  getDatosSeguimientoByIdSolicitud(idSolicitud: number): Observable<DatosSeguimiento[]> {
    const params = new HttpParams().set("idSolicitud", idSolicitud.toString());

    return this.http.get<DatosSeguimiento[]>(
      this.appSettings.SOLICITUDEYE_ENDPOINT +
        "solicitudeseye/getDatosSeguimientoByIdSolicitud",
      { params }
    );
  }

  getMaterialEnExistencia(claveOficina: string): Observable<ExistenciaMaterial[]> {
    const params = new HttpParams().set("claveOficina", claveOficina);

    return this.http
      .get<ExistenciaMaterial[]>(
        this.appSettings.KARDEXEYE_ENDPOINT +
          "existenciaMateriales/getMaterialEnExistencia",
        { params }
      ).pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getInventarioByParams(idOficina: number, idMaterial: number,cantidad: number): Observable<InventarioEye> {
    const params = new HttpParams()
      .set("idOficina", idOficina.toString())
      .set("idMaterial", idMaterial.toString())
      .set("cantidad", cantidad.toString());

    return this.http
      .get<InventarioEye>(
        this.appSettings.KARDEXEYE_ENDPOINT +
          "inventarioEyE/getInventarioByParams",
        { params }
      )
      .pipe(
        map((inventario) => {
          if (inventario == null) {
            inventario = {
              idInventario: 0,
              idOficina: idOficina,
              idMaterial: idMaterial,
              cantidad: 0,
              idPersonal: 0,
              fecha: "",
              hora: "",
            };
          }
          return inventario;
        })
      );
  }

  getMovimientosByIdOficina(idOficina: number,claveOficina: string ): Observable<DatosMovimientosKardex[]> {
    const params = new HttpParams()
      .set("idOficina", idOficina.toString())
      .set("claveOficina", claveOficina);

    return this.http
      .get<DatosMovimientosKardex[]>(
        this.appSettings.KARDEXEYE_ENDPOINT +
          "movimientosKardex/getMovimientosByIdOficina",
        { params }
      )
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getTalonByClaTalon(claTalon: string): Observable<Talones> {
    const params = new HttpParams().set("claTalon", claTalon);
    return this.http
      .get<Talones>(
        this.appSettings.SOLICITUDEYE_ENDPOINT +
          "talonessolicitudeseye/getTalonByClaTalon",
        { params }
      )
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getTrByClaTalon(tabla: string, claTalon: string): Observable<Tr> {
    const params = new HttpParams()
      .set("tabla", tabla)
      .set("claTalon", claTalon);

    return this.http
      .get<Tr>(
        this.appSettings.SOLICITUDEYE_ENDPOINT +
          "talonessolicitudeseye/getTrByClaTalon",
        { params }
      )
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getCdOrigenDestino(idCiudadOrigen: Number = 0,idCiudadDestino: Number = 0): Observable<CdOrigenDestino[]> {
    const params = new HttpParams()
      .set("idCiudadOrigen", idCiudadOrigen.toString())
      .set("idCiudadDestino", idCiudadDestino.toString());

    return this.http.get<CdOrigenDestino[]>(
      this.appSettings.SOLICITUDEYE_ENDPOINT +
        "talonessolicitudeseye/getCdOrigenDestino",
      { params }
    );
  }

  getTalonesParametrosByIdParametro(idParametro: number): Observable<TalonesParametros> {
    const params = new HttpParams().set("idParametro", idParametro.toString());
    return this.http.get<TalonesParametros>(
      this.appSettings.SOLICITUDEYE_ENDPOINT +
        "talonessolicitudeseye/getTalonesParametrosByIdParametro",
      { params }
    );
  }

  getDetatrByClaTalon(claTalon: string): Observable<Detatr> {
    const params = new HttpParams().set("claTalon", claTalon);
    return this.http.get<Detatr>(
      this.appSettings.SOLICITUDEYE_ENDPOINT +
        "talonessolicitudeseye/getDetatrByClaTalon",
      { params }
    );
  }

  getMetodoPagoById(idMetodoPago: string): Observable<MetodoPago> {
    const params = new HttpParams().set("idMetodoPago", idMetodoPago);
    return this.http.get<MetodoPago>(
      this.appSettings.SOLICITUDEYE_ENDPOINT +
        "talonessolicitudeseye/getMetodoPagoById",
      { params }
    );
  }
  getMetodoPagoByClave(idMetodoPago: string): Observable<MetodoPago> {
    const params = new HttpParams().set("idMetodoPago", idMetodoPago);
    return this.http.get<MetodoPago>(
      this.appSettings.SOLICITUDEYE_ENDPOINT +
        "talonessolicitudeseye/getMetodoPagoByClave",
      { params }
    );
  }

  getCatalogoConceptoImporteByParams(tabla: string, idConcepto: string,claTalon: string): Observable<CatalogoConceptoImporte> {
    const params = new HttpParams()
      .set("tabla", tabla)
      .set("idConcepto", idConcepto)
      .set("claTalon", claTalon);

    return this.http.get<CatalogoConceptoImporte>(
      this.appSettings.SOLICITUDEYE_ENDPOINT +
        "talonessolicitudeseye/getCatalogoConceptoImporteByParams",
      { params }
    );
  }

  getQueContiene(tabla: string, claTalon: string): Observable<Co[]> {
    const params = new HttpParams()
      .set("tabla", tabla)
      .set("claTalon", claTalon);

    return this.http.get<Co[]>(
      this.appSettings.SOLICITUDEYE_ENDPOINT +
        "talonessolicitudeseye/getQueContiene",
      { params }
    );
  }

  getReporteDeVentas(fechaInicio: string,fechaFin: string,simplex: boolean,claveOficina: string,lstIdMateriales: number[]): Observable<ReporteVentasDetalle[]> {
    const tipo = simplex ? "1,27" : "19,20,21,22";
    var cadenaIDs = "";
    var contador = 1;

    lstIdMateriales.forEach((id) => {
      cadenaIDs += id.toString();

      if (contador < lstIdMateriales.length) {
        cadenaIDs += ",";
      }

      contador++;
    });

    const params = new HttpParams()
      .set("fechaInicio", fechaInicio)
      .set("fechaFin", fechaFin)
      .set("tipo", tipo)
      .set("claveOficina", claveOficina)
      .set("cadenaMateriales", cadenaIDs);

    return this.http
      .get<ReporteVentasDetalle[]>(
        this.appSettings.REPORTES_ENDPOINT + "reporteVentas/getReporteVentas",
        { params }
      )
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getSolicitudPendienteDeEnviar(idOficina: string): Observable<Solicitudes_Eye> {
    const params = new HttpParams().set("idOficina", idOficina);
    return this.http
      .get<Solicitudes_Eye>(
        this.appSettings.SOLICITUDEYE_ENDPOINT +
          "solicitudeseye/getSolicitudPendienteDeEnviar",
        { params }
      )
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getTalonesSolicitudByIdSolicitud( idSolicitud: string): Observable<TalonesSolicitudesEyE[]> {
    const params = new HttpParams().set("idSolicitud", idSolicitud);
    return this.http.get<TalonesSolicitudesEyE[]>(
      this.appSettings.SOLICITUDEYE_ENDPOINT +
        "talonessolicitudeseye/getTalonesSolicitudByIdSolicitud",
      { params }
    );
  }

  getTalonEnviadoByNumeroTalon(numeroTalon: string): Observable<TalonEnviadoEye> {
    const params = new HttpParams().set("numeroTalon", numeroTalon);
    return this.http.get<TalonEnviadoEye>(
      this.appSettings.SOLICITUDEYE_ENDPOINT +
        "talonessolicitudeseye/getTalonEnviadoByNumeroTalon",
      { params }
    );
  }

  getSolicitudSap(folio: string): Observable<DatosSeguimientoSap> {
    return this.http.get<DatosSeguimientoSap>(
      this.appSettings.SOLICITUDEYE_ENDPOINT + "seguimientoSolicitudes/seguimiento/" + folio
    );
  }

  checarEstatus(): Observable<DatosSeguimientoSap> {
    return this.http.get<DatosSeguimientoSap>(
      this.appSettings.SOLICITUDEYE_ENDPOINT + "seguimientoSolicitudes/checarEstatus"
    );
  }

  getPersonalCorpoByIdUsuario(idUsuario: string): Observable<Personal> {
    const params = new HttpParams().set("idUsuario", idUsuario);
    return this.http
      .get<Personal>(
        this.appSettings.KARDEXEYE_ENDPOINT +
          "existenciaMateriales/getPersonalCorpoByIdUsuario",
        { params }
      )
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getMaterialesInventarioById(claveOficina: string,idMaterial: number): Observable<MaterialesInventario> {
    const params = new HttpParams()
      .set("claveOficina", claveOficina)
      .set("idMaterial", idMaterial.toString());
    return this.http
      .get<MaterialesInventario>(
        this.appSettings.KARDEXEYE_ENDPOINT +
          "existenciaMateriales/getMaterialesInventarioById",
        { params }
      )
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getTipoEmpaque(): Observable<TipoEmpaque[]> {
    return this.http
      .get<TipoEmpaque[]>(
        this.appSettings.SOLICITUDEYE_ENDPOINT + "solicitudeseye/getTipoEmpaque"
      )
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getCantidadMaterialPermitido(idMaterial: number,idTipoEmpaque: number): Observable<CantidadesMaterialPermitido> {
    const params = new HttpParams()
      .set("idMaterial", idMaterial.toString())
      .set("idTipoEmpaque", idTipoEmpaque.toString());

    return this.http
      .get<CantidadesMaterialPermitido>(
        this.appSettings.SOLICITUDEYE_ENDPOINT +
          "solicitudeseye/getCantidadMaterialPermitido",
        { params }
      )
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getEmpaquesUtilizados(idSolicitud: number,idOficina: string): Observable<EmpaquesUtilizados[]> {
    const params = new HttpParams()
      .set("idSolicitud", idSolicitud.toString())
      .set("idOficina", idOficina);

    return this.http
      .get<EmpaquesUtilizados[]>(
        this.appSettings.SOLICITUDEYE_ENDPOINT +
          "solicitudeseye/getEmpaquesUtilizados",
        { params }
      )
      .pipe(catchError((error) => throwError(error)));
  }

  getSemaforo(idModulo: number,idSistema: number): Observable<number> {
    const params = new HttpParams()
      .set("idModulo", idModulo.toString())
      .set("idSistema", idSistema.toString());

    return this.http
      .get<number>(
        this.appSettings.SOLICITUDEYE_ENDPOINT + "seguimientoSolicitudes/getSemaforo",
        { params }
      )
      .pipe(catchError((error) => throwError(error)));
  }


  //====POST====

  guardarAnexo(archivo: File, nombreOficina: string): Observable<Anexo> {
    if (archivo == null) {
      archivo = new File([""], "vacio.txt", { type: "text/plain" });
    }
    const formData = new FormData();
    formData.append("archivo", archivo);
    formData.append("nombreOficina", nombreOficina);
    return this.http
      .post<Anexo>(
        this.appSettings.SOLICITUDEYE_ENDPOINT + `solicitudeseye/guardarAnexo`,
        formData
      ).pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  guardarSolicitud(solicitud: Solicitudes_Eye): Observable<Solicitudes_Eye> {
    const formData = new FormData();
    formData.append("idSolicitud", solicitud.idSolicitud.toString());
    formData.append("folio", solicitud.folio.toString());
    formData.append("idPersonal", solicitud.idPersonal.toString());
    formData.append("idOficina", solicitud.idOficina.toString());
    formData.append("idEstatus", solicitud.idEstatus.toString());
    formData.append("anexo", solicitud.anexo);
    formData.append("observaciones", solicitud.observaciones);
    formData.append("motivoRechazo", solicitud.motivoRechazo);
    formData.append("tipoSolicitud", solicitud.tipoSolicitud);
    formData.append("fecha", solicitud.fecha);
    formData.append("hora", solicitud.hora);
    formData.append("documento", solicitud.documento);

    return this.http
      .post<Solicitudes_Eye>(
        this.appSettings.SOLICITUDEYE_ENDPOINT +
          `solicitudeseye/guardarSolicitud`,
        formData
      ).pipe(
        catchError((error) => {
          return throwError(`Ocurrió un error guardar la solicitud. ${error}`);
        })
      );
  }

  guardarSeguimiento(seguimiento: SeguimientoSolicitudesEyE): Observable<SeguimientoSolicitudesEyE> {
    return this.http.post<SeguimientoSolicitudesEyE>(
      this.appSettings.SOLICITUDEYE_ENDPOINT +
        "seguimientoSolicitudes/guardarSeguimiento",
      seguimiento
    );
  }

  guardarSalidaAlamcen(salida: SalidaAlmacenEyE): Observable<SalidaAlmacenEyE> {
    return this.http
      .post<SalidaAlmacenEyE>(
        this.appSettings.SOLICITUDEYE_ENDPOINT + `salidaalmacen/guardarSalida`,
        salida
      )
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  guardarTalones(talon: TalonesSolicitudesEyE): Observable<TalonesSolicitudesEyE> {
    return this.http.post<TalonesSolicitudesEyE>(
      this.appSettings.SOLICITUDEYE_ENDPOINT +
        `talonessolicitudeseye/guardarTalonSolicitudEyE`,
      talon
    );
  }

  guardarEntrada(entrada: InventarioEye): Observable<InventarioEye> {
    return this.http.post<InventarioEye>(
      this.appSettings.KARDEXEYE_ENDPOINT + "inventarioEyE/guardarInventario",
      entrada
    );
  }

  guardarMovimiento(movimiento: MovimientosKardexEyE): Observable<MovimientosKardexEyE> {
    return this.http.post<MovimientosKardexEyE>(
      this.appSettings.KARDEXEYE_ENDPOINT +
        "movimientosKardex/guardarMovimiento",
      movimiento
    );
  }

  guardarTalonEnviadoEye(talonEnviado: TalonEnviadoEye): Observable<TalonEnviadoEye> {
    return this.http.post<TalonEnviadoEye>(
      this.appSettings.SOLICITUDEYE_ENDPOINT +
        "talonessolicitudeseye/guardarTalonEnviadoEye",
      talonEnviado
    );
  }

  guardarSolicitudSap(solicitud: SolicitudSap): Observable<string> {
    return this.http
      .post<string>(
        this.appSettings.SAP_ENDPOINT + "solicitudMaterial",
        solicitud
      )
      .pipe(
        catchError((error) => {
          return throwError(error.error);
        })
      );
  }

  guardarEntradaMaterial(claveOficina: string,entrada: EntradaMaterial): Observable<boolean> {
    const params = new HttpParams().set("claveOficina", claveOficina);

    return this.http
      .post<boolean>(
        this.appSettings.KARDEXEYE_ENDPOINT +
          "existenciaMateriales/guardarEntradaMaterial",
        entrada,
        { params }
      )
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  guardarSalidaMaterial(claveOficina: string,salida: SalidaMaterial): Observable<boolean> {
    const params = new HttpParams().set("claveOficina", claveOficina);
    return this.http
      .post<boolean>(
        this.appSettings.KARDEXEYE_ENDPOINT +
          "existenciaMateriales/guardarSalidaMaterial",
        salida,
        { params }
      )
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  guardarMaterialesInventario(claveOficina: string,materialInv: MaterialesInventario ): Observable<boolean> {
    const params = new HttpParams().set("claveOficina", claveOficina);
    return this.http
      .post<boolean>(
        this.appSettings.KARDEXEYE_ENDPOINT +
          "existenciaMateriales/guardarMaterialesInventario",
        materialInv,
        { params }
      )
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  guardarEmpaqueUtilizado(empaque: EmpaquesUtilizados): Observable<EmpaquesUtilizados> {
    return this.http
      .post<EmpaquesUtilizados>(
        this.appSettings.SOLICITUDEYE_ENDPOINT +
          "solicitudeseye/guardarEmpaqueUtilizado",
        empaque
      )
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  //====PUT====

  actualizarTalon(talon: TalonesSolicitudesEyE): Observable<TalonesSolicitudesEyE> {
    return this.http.put<TalonesSolicitudesEyE>(
      this.appSettings.SOLICITUDEYE_ENDPOINT +
        "talonessolicitudeseye/actualizarTalonSolicitudEyE",
      talon
    );
  }

  actualizarSalidaAlmacen( salida: SalidaAlmacenEyE): Observable<SalidaAlmacenEyE> {
    return this.http
      .put<SalidaAlmacenEyE>(
        this.appSettings.SOLICITUDEYE_ENDPOINT +
          "salidaalmacen/actualizarSalida",
        salida
      )
      .pipe(
        catchError((error) => {
          return throwError(
            `Ocurrió un error al actualizar los materiales de la solicitud. ${error.message}`
          );
        })
      );
  }

  actualizarSolicitud(solicitud: Solicitudes_Eye): Observable<Solicitudes_Eye> {
    const formData = new FormData();
    formData.append("idSolicitud", solicitud.idSolicitud.toString());
    formData.append("folio", solicitud.folio.toString());
    formData.append("idPersonal", solicitud.idPersonal.toString());
    formData.append("idOficina", solicitud.idOficina.toString());
    formData.append("idEstatus", solicitud.idEstatus.toString());
    formData.append("anexo", solicitud.anexo);
    formData.append("observaciones", solicitud.observaciones);
    formData.append("motivoRechazo", solicitud.motivoRechazo);
    formData.append("tipoSolicitud", solicitud.tipoSolicitud);
    formData.append("fecha", solicitud.fecha);
    formData.append("hora", solicitud.hora);
    formData.append("documento", solicitud.documento);

    return this.http
      .put<Solicitudes_Eye>(
        this.appSettings.SOLICITUDEYE_ENDPOINT +
          "solicitudeseye/actualizarSolicitud",
        formData
      )
      .pipe(
        catchError((error) => {
          return throwError(
            `Ocurrió un error al actualizar la solicitud. ${error.message}`
          );
        })
      );
  }

  actualizarAnexo(archivoNuevo: File,archivoViejo: string,nombreOficina: string): Observable<Anexo> {
    archivoViejo = archivoViejo == null || archivoViejo.length == 0 ? "" : archivoViejo;
    const formData = new FormData();
    formData.append("archivoNuevo", archivoNuevo);
    formData.append("archivoViejo", archivoViejo);
    formData.append("nombreOficina", nombreOficina);

    return this.http
      .put<Anexo>(
        this.appSettings.SOLICITUDEYE_ENDPOINT +
          "solicitudeseye/actualizarAnexo",
        formData
      )
      .pipe(
        map((response: Anexo) => {
          if (archivoNuevo != null && response.code != 200) {
            throw new Error(response.mensaje);
          }

          return response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  actualizarInventario(entrada: InventarioEye): Observable<InventarioEye> {
    return this.http.put<InventarioEye>(
      this.appSettings.KARDEXEYE_ENDPOINT +
        "inventarioEyE/actualizarInventario",
      entrada
    );
  }

  actualizarMaterialesInventario( claveOficina: string,idMaterial: number,cantEntrada: number,cantSalida: number): Observable<boolean> {
    const params = new HttpParams()
      .set("claveOficina", claveOficina)
      .set("idMaterial", idMaterial.toString())
      .set("cantEntrada", cantEntrada.toString())
      .set("cantSalida", cantSalida.toString());

    return this.http
      .put<boolean>(
        this.appSettings.KARDEXEYE_ENDPOINT +
          "existenciaMateriales/actualizarMaterialesInventario",
        params
      )
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  actualizarEmpaqueUtilizado(empaque: EmpaquesUtilizados): Observable<EmpaquesUtilizados> {
    return this.http
      .put<EmpaquesUtilizados>(
        this.appSettings.SOLICITUDEYE_ENDPOINT +
          "solicitudeseye/actualizarEmpaqueUtilizado",
        empaque
      )
      .pipe(catchError((error) => throwError(error)));
  }

  //====DELETE====
  eliminarSalida(idSalida: string): Observable<number> {
    const params = new HttpParams().set("idSalida", idSalida);
    return this.http
      .delete<number>(
        this.appSettings.SOLICITUDEYE_ENDPOINT + "salidaalmacen/eliminarSalida",
        { params }
      )
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  eliminarTalonSolicitud(idTalon: string): Observable<number> {
    const params = new HttpParams().set("idTalon", idTalon);
    return this.http
      .delete<number>(
        this.appSettings.SOLICITUDEYE_ENDPOINT +
          "talonessolicitudeseye/eliminarTalonSolicitud",
        { params }
      )
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  //====OTROS====
  convertirUnidadMedida(idMaterial: number, unidaMedida: string): string {
    if (unidaMedida == "pzas" || unidaMedida == "pza") {
      unidaMedida = idMaterial == 2 ? "mts" : "pza";
      unidaMedida = idMaterial == 7 ? "bolsas" : unidaMedida;

    } else if (unidaMedida == "mts") {
      unidaMedida = idMaterial == 2 ? "pza" : "rollos";
    } else {
      unidaMedida = "mts";
      unidaMedida = idMaterial == 37 || idMaterial == 38 ? "kg" : unidaMedida;
    }
    return unidaMedida;
  }

  convertirCantidadService(nombreMaterial: string,cantidad: number,unidadMedida: string): Observable<number> {

    const params = new HttpParams()
      .set("nombreMaterial", nombreMaterial)
      .set("cantidad", cantidad.toString())
      .set("unidadMedida", unidadMedida);

      return  this.http 
      .get<number>(
        this.appSettings.SOLICITUDEYE_ENDPOINT +
          "materialeseye/convertirCantidadMateriales",
        { params }
      )
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
      
  }

  convertirCantidadPromesa(nombreMaterial: string,cantidad: number,unidadMedida: string): Promise<number> {
    return new Promise((resolve, reject) => {
      this.convertirCantidadService(nombreMaterial,cantidad,unidadMedida).subscribe(
        (resp) => {
          resolve(resp);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }


  convertirCantidad ( nombreMaterial: string,cantidad: number,unidadMedida: string): number {
    this.convertirCantidadService(nombreMaterial,cantidad,unidadMedida).subscribe(
      (resp) => {
        cantidad = resp;
      }
    );
    return cantidad;
  }
  



  calcularCantidad(idMaterial: number, cantidad: number): number {
    var resp = 0;
    var fixed = "";
    var lstCantidades = [];
    var mtsLineales = 0;

    switch (idMaterial) {
      case 2:
        mtsLineales = 150;
        break;
      case 3:
        mtsLineales = 396;
        break;
      case 4:
        mtsLineales = 120;
        break;
      case 5:
        mtsLineales = 35;
        break;
    }

    if (mtsLineales > 0) {
      resp = cantidad / mtsLineales;
      fixed = resp.toFixed(6);
      fixed = fixed.replace(",", ".");
      lstCantidades = fixed.split(".");
      resp =
        parseInt(lstCantidades[1]) > 0
          ? parseInt(lstCantidades[0]) + 1
          : parseInt(lstCantidades[0]);
    } else {
      resp = cantidad;
    }

    return resp;
  }

  descargarAnexo(nombreAnexo: string, nombreOficina: string): Observable<Blob> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });

    const params = new HttpParams()
      .set("nombreAnexo", nombreAnexo)
      .set("nombreOficina", nombreOficina);

    return this.http
      .get(
        this.appSettings.SOLICITUDEYE_ENDPOINT +
          "solicitudeseye/descargarAnexo",
        {
          headers: headers,
          responseType: "blob",
          observe: "response",
          params,
        }
      )
      .pipe(
        map((response: HttpResponse<Blob>) => {
          return response.body;
        }),
        catchError((error) => throwError(error))
      );
    }

    
  
}