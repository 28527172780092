import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppsettingsComponent } from '../app-settings/appsettings.component';
import { AuthService } from '../authentication/login/auth.service';
import { Observable, of, throwError } from 'rxjs';
import { Corte } from '../interfaces/nomina-estibadores/corte';
import { ConfiguracionCorteOficina } from '../interfaces/nomina-estibadores/configuracion-corte-oficina';
import { Oficina } from '../interfaces/oficina';
import { Personal } from '../interfaces/personal';
import { AutorizacionFaltasCorte } from '../interfaces/nomina-estibadores/autorizacion-faltas-corte';
import { ClasificacionCorteOficina } from '../interfaces/nomina-estibadores/clasificacion-corte-oficina';
import { DescuentoCorte } from '../interfaces/nomina-estibadores/descuento_corte';
import { EstibadorNuevo } from '../interfaces/nomina-estibadores/estibador-nuevo';
import { Puesto } from '../interfaces/puesto';
import { TipoOrden } from '../interfaces/nomina-estibadores/tipo-orden';
import { Cuadrilla } from '../interfaces/nomina-estibadores/cuadrilla';
import { IncentivoCorte } from '../interfaces/nomina-estibadores/incentivo-corte';

@Injectable()
export class NominaEstibadoresService {

  constructor(private appsettings: AppsettingsComponent, private authService: AuthService, private http: HttpClient, private router: Router) { }

  /* - Nomina Estibadores - */
  obtenerCortePorOficina(idOficina: number, fechaAperturaCorte: string, fechaCierreCorte: string, precargarIncentivos: boolean = false): Observable<Corte> {
    return this.http.get<Corte>(this.appsettings.API_ENDPOINT + `nominaEstibadores/nominaEstibadores/getCorte?idOficina=${idOficina}&fechaAperturaCorte=${fechaAperturaCorte}&fechaCierreCorte=${fechaCierreCorte}&precargarIncentivos=${precargarIncentivos}`).pipe(
      map((response: any) => response as Corte),
      catchError(e => {
        return throwError(e);
      })
    );
  }

  /* - Corte */
  obtenerCortes(filtroOficina: string = '', filtroEstatus: string = '', filtroRangoInicio: string, filtroRangoFin: string): Observable<Corte[]> {
    return this.http.get<Corte[]>(this.appsettings.API_ENDPOINT + `nominaEstibadores/corte/obtenerCortes?filtroOficina=${filtroOficina}&filtroEstatus=${filtroEstatus}&filtroRangoInicio=${filtroRangoInicio}&filtroRangoFin=${filtroRangoFin}`).pipe(
      map((response: any) => response as Corte[]),
      catchError(e => {
        return throwError(e);
      })
    );
  }

  contarCortesPendientesYAprobados(idOficina: number, fechaAperturaCorte: string, fechaCierreCorte: string): Observable<number> {
    return this.http.get<number>(this.appsettings.API_ENDPOINT + `nominaEstibadores/corte/contarCortesPendientesYAprobados?idOficina=${idOficina}&fechaAperturaCorte=${fechaAperturaCorte}&fechaCierreCorte=${fechaCierreCorte}`).pipe(
      map((response: any) => response as number),
      catchError(e => {
        return throwError(e);
      })
    );
  }

  obtenerCorte(idCorte: number): Observable<Corte> {
    return this.http.get<Corte>(this.appsettings.API_ENDPOINT + `nominaEstibadores/corte/${idCorte}`).pipe(
      map((response: any) => response as Corte),
      catchError(e => {
        return throwError(e);
      })
    );
  }

  generarCorte(corte: Corte): Observable<any> {
    return this.http.post(this.appsettings.API_ENDPOINT + 'nominaEstibadores/corte/generarCorte', corte).pipe(
      catchError(e => {
        return throwError(e);
      })
    );
  }

  autorizarCorte(corte: Corte): Observable<any> {
    return this.http.put(this.appsettings.API_ENDPOINT + 'nominaEstibadores/corte/autorizarCorte', corte).pipe(
      catchError(e => {
        return throwError(e);
      })
    );
  }

  rechazarCorte(corte: Corte): Observable<any> {
    return this.http.put(this.appsettings.API_ENDPOINT + 'nominaEstibadores/corte/rechazarCorte', corte).pipe(
      catchError(e => {
        return throwError(e);
      })
    );
  }

  /* - Faltas - */
  obtenerAutorizacionFaltas(idAutorizacionFaltas: number): Observable<AutorizacionFaltasCorte> {
    return this.http.get<AutorizacionFaltasCorte>(this.appsettings.API_ENDPOINT + `nominaEstibadores/autorizacionFaltasCorte/${idAutorizacionFaltas}`).pipe(
      map((response: any) => response as AutorizacionFaltasCorte),
      catchError(e => {
        return throwError(e);
      })
    );
  }

  autorizarFaltas(autorizacionFaltasCorte: AutorizacionFaltasCorte): Observable<AutorizacionFaltasCorte> {
    return this.http.post(this.appsettings.API_ENDPOINT + 'nominaEstibadores/autorizacionFaltasCorte/guardarAutorizacion', autorizacionFaltasCorte).pipe(
      map((response: any) => response as AutorizacionFaltasCorte),
      catchError(e => {
        return throwError(e);
      })
    );
  }

  eliminarAutorizacionFaltas(idAutorizacionFaltas: number): Observable<any> {
    return this.http.delete(this.appsettings.API_ENDPOINT + `nominaEstibadores/autorizacionFaltasCorte/${idAutorizacionFaltas}`).pipe(
      catchError(e => {
        return throwError(e);
      })
    );
  }

  /* - Configuracion Corte Oficina - */
  obtenerConfiguraciones(filtroOficina: string = '', filtroDiaCorte: string = ''): Observable<ConfiguracionCorteOficina[]> {
    return this.http.get<ConfiguracionCorteOficina[]>(this.appsettings.API_ENDPOINT + `nominaEstibadores/configuracionCorteOficina/obtenerConfiguraciones?filtroOficina=${filtroOficina}&filtroDiaCorte=${filtroDiaCorte}`).pipe(
      map((response: any) => response as ConfiguracionCorteOficina[]),
      catchError(e => {
        return of([]);
      })
    );
  }

  obtenerConfiguracion(idConfiguracionCorte: number): Observable<ConfiguracionCorteOficina> {
    return this.http.get<ConfiguracionCorteOficina>(this.appsettings.API_ENDPOINT + `nominaEstibadores/configuracionCorteOficina/${idConfiguracionCorte}`).pipe(
      map((response: any) => response as ConfiguracionCorteOficina),
      catchError(e => {
        return throwError(e);
      })
    );
  }

  obtenerConfiguracionPorOficina(idOficina: number): Observable<ConfiguracionCorteOficina[]> {
    return this.http.get<ConfiguracionCorteOficina[]>(this.appsettings.API_ENDPOINT + `nominaEstibadores/configuracionCorteOficina/oficina/${idOficina}`).pipe(
      map((response: any) => response as ConfiguracionCorteOficina[]),
      catchError(e => {
        return throwError(e);
      })
    );
  }

  obtenerConfiguracionPorOficinaYClasificacionYTipoOrdenYCuadrilla(idOficina: number, idClasificacionCorte: number, idTipoOrden: number, idCuadrilla: string = ''): Observable<ConfiguracionCorteOficina> {
    return this.http.get<ConfiguracionCorteOficina>(this.appsettings.API_ENDPOINT + `nominaEstibadores/configuracionCorteOficina/obtenerConfiguracionByOficinaAndClasificacionAndTipoOrdenAndCuadrilla?idOficina=${idOficina}&idClasificacionCorte=${idClasificacionCorte}&idTipoOrden=${idTipoOrden}&idCuadrilla=${idCuadrilla}`).pipe(
      map((response: any) => response as ConfiguracionCorteOficina),
      catchError(e => {
        return throwError(e);
      })
    );
  }

  crearConfiguracion(configuracionCorteOficina: ConfiguracionCorteOficina): Observable<any> {
    return this.http.post(this.appsettings.API_ENDPOINT + 'nominaEstibadores/configuracionCorteOficina/insertarConfiguracion', configuracionCorteOficina).pipe(
      catchError(e => {
        return throwError(e);
      })
    );
  }

  actualizarConfiguracion(configuracionCorteOficina: ConfiguracionCorteOficina): Observable<any> {
    return this.http.put(this.appsettings.API_ENDPOINT + 'nominaEstibadores/configuracionCorteOficina/actualizarConfiguracion', configuracionCorteOficina).pipe(
      catchError(e => {
        return throwError(e);
      })
    );
  }

  /* - Personal - */
  obtenerEstibadoresPorOficinaYClasificacionYTipoOrdenYCuadrilla(idOficina: number, idClasificacionCorte: number, idTipoOrden: number, idCuadrilla: string): Observable<Personal[]> {
    return this.http.get<Personal>(this.appsettings.API_ENDPOINT + `nominaEstibadores/personal/obtenerEstibadores?idOficina=${idOficina}&idClasificacionCorte=${idClasificacionCorte}&idTipoOrden=${idTipoOrden}&idCuadrilla=${idCuadrilla}`).pipe(
      map((response: any) => response as Personal[]),
      catchError(e => {
        return throwError(e);
      })
    );
  }

  obtenerEstibadoresSinOrdenPorOficinaYClasificacion(idOficina: number, idClasificacionCorte: number): Observable<Personal[]> {
    return this.http.get<Personal>(this.appsettings.API_ENDPOINT + `nominaEstibadores/personal/obtenerEstibadoresSinOrden?idOficina=${idOficina}&idClasificacionCorte=${idClasificacionCorte}`).pipe(
      map((response: any) => response as Personal[]),
      catchError(e => {
        return throwError(e);
      })
    );
  }

  obtenerCoincidenciasPorCurp(curp: string): Observable<number> {
    return this.http.get<number>(this.appsettings.API_ENDPOINT + `nominaEstibadores/personal/existeCurp?curp=${curp}`).pipe(
      map((response: any) => response as number),
      catchError(e => {
        return throwError(e);
      })
    );
  }

  /* - Oficina - */
  obtenerOficinasParaCorte(): Observable<Oficina[]> {
    return this.http.get<Oficina[]>(this.appsettings.API_ENDPOINT + `nominaEstibadores/oficina/getOficinasCorte`);
  }

  obtenerOficinasConConfiguracionSinMatriz(): Observable<Oficina[]> {
    return this.http.get<Oficina[]>(this.appsettings.API_ENDPOINT + `nominaEstibadores/oficina/getOficinasConConfiguracionSinMatriz`);
  }

  obtenerOficinasParaMatriz(): Observable<Oficina[]> {
    return this.http.get<Oficina[]>(this.appsettings.API_ENDPOINT + `nominaEstibadores/oficina/getOficinasMatriz`);
  }

  obtenerOficinasAgenciasDeMatriz(idOficinaMatriz: string): Observable<Oficina[]> {
    return this.http.get<Oficina[]>(this.appsettings.API_ENDPOINT + `nominaEstibadores/oficina/getOficinasAgenciasByOficinaMatriz?idOficinaMatriz=${idOficinaMatriz}`);
  }

  obtenerIsOficinaCedis(idOficina: number): Observable<boolean> {
    return this.http.get<boolean>(this.appsettings.API_ENDPOINT + `nominaEstibadores/oficina/getIsOficinaCedis?idOficina=${idOficina}`);
  }

  obtenerIsOficinaTipoOrdenCuadrillaGuiaAyudante(idOficina: number): Observable<boolean> {
    return this.http.get<boolean>(this.appsettings.API_ENDPOINT + `nominaEstibadores/oficina/getIsOficinaTipoOrdenCuadrillaGuiaAyudante?idOficina=${idOficina}`);
  }

  obtenerIsOficinaConIncentivos(idOficina: number): Observable<boolean> {
    return this.http.get<boolean>(this.appsettings.API_ENDPOINT + `nominaEstibadores/oficina/getIsOficinaConIncentivos?idOficina=${idOficina}`);
  }

  /* - Clasificacion Corte Oficina - */
  obtenerClasificacionesCorte(idOficina: number): Observable<ClasificacionCorteOficina[]> {
    return this.http.get<ClasificacionCorteOficina[]>(this.appsettings.API_ENDPOINT + `nominaEstibadores/clasificacionCorteOficina/getClasificacionesCorte/${idOficina}`).pipe(
      map((response: any) => response as ClasificacionCorteOficina[]),
      catchError(e => {
        return throwError(e);
      })
    );
  }

  obtenerClasificacionCortePorOficinaYPuesto(idOficina: number, idPuesto: number): Observable<ClasificacionCorteOficina> {
    return this.http.get<ClasificacionCorteOficina>(this.appsettings.API_ENDPOINT + `nominaEstibadores/clasificacionCorteOficina/getClasificacionCorte?idOficina=${idOficina}&idPuesto=${idPuesto}`).pipe(
      map((response: any) => response as ClasificacionCorteOficina),
      catchError(e => {
        return throwError(e);
      })
    );
  }

  /* - Descuentos - */
  obtenerDescuento(idDescuento: number): Observable<DescuentoCorte> {
    return this.http.get<DescuentoCorte>(this.appsettings.API_ENDPOINT + `nominaEstibadores/descuentoCorte/${idDescuento}`).pipe(
      map((response: any) => response as DescuentoCorte),
      catchError(e => {
        return throwError(e);
      })
    );
  }

  guardarDescuento(descuentoCorte: DescuentoCorte): Observable<DescuentoCorte> {
    return this.http.post(this.appsettings.API_ENDPOINT + 'nominaEstibadores/descuentoCorte/guardarDescuento', descuentoCorte).pipe(
      map((response: any) => response as DescuentoCorte),
      catchError(e => {
        return throwError(e);
      })
    );
  }

  eliminarDescuento(idDescuento: number): Observable<any> {
    return this.http.delete(this.appsettings.API_ENDPOINT + `nominaEstibadores/descuentoCorte/${idDescuento}`).pipe(
      catchError(e => {
        return throwError(e);
      })
    );
  }

  /* - Estibadores Nuevos - */
  obtenerEstibadoresNuevosActivos(idOficina: number): Observable<EstibadorNuevo[]> {
    return this.http.get<EstibadorNuevo[]>(this.appsettings.API_ENDPOINT + `nominaEstibadores/estibadorNuevo/obtenerEstibadoresActivos?idOficina=${idOficina}`).pipe(
      map((response: any) => response as EstibadorNuevo[]),
      catchError(e => {
        return throwError(e);
      })
    );
  }

  insertarEstibadorNuevo(estibadorNuevo: EstibadorNuevo): Observable<any> {
    return this.http.post(this.appsettings.API_ENDPOINT + 'nominaEstibadores/estibadorNuevo/insertarEstibador', estibadorNuevo).pipe(
      catchError(e => {
        return throwError(e);
      })
    );
  }

  actualizarEstibadorNuevo(estibadorNuevo: EstibadorNuevo): Observable<any> {
    return this.http.put(this.appsettings.API_ENDPOINT + 'nominaEstibadores/estibadorNuevo/actualizarEstibador', estibadorNuevo).pipe(
      catchError(e => {
        return throwError(e);
      })
    );
  }

  actualizarSueldoDeEstibadoresNuevos(arrEstibadorNuevo: EstibadorNuevo[]): Observable<any> {
    return this.http.put(this.appsettings.API_ENDPOINT + 'nominaEstibadores/estibadorNuevo/actualizarSueldoDeEstibadores', arrEstibadorNuevo).pipe(
      catchError(e => {
        return throwError(e);
      })
    );
  }

  eliminarEstibadorNuevo(idEstibador: number, idPersonalModificacion: number): Observable<any> {
    return this.http.delete(this.appsettings.API_ENDPOINT + `nominaEstibadores/estibadorNuevo/eliminarEstibador?idEstibador=${idEstibador}&idPersonalModificacion=${idPersonalModificacion}`).pipe(
      catchError(e => {
        return throwError(e);
      })
    );
  }

  /* - Puestos - */
  obtenerPuestosParaEstibadoresOficina(idOficina: number): Observable<Puesto[]> {
    return this.http.get<Puesto[]>(this.appsettings.API_ENDPOINT + `nominaEstibadores/puesto/obtenerPuestosEstibadoresOficina?idOficina=${idOficina}`).pipe(
      map((response: any) => response as Puesto[]),
      catchError(e => {
        return throwError(e);
      })
    );
  }

  /* - Tipo Orden - */
  obtenerTiposOrdenParaEstibadoresPorClasificacion(idClasificacionCorte: number): Observable<TipoOrden[]> {
    return this.http.get<TipoOrden[]>(this.appsettings.API_ENDPOINT + `nominaEstibadores/tipoOrden/obtenerTiposOrdenByClasificacion?idClasificacionCorte=${idClasificacionCorte}`).pipe(
      map((response: any) => response as TipoOrden[]),
      catchError(e => {
        return throwError(e);
      })
    );
  }

  /* - Cuadrilla - */
  obtenerCuadrillasPorOficina(idOficina: number): Observable<Cuadrilla[]> {
    return this.http.get<Cuadrilla[]>(this.appsettings.API_ENDPOINT + `nominaEstibadores/cuadrilla/obtenerCuadrillas?idOficina=${idOficina}`).pipe(
      map((response: any) => response as Cuadrilla[]),
      catchError(e => {
        return throwError(e);
      })
    );
  }

  /* - EstibadorCuadrilla - */
  obtenerNumEstibadoresPorCuadrilla(idCuadrilla: number): Observable<number> {
    return this.http.get<number>(this.appsettings.API_ENDPOINT + `nominaEstibadores/estibadorCuadrilla/obtenerNumEstibadoresCuadrilla?idCuadrilla=${idCuadrilla}`).pipe(
      map((response: any) => response as number),
      catchError(e => {
        return throwError(e);
      })
    );
  }

  /* - Faltas - */
  obtenerIncentivo(idIncentivo: number): Observable<IncentivoCorte> {
    return this.http.get<IncentivoCorte>(this.appsettings.API_ENDPOINT + `nominaEstibadores/incentivoCorte/${idIncentivo}`).pipe(
      map((response: any) => response as IncentivoCorte),
      catchError(e => {
        return throwError(e);
      })
    );
  }

  autorizarIncentivo(incentivoCorte: IncentivoCorte): Observable<IncentivoCorte> {
    return this.http.post(this.appsettings.API_ENDPOINT + 'nominaEstibadores/incentivoCorte/guardarIncentivo', incentivoCorte).pipe(
      map((response: any) => response as IncentivoCorte),
      catchError(e => {
        return throwError(e);
      })
    );
  }

  eliminarIncentivo(idIncentivo: number): Observable<any> {
    return this.http.delete(this.appsettings.API_ENDPOINT + `nominaEstibadores/incentivoCorte/${idIncentivo}`).pipe(
      catchError(e => {
        return throwError(e);
      })
    );
  }
}
