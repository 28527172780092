import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Oficina } from '../interfaces/oficina';

import { AppsettingsComponent } from '../app-settings/appsettings.component'
/*
* Título                    : agencia.service.ts
* Descripción               : Service para el apartado de Catálogo de Agencias
* Compañía                  : Transportes Castores de Baja California S.A. de C.V. – Área de Desarrollo
* Fecha de creación         : 2021-10-12
* Autor                     : Pablo Velazquez Martinez 
* Versión                   : 1.0.0
* ID Requerimiento/Ticket   : NULL
*/
@Injectable()
export class AgenciaService {

  constructor(private appsettings: AppsettingsComponent, private http: HttpClient, private router: Router) { }
  
  /*
  * Título                    : findOficinaAgenciaByIdOficina
  * Descripción               : Obtención de las agencias Activas
  * Fecha de creación         : 2021-10-04
  * Autor                     : Pablo Velazquez Martinez
  * Versión                   : 1.0.0
  * ID Requerimiento/Ticket   : NULL
  */
  findOficinaAgenciaByIdOficina(oficina: number):Observable<any>{
    return this.http.get<Oficina[]>(this.appsettings.API_ENDPOINT + `oficina/findAgenciaByIdOficina/${oficina}`);
  }
}