import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSnackBar, MatTableDataSource, MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import * as moment from 'moment';
import { TalonesLogService } from '../../../services/talones-log.service';

@Component({
  selector: 'app-correccion-timbrado-log',
  templateUrl: './correccion-timbrado-log.component.html',
  styleUrls: ['./correccion-timbrado-log.component.css']
})
export class CorreccionTimbradoLogComponent implements OnInit {
  // Variables 
  private cargandoVisible: Boolean = false
  private log : any[]
  private type: String = null
  private status = "si"

  // Filtros
  private formFecha: String = ''
  private formTalon: String = ''
  private now = new Date()

  constructor(
    private logService: TalonesLogService,
    public snackBar: MatSnackBar,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    // Valida que busqueda se realizara
    switch (this.data.typeSearch) {
      case "one":
        this.type = "one";
        this.findByClaTalon(this.data.claTalon)
        break;

      case "all":
        this.type = "all";
        this.findAllCorrecionTimbradoByFilter(this.data)
        break;
    
      default:
        break;
    }
  }

  // Obtiene los log de un talon especifico
  findByClaTalon (claTalon: String): void {
    this.cargandoVisible = true
    // Respuesta del servicio
    this.logService.findByClaTalon(claTalon).subscribe(
      response => {
        this.openSnackBar('Listado de Correcciones Encontradas', '⟴', 1500)
        this.log = response
        this.cargandoVisible = false        
      },
      error => {
        this.openSnackBar('Error en el servidor', '⛔', 3000)
      }
    )
  }

  //Obtiene los log de los talones 
  findAllCorrecionTimbradoByFilter(data: any) {
    this.cargandoVisible = true
    // Respuesta del servicio
    delete this.data.typeSearch
    this.logService.findAllCorrecionTimbradoByFilter(data).subscribe(
      response => {
        this.log = response
        this.cargandoVisible = false  
        this.cdr.detectChanges();
      },
      error => {
        this.openSnackBar('Error en el servidor', '⛔', 3000)
      }
    )
  }

  goFilters() {
    var date
    if (this.formFecha == '') {
      date = ''
    } else {
      date = moment(this.formFecha.toString()).local().format('YYYY-MM-DD')
    }

    const dataFilter = {
      claTalon: this.formTalon, 
      idPersonal: "",
      fechaMod: date
    }
    this.findAllCorrecionTimbradoByFilter(dataFilter);
  }

  // Función generica para mostrar los mensajes con las notificaciones de los resultados de las acciones
  openSnackBar(message: string, action: string, tiempo: number): void {
    this.snackBar.open(message, action, {
      duration: tiempo
    })
  }

  // Solo permite numeros
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false
    }
    return true
  }

}