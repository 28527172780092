import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatSnackBar, MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { TalonesLogService } from '../../../services/talones-log.service';

@Component({
  selector: 'app-talones-log-item',
  templateUrl: './talones-log-item.component.html',
  styleUrls: ['./talones-log-item.component.css']
})
export class TalonesLogItemComponent implements OnInit {

  @Input() log: any[];

  constructor(
    private logService: TalonesLogService,
    public snackBar: MatSnackBar,
  ) { }

  ngOnInit() {  }

}
