import {
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  ViewChild,
  HostListener,
  Directive,
  AfterViewInit,
  Inject
} from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MediaMatcher } from '@angular/cdk/layout';
import { AuthService } from '../../../authentication/login/auth.service';
import { Router } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { MenuItems } from '../../../shared/menu-items/menu-items';
import { MatDialog } from "@angular/material";
import { DialogCambioOficinaComponent } from '../../../shared/modals/cambio-oficina/dialog-cambio-oficina.component';
import { OficinaActual } from '../../../interfaces/oficina-actual';
import { Observable } from 'rxjs/Observable';
import { AppsettingsComponent } from '../../../app-settings/appsettings.component';

const SISTEMA: number = 4;
const MODULO: number = 7;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: []
})
export class AppSidebarComponent implements OnDestroy {
  public config: PerfectScrollbarConfigInterface = {};
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  status: boolean = true;
  itemSelect: number[] = []
  parentIndex: Number;
  childIndex: Number;
  permisos: any;
  idOficina: any;
  permisoCambioOficina: any = 0;
  private oficinaActual: OficinaActual;
  private oficinaActual$: Observable<OficinaActual>;
  menucompleto;
  nombre: string = (this.authService.usuario.nombre ? this.authService.usuario.nombre : '') + ' ' + (this.authService.usuario.apellido ? this.authService.usuario.apellido : '');
  usuario: string = this.authService.usuario.username;
  departamento: string = this.authService.usuario.departamento;
  plazaOficina: string = this.authService.usuario.plazaOficina;
  plaOficinaVirtual: string;

  setClickedRow(i, j) {
    this.parentIndex = i;
    this.childIndex = j;
  }
  subclickEvent() {
    this.status = true;
  }
  scrollToTop() {
    document.querySelector('.page-wrapper').scroll({
      top: 0,
      left: 0
    });
  }

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    private authService: AuthService, private router: Router, public snackBar: MatSnackBar,
    public dialog: MatDialog, public AppsettingsComponent: AppsettingsComponent)
  {
  
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.idOficina = (this.authService.usuario.idOficina == undefined ||
                      this.authService.usuario.idOficina == null)?
                      "" : this.authService.usuario.idOficina;
    this.menucompleto = menuItems.getMenuitem();
    this.permisos = authService.obtenerPermisosModulo(SISTEMA,MODULO);
    if(authService.validaPermisoCambioOficina(this.permisos, this.idOficina))
    {
      this.permisoCambioOficina = 1;
      this.oficinaActual$ = this.authService.getOficinaActual$();
      this.oficinaActual$.subscribe(oficinaActual =>
      {
        this.plaOficinaVirtual = (oficinaActual.idOficina != 1 && oficinaActual.idOficina != undefined
                                    && oficinaActual.idOficina != null && !isNaN(oficinaActual.idOficina)) ?
                                    '-> ' + this.authService.oficinaActual.plaza : "";
      })
    }
  }

  // alert snack
  openSnackBar(message: string, action: string, tiempo: number) {
    this.snackBar.open(message, action, {
      duration: tiempo
    });
  }
  /* Invoca el método para que cargue la sección para cambiar oficina, recibiendo el resultado */
  cambiarOficina(){
   let array = [];
    array.push({'idOficina': this.idOficina,'plazaOficina': this.plazaOficina});

    let modal = this.dialog.open(DialogCambioOficinaComponent, {
        data: array
      });
      modal.afterClosed().subscribe(result => {
        console.log("cerro el modal");
        console.log(result);
        if (result != undefined && result.hasOwnProperty('respuesta') && result['respuesta'] == true) {
            //this.plaOficinaVirtual = (result['datos']['oficina'] == 1)? "" : '-> ' + result['datos']['plaza'];
            this.openSnackBar('Se cambio a la oficina '+ result['datos']['plaza'] + ' correctamente', '⟴',1000);
        }
        else
        {

        }
      })
  }
  //Cerrar sesión :)
  logout(): void {
    this.authService.cerrarSesion();
    this.openSnackBar('Sesión cerrada.', '👋',3000);
    this.router.navigate(['/authentication/login']);
  }
  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
}
