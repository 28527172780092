import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from "@angular/material";
import { SolicitarMaterialComponent } from "../../../systems/paqueteria/solicitar-material/solicitar-material.component";
import { ReporteSolicitudesMaterial } from "../../../interfaces/paqueteria/reporte-solicitudes-material";
import { DatosSeguimiento, DatosSeguimientoSap, SalidaMaterial } from "../../../interfaces/paqueteria/datos-seguimiento";
import { EyeService } from "../../../services/eye.service";
import { Materiales } from "../../../interfaces/paqueteria/materiales";
import { cadenaMaterialesCompleta } from "../../helpers/helpers";


@Component({
  selector: "app-seguimiento-solicitud-sap-eye",
  templateUrl: "./seguimiento-solicitud-sap-eye.component.html",
  styleUrls: ["./seguimiento-solicitud-sap-eye.component.css"],
})

export class SeguimientoSolicitudSapEyeComponent implements OnInit {
  public estatus: string = "salida";
  public lstDatosSeguimiento: DatosSeguimiento[] = [];
  private  cadenaMateriales: string[] = cadenaMaterialesCompleta;
  public lstDatosSeguimientoSap: DatosSeguimientoSap = {} as DatosSeguimientoSap;
  public lstMateriales: SalidaMaterial[] = [];
  private nombreMateriales: Materiales[] = [];

  constructor(
    public dialogRef: MatDialogRef<SolicitarMaterialComponent>,
    @Inject(MAT_DIALOG_DATA) public datosReporte: ReporteSolicitudesMaterial,
    private eyeService: EyeService,
    public snackBar: MatSnackBar
  ) { }

  public async ngOnInit() {
    this.obtenerDatos();
    await this.getSeguimientoSolicitudSap();

  }

  public obtenerDatos() : void {
    this.eyeService
      .getDatosSeguimientoByIdSolicitud(this.datosReporte.idSolicitud)
      .subscribe((lstDatos: DatosSeguimiento[]) => {
        lstDatos.forEach((element: DatosSeguimiento) => {
          const fechaHora = new Date(`${element.fecha}T${element.hora}`);
          element.fecha = Date.parse(fechaHora.toDateString()).toString();
          element.hora = `${fechaHora.getHours()}:${fechaHora.getMinutes()}:${fechaHora.getSeconds()}`;
          this.lstDatosSeguimiento.push(element);
        });
        const lastIndex = this.lstDatosSeguimiento.length - 1;
        this.lstDatosSeguimiento[lastIndex].ultimo = true;
      });
  }

    private async getMateriales()  : Promise<void> {
      await this.eyeService.getMaterialesSolicitud(this.datosReporte.idSolicitud, this.datosReporte.tipoSolicitud).toPromise().then((lstSalidaMaterial: SalidaMaterial[]) => {
        this.lstMateriales = lstSalidaMaterial;
      });
  }

  private  getSeguimientoSolicitudSap() : Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      await this.getMateriales();
      await this.getNombreMateriales();

      this.eyeService.getSolicitudSap(this.datosReporte.folio.toString()).subscribe({
        next: (response: DatosSeguimientoSap) => {
        this.lstDatosSeguimientoSap = response;
        if(this.lstDatosSeguimientoSap.listMaterial != undefined){
            this.lstDatosSeguimientoSap.listMaterial.map((material) => {
          material.listSurtido.map((surtido) => {
            if(surtido.fechaRegistro.length == 8){
              const formattedDate : string = `${surtido.fechaRegistro.substring(0, 4)}-${surtido.fechaRegistro.substring(4, 6)}-${surtido.fechaRegistro.substring(6, 8)}`;
              const date : Date = new Date(formattedDate);
              const formattedString : string = date.toLocaleDateString('es-ES', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
              surtido.fechaRegistro = formattedString;
            }

          });
        });

        this.lstDatosSeguimientoSap.listMaterial.map((material) => { material.nombreMaterial = this.nombreMateriales.find((materialBusqueda) => materialBusqueda.claveMaterial.toString() == material.material).nombre; });
        this.lstMateriales.map((material) => { material.cantidadSolicitada = material.unidadMedida == "mts" ?  this.eyeService.calcularCantidad(material.idMaterial, material.cantidadSolicitada) : material.cantidadSolicitada; });
        this.lstDatosSeguimientoSap.listMaterial.map((material) => { material.totalSurtir = this.lstMateriales.find((materialBusqueda) => materialBusqueda.claveMaterial.toString() == material.material).cantidadSolicitada; });
        this.lstDatosSeguimientoSap.listMaterial.map((material) => { material.totalSurtido = material.listSurtido.reduce((total, surtido) => total + parseInt(surtido.cantidadSurtida), 0); });
        this.lstDatosSeguimientoSap.listMaterial.map((material) => { material.ultimo = material.totalSurtir == material.totalSurtido; });
          
        }
      }, error: (error) => {
       this.openSnackBar("No se encontró la solicitud en SAP", "Cerrar"); this.dialogRef.close(); 
       return; 
      }
      });

    });
  }

  public asignarNombreMaterial(idMaterial: number) : string {
    let material = this.nombreMateriales.find((material) => material.claveMaterial == idMaterial);
    return material != undefined ? material.nombre : "";

  }

  public asignarUnidadMedida(idMaterial: number) : string {
    let material = this.lstMateriales.find((material) => material.claveMaterial == idMaterial);
    return material.unidadMedida == "mts" ? this.eyeService.convertirUnidadMedida(material.idMaterial, material.unidadMedida) : material.unidadMedida;
  }

  public asignarCantidadSolicitada(idMaterial: number) : number {
    let material : SalidaMaterial = this.lstMateriales.find((material : SalidaMaterial ) => material.claveMaterial == idMaterial);
    return  material != undefined  ? material.cantidadSolicitada : 0;
  }

  public asignarDescripcion(idMaterial: number) {
    let material : Materiales = this.nombreMateriales.find((material : Materiales) => material.claveMaterial == idMaterial);
    return material != undefined  ? material.descripcion : "";
  }

  private async getNombreMateriales(): Promise<void> {
      const lstMateriales: Materiales[] = await this.eyeService.getMaterialesByNombre(this.cadenaMateriales).toPromise();
      
      this.lstMateriales = this.lstMateriales.map((material: SalidaMaterial) => {
        const foundMaterial = lstMateriales.find((materiales: Materiales) => materiales.claveMaterial === material.claveMaterial);
        return { ...material, nombreMaterial: foundMaterial ? foundMaterial.nombre : material.nombreMaterial };
      });
      
      this.nombreMateriales = lstMateriales;
  }
  
  public cerrar() : void {
    this.dialogRef.close();
  }

  openSnackBar(message: string, action: string) : void {
    this.snackBar.open(message, action, {
      duration: 3000
    });
  }

}
