import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppsettingsComponent } from '../app-settings/appsettings.component';

@Injectable({
  providedIn: 'root'
})
export class TalonesErrorService {
  constructor(
    private appsettings: AppsettingsComponent,
    private http: HttpClient
  ) { }

  

  /*
  * Título                    : findErrorsByTalon
  * Descripción               : Obtener el último error por talon.
  * Fecha de creación         : 2021-11-26 - 2022-03-30
  * Autor                     : Moises Lopez Arrona. [moisesarrona] - Alma Laura Meza [almalaurameza]
  * Versión                   : 1.0.1
  * ID Requerimiento/Ticket   : NULL
  */
  findErrorsByTalon(data: any): Observable<any> {
    return this.http.post(this.appsettings.API_ENDPOINT + `talones/findErrorsByTalon`, data).pipe(
      catchError(error => {
        if (error.error.message === 'No se pudo conectar a la oficina.'
          || error.error.message === 'Error al conectarse a la oficina o servidor vinculado no encontrado') {
          return 'noConnection';
        }
        return error.error.message
      })
    );
  }

  /*
  * Título                    : fixErrorTalones
  * Descripción               : Arregla los errores del timbrado de RFC.
  * Fecha de creación         : 2021-12-20 - 2022-03-30
  * Autor                     : Moises Lopez Arrona. [moisesarrona] - Alma Laura Meza [almalaurameza]
  * Versión                   : 1.0.1
  * ID Requerimiento/Ticket   : NULL
  */
  fixErrorRFC(data:any): Observable<any> {
    var tipotalon=data.idtipo;
    if(tipotalon=='13'){
      return this.http.put(this.appsettings.API_ENDPOINT + `talones/updateRFCTimbrado13/`, data)
    }
    else {
    switch(data.tipoPago){
        case 1:
          return this.http.put(this.appsettings.API_ENDPOINT + `talones/updateRFCTimbrado/`, data)   
        case 2:
          return this.http.put(this.appsettings.API_ENDPOINT + `talones/updateRFCTimbrado/`, data) 
        case 3:
          return this.http.put(this.appsettings.API_ENDPOINT + `talones/updateRFCTimbradoTipo3/`, data)
    }
   }
  };

  /*
  * Título                    : fixErrorClaveUnidad
  * Descripción               : Arregla los errores del timbrado de clave unidad.
  * Fecha de creación         : 2021-12-20 -2022-03-30
  * Autor                     : Moises Lopez Arrona. [moisesarrona] - Alma Laura Meza [almalaurameza]
  * Versión                   : 1.0.1
  * ID Requerimiento/Ticket   : NULL
  */
  fixErrorClaveUnidad(data: any): Observable<any> {
    return this.http.put(this.appsettings.API_ENDPOINT + `talones/updateClaveUnidad/`, data)
  }

  /*
  * Título                    : fixErrorLicencia
  * Descripción               : Arregla los errores del timbrado de licencia.
  * Fecha de creación         : 2022-01-06 - 2022-03-30
  * Autor                     : Moises Lopez Arrona. [moisesarrona] - Alma Laura Meza [almalaurameza]
  * Versión                   : 1.0.1
  * ID Requerimiento/Ticket   : NULL
  */
  fixErrorLicencia(data: any): Observable<any> {
    return this.http.put(this.appsettings.API_ENDPOINT + `talones/updateLicencia/`, data)
  }

  /*
  * Título                    : fixErrorPlaca
  * Descripción               : Arregla los errores del timbrado de licencia.
  * Fecha de creación         : 2022-03-01
  * Autor                     : Alma Laura Meza M. [desarrolloti33]
  * Versión                   : 1.0.1
  * ID Requerimiento/Ticket   : NULL
  */
  fixErrorPlaca(data: any): Observable<any> {
    return this.http.put(this.appsettings.API_ENDPOINT + `talones/updatePlaca/`, data)
  }
  
    /*
  * Título                    : updatePlacafolio
  * Descripción               : actualiza placa por folio
  * Fecha de creación         : 2022-12-06
  * Autor                     : Mario Garcia
  * Versión                   : 2.0.1.0
  * ID Requerimiento/Ticket   : NULL
  */

    fixPlacafolio(dataplaca: any): Observable<any> {
      return this.http.put(this.appsettings.API_ENDPOINT + `talones/updatePlacafolio/`, dataplaca)
    }
        /*
    * Título                    : insertxmlbd
    * Descripción               : inserta xml obtenido sat en bd
    * Fecha de creación         : 2022-12-06
    * Autor                     : Mario Garcia
    * Versión                   : 2.0.1.0
    * ID Requerimiento/Ticket   : NULL
    */
  
    insertxmlbd(dataformbd: any): Observable<any> {
      return this.http.put(this.appsettings.API_ENDPOINT + `talones/insertxmlbd/`, dataformbd)
    }
  /*
  * Título                    : changeStatusTalon
  * Descripción               : Cambia el estatus de los errores no encontrados.
  * Fecha de creación         : 2021-12-20
  * Autor                     : Moises Lopez Arrona. [moisesarrona]
  * Versión                   : 1.0.0
  * ID Requerimiento/Ticket   : NULL
  */
  changeStatusTalon(data: any): Observable<any> {
    return this.http.put(this.appsettings.API_ENDPOINT + `talones/changeStatusTalon/`, data)
  }

  /*
   * Título                    : changeStatusTalonXML
   * Descripción               : Cambia el estatus de los talones timbrados sin XML.
   * Fecha de creación         : 2021-12-21 - 2022-03-30
   * Autor                     : Moises Lopez Arrona. [moisesarrona] - Alma Laura Meza [almalaurameza]
   * Versión                   : 1.0.1
   * ID Requerimiento/Ticket   : NULL
   */
  changeStatusTalonXML(data: any) {
    return this.http.put(this.appsettings.API_ENDPOINT + `talones/changeStatusTalonXML/`, data)
  }
  /*
  * Título                    : findAllTipoError
  * Descripción               : Consulta el catálogo de errores que se corrigen en el Hub
  * Fecha de creación         : 2022-02-22
  * Autor                     : Alma laura Meza M. [desarrollo33]
  * Versión                   : 1.0.0
  * ID Requerimiento/Ticket   : NULL
  */
  findAllTipoError() {
    return this.http.get<[]>(this.appsettings.API_ENDPOINT + `talones/findAllTipoError`)
  }
}
