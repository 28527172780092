import { Permisos } from "../../interfaces/paqueteria/permisos";
 
export const displayedColumnsKardex = ["position", "tipoMovimiento", "material", "cantidad", "unidadMedida", "personal", "fecha", "hora"];
export const cadenaMaterialesCompleta = ["cinta canela", "emplaye", "poliburbuja", "carton corrugado", "fleje", "polipack", "esquinero", "sellos fleje",  "caja carton chica", "etiqueta","etiqueta rfid","clavo 3 in","clavo 4 in","serrucho","bolsa mensajeria","pinzas para flejado","restiradora","martillo","caja carton chica", "caja carton mediana", "caja carton grande", "etiqueta sensible hum ngo", "etiqueta sensible hum roja", "etiqueta mcia ngo", "etiqueta mcia roja", "etiqueta estibable ngo", "etiqueta estibable roja", "etiqueta no desentarimar ngo", "etiqueta no desentarimar roja", "etiqueta hacia arriba ngo", "etiqueta hacia arriba roja", "etiqueta no estibar ngo", "etiqueta no estibar roja", "etiqueta fragil ngo", "etiqueta fragil roja"];
export const cadenaMaterialesVenta = ["cinta canela", "emplaye", "poliburbuja", "carton corrugado", "fleje", "polipack", "esquinero", "sellos fleje", "caja carton chica","clavo 3 in","clavo 4 in","serrucho","bolsa mensajeria","pinzas para flejado","restiradora","martillo","caja carton chica", "caja carton mediana", "caja carton grande", "etiqueta sensible hum ngo", "etiqueta sensible hum roja", "etiqueta mcia ngo", "etiqueta mcia roja", "etiqueta estibable ngo", "etiqueta estibable roja", "etiqueta no desentarimar ngo", "etiqueta no desentarimar roja", "etiqueta hacia arriba ngo", "etiqueta hacia arriba roja", "etiqueta no estibar ngo", "etiqueta no estibar roja", "etiqueta fragil ngo", "etiqueta fragil roja"];
export const cadenaMaterialesReacondicionamientoTodas = ["cinta canela", "emplaye", "poliburbuja", "carton corrugado"];
export const cadenaMaterialesReacondicionamientoOficina2 = ["cinta canela", "emplaye", "poliburbuja", "carton corrugado"];
export const cadenaMaterialesReacondicionamientoOficina3 = ["cinta canela", "emplaye"];
export const displayedColumnsVentas = ["talon", "oficina", "cintaCanela", "emplaye", "poliburbuja", "cartonCorrugado", "fleje", "polipack", "esquinero", "sellosFleje",  "cajaCartonChica", "cajaCartonMediana", "cajaCartonGrande", "tarimaMadera","etiqueta", "etiquetaGrande"];
export const filterEtiquetas  = ["etiqueta sensible hum ngo", "etiqueta sensible hum roja", "etiqueta mcia ngo", "etiqueta mcia roja", "etiqueta estibable ngo", "etiqueta estibable roja", "etiqueta no desentarimar ngo", "etiqueta no desentarimar roja", "etiqueta hacia arriba ngo", "etiqueta hacia arriba roja", "etiqueta no estibar ngo", "etiqueta no estibar roja", "etiqueta fragil ngo", "etiqueta fragil roja","pel.estirable","martillo","clavo 3 in","clavo 4 in","serrucho","bolsa mensajeria","pinzas para flejado","restiradora","cinchos","cinchos plastico","etiqueta rfid" ];
export function validarPermisos( lstPermisos: Permisos, lstPermisosValidar : string[], router: any, snackBar: any) {
    let permisosValidar = lstPermisosValidar;
    let permisos = lstPermisos;
    let permisosFaltantes = permisosValidar.filter(permiso => permisos[permiso] == 0);
    if(permisosFaltantes.length > 0){
        snackBar.open("No tienes permisos para este recurso", "⛔", {
        duration: 3000
        });
        router.navigate(['/authentication/e401']);
        return false;
    }
    return true;
}

export function convertDate(dateString: string): number {
    const [day, month, year] = dateString.split('/').map(Number);
    return new Date(year, month - 1, day).getTime();
  }
