import { Component, Inject, OnInit } from '@angular/core';
import { EstibadorNuevo } from '../../../interfaces/nomina-estibadores/estibador-nuevo';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NominaEstibadoresService } from '../../../services/nomina-estibadores.service';
import { Puesto } from '../../../interfaces/puesto';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import * as _moment from 'moment';
import { TipoOrden } from '../../../interfaces/nomina-estibadores/tipo-orden';
import { Cuadrilla } from '../../../interfaces/nomina-estibadores/cuadrilla';
import { ClasificacionCorteOficina } from '../../../interfaces/nomina-estibadores/clasificacion-corte-oficina';
import UtilsValidateFormat from '../../helpers/utils-validate-format';

const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-dialog-estibador-nuevo',
  templateUrl: './dialog-estibador-nuevo.component.html',
  styleUrls: ['./dialog-estibador-nuevo.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class DialogEstibadorNuevoComponent implements OnInit {
  public isLoading: boolean = false;

  nuevoEstibador: boolean = true;
  estibadorNuevo: EstibadorNuevo;
  arrPuestosEstibadores: Puesto[] = [];
  public arrTipoOrden: TipoOrden[];
  private arrClasificacionesConTiposOrden: number[] = [3, 4];
  public isOficinaConTiposOrden: boolean = false;
  public arrCuadrilla: Cuadrilla[];

  private clasificacionCorteOficina: ClasificacionCorteOficina = new ClasificacionCorteOficina();

  estibadorNuevoFormGroup: FormGroup;

  constructor(public dialogRef: MatDialogRef<DialogEstibadorNuevoComponent>,
    private formBuilder: FormBuilder,
    private nominaEstibadoresService: NominaEstibadoresService,
    @Inject(MAT_DIALOG_DATA) public data: { nuevoEstibador: boolean, estibadorNuevo: EstibadorNuevo }
  ) {
    this.nuevoEstibador = data.nuevoEstibador;
    this.estibadorNuevo = data.estibadorNuevo;
    this.nuevoEstibador ? this.obtenerPuestosDeEstibadores() : this.obtenerEstibadorNuevo();
  }

  ngOnInit() {
    this.estibadorNuevoFormGroup = this.formBuilder.group({
      nombreControl: ["", Validators.required],
      apellidoPaternoControl: ["", Validators.required],
      apellidoMaternoControl: [""],
      curpControl: ["", Validators.required],
      puestoControl: [new Puesto(), Validators.required],
      tipoOrdenControl: [null, Validators.required],
      cuadrillaControl: [null],
      fechaIngresoControl: [new FormControl(moment()), Validators.required]
    });

    this.estibadorNuevoFormGroup.controls['nombreControl'].setValue(this.estibadorNuevo.nombre);
    this.estibadorNuevoFormGroup.controls['apellidoPaternoControl'].setValue(this.estibadorNuevo.apellidoPaterno);
    this.estibadorNuevoFormGroup.controls['apellidoMaternoControl'].setValue(this.estibadorNuevo.apellidoMaterno);
    this.estibadorNuevoFormGroup.controls['curpControl'].setValue(this.estibadorNuevo.curp);
    this.estibadorNuevoFormGroup.controls['fechaIngresoControl'].setValue(moment(this.estibadorNuevo.fechaIngreso));

    this.isLoading = true;
    this.nominaEstibadoresService.obtenerIsOficinaTipoOrdenCuadrillaGuiaAyudante(this.estibadorNuevo.oficina.oficina).subscribe(response => {
      this.isOficinaConTiposOrden = response;
      this.isLoading = false;
    });
  }

  obtenerPuestosDeEstibadores() {
    this.isLoading = true;
    this.nominaEstibadoresService.obtenerPuestosParaEstibadoresOficina(this.estibadorNuevo.oficina.oficina).subscribe(response => {
      this.arrPuestosEstibadores = response as Puesto[];
      this.estibadorNuevo.puesto ? this.estibadorNuevoFormGroup.controls['puestoControl'].setValue(this.arrPuestosEstibadores[this.arrPuestosEstibadores.findIndex(puesto => puesto.idPuesto == this.estibadorNuevo.puesto.idPuesto)]) : null;
      this.isLoading = false;
    });
  }

  cambioDePuesto() {
    this.isLoading = true;
    this.nominaEstibadoresService.obtenerClasificacionCortePorOficinaYPuesto(
      this.estibadorNuevo.oficina.oficina,
      this.estibadorNuevoFormGroup.controls['puestoControl'].value.idPuesto).subscribe(response => {
        this.clasificacionCorteOficina = response;
        this.isLoading = false;
        this.obtenerTiposOrdenParaEstibadores();
      });
  }

  obtenerTiposOrdenParaEstibadores(): void {
    this.isLoading = true;
    this.nominaEstibadoresService.obtenerTiposOrdenParaEstibadoresPorClasificacion(this.clasificacionCorteOficina.idClasificacionCorte).subscribe(response => {
      this.arrTipoOrden = response as TipoOrden[];
      this.isLoading = false;
      if (!this.isOficinaConTiposOrden ||
        !this.arrClasificacionesConTiposOrden.some((idClasificacionCorte) => idClasificacionCorte == this.clasificacionCorteOficina.idClasificacionCorte)) {
        this.estibadorNuevoFormGroup.controls['tipoOrdenControl'].disable();
        this.estibadorNuevoFormGroup.controls['tipoOrdenControl'].setValue(this.arrTipoOrden[this.arrTipoOrden.findIndex(tipoOrden => tipoOrden.idTipoOrden == 1)]);
        this.cambioDeTipoOrden();
      } else {
        this.estibadorNuevoFormGroup.controls['tipoOrdenControl'].enable();
      }
    });
  }

  cambioDeTipoOrden() {
    if (this.estibadorNuevoFormGroup.controls['tipoOrdenControl'].value.idTipoOrden != 2) {
      this.estibadorNuevoFormGroup.controls['cuadrillaControl'].clearValidators();
      this.estibadorNuevoFormGroup.controls['cuadrillaControl'].updateValueAndValidity();
    } else {
      this.estibadorNuevoFormGroup.controls['cuadrillaControl'].setValidators(Validators.required);
      this.obtenerCuadrillasPorOficina();
    }
  }

  obtenerCuadrillasPorOficina() {
    this.isLoading = true;
    this.nominaEstibadoresService.obtenerCuadrillasPorOficina(this.estibadorNuevo.oficina.oficina).subscribe(response => {
      this.arrCuadrilla = response;
      this.isLoading = false;
    });
  }

  obtenerEstibadorNuevo() {
    this.isLoading = true;
    this.nominaEstibadoresService.obtenerPuestosParaEstibadoresOficina(this.estibadorNuevo.oficina.oficina).subscribe(response => {
      this.arrPuestosEstibadores = response as Puesto[];

      this.nominaEstibadoresService.obtenerClasificacionCortePorOficinaYPuesto(
        this.estibadorNuevo.oficina.oficina,
        this.estibadorNuevo.puesto.idPuesto).subscribe(response => {
          this.clasificacionCorteOficina = response;

          this.nominaEstibadoresService.obtenerTiposOrdenParaEstibadoresPorClasificacion(this.clasificacionCorteOficina.idClasificacionCorte).subscribe(response => {
            this.arrTipoOrden = response as TipoOrden[];

            this.nominaEstibadoresService.obtenerCuadrillasPorOficina(this.estibadorNuevo.oficina.oficina).subscribe(response => {
              this.arrCuadrilla = response;

              if (!this.isOficinaConTiposOrden ||
                !this.arrClasificacionesConTiposOrden.some((idClasificacionCorte) => idClasificacionCorte == this.clasificacionCorteOficina.idClasificacionCorte)) {
                this.estibadorNuevoFormGroup.controls['tipoOrdenControl'].disable();

              } else {
                this.estibadorNuevoFormGroup.controls['tipoOrdenControl'].enable();
              }

              this.estibadorNuevoFormGroup.controls['puestoControl'].setValue(this.arrPuestosEstibadores[this.arrPuestosEstibadores.findIndex(puesto => puesto.idPuesto == this.estibadorNuevo.puesto.idPuesto)]);
              this.estibadorNuevoFormGroup.controls['tipoOrdenControl'].setValue(this.arrTipoOrden[this.arrTipoOrden.findIndex(tipoOrden => tipoOrden.idTipoOrden == this.estibadorNuevo.tipoOrden.idTipoOrden)]);
              if (this.estibadorNuevo.tipoOrden.idTipoOrden == 2) this.estibadorNuevoFormGroup.controls['cuadrillaControl'].setValue(this.arrCuadrilla[this.arrCuadrilla.findIndex(cuadrilla => cuadrilla.idCuadrilla == this.estibadorNuevo.cuadrilla.idCuadrilla)]);
              this.isLoading = false;
            });
          });
        });
    });
  }

  cerrarDialogo(): void {
    const array = {
      agregar: false
    };
    this.dialogRef.close(array);
  }

  agregar(): void {
    this.estibadorNuevo.nombre = this.estibadorNuevoFormGroup.controls['nombreControl'].value;
    this.estibadorNuevo.apellidoPaterno = this.estibadorNuevoFormGroup.controls['apellidoPaternoControl'].value;
    this.estibadorNuevo.apellidoMaterno = this.estibadorNuevoFormGroup.controls['apellidoMaternoControl'].value;
    this.estibadorNuevo.curp = this.estibadorNuevoFormGroup.controls['curpControl'].value;
    this.estibadorNuevo.puesto = this.estibadorNuevoFormGroup.controls['puestoControl'].value;
    this.estibadorNuevo.tipoOrden = this.estibadorNuevoFormGroup.controls['tipoOrdenControl'].value;
    this.estibadorNuevo.cuadrilla = this.estibadorNuevoFormGroup.controls['cuadrillaControl'].value;
    this.estibadorNuevo.fechaIngreso = this.estibadorNuevoFormGroup.controls['fechaIngresoControl'].value.toDate();

    this.estibadorNuevo.nombreCompleto = this.estibadorNuevo.nombre + ' ' + this.estibadorNuevo.apellidoPaterno + (this.estibadorNuevo.apellidoMaterno ? ' ' + this.estibadorNuevo.apellidoMaterno : '');

    const array = {
      agregar: true,
      estibadorNuevo: this.estibadorNuevo
    };
    this.dialogRef.close(array);
  }

  guardar(): void {
    this.estibadorNuevo.nombre = this.estibadorNuevoFormGroup.controls['nombreControl'].value;
    this.estibadorNuevo.apellidoPaterno = this.estibadorNuevoFormGroup.controls['apellidoPaternoControl'].value;
    this.estibadorNuevo.apellidoMaterno = this.estibadorNuevoFormGroup.controls['apellidoMaternoControl'].value;
    this.estibadorNuevo.curp = this.estibadorNuevoFormGroup.controls['curpControl'].value;
    this.estibadorNuevo.puesto = this.estibadorNuevoFormGroup.controls['puestoControl'].value;
    this.estibadorNuevo.tipoOrden = this.estibadorNuevoFormGroup.controls['tipoOrdenControl'].value;
    this.estibadorNuevo.cuadrilla = this.estibadorNuevoFormGroup.controls['cuadrillaControl'].value;
    this.estibadorNuevo.fechaIngreso = this.estibadorNuevoFormGroup.controls['fechaIngresoControl'].value.toDate();

    this.estibadorNuevo.nombreCompleto = this.estibadorNuevo.nombre + ' ' + this.estibadorNuevo.apellidoPaterno + (this.estibadorNuevo.apellidoMaterno ? ' ' + this.estibadorNuevo.apellidoMaterno : '');

    const array = {
      editar: true,
      estibadorNuevo: this.estibadorNuevo
    };
    this.dialogRef.close(array);
  }

  validateLettersAndNumbersFormatKeyPress(event: any) {
    UtilsValidateFormat.validateLettersAndNumbersFormatEvent(event);
  }

}
