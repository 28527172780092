import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { PolizaPagados } from '../interfaces/poliza-pagados';
import { MovimientosPolizaPagados } from '../interfaces/movimientos-poliza-pagados';
import { TalonesAbonadosNoContabilizados } from '../interfaces/talonesabonadosnocontabilizados';

import { AppsettingsComponent } from '../app-settings/appsettings.component'
/*
* Título                    : poliza-pagados.service.ts
* Descripción               : Service para el apartado de Catálogo de Poliza Pagados
* Compañía                  : Transportes Castores de Baja California S.A. de C.V. – Área de Desarrollo
* Fecha de creación         : 2020-06-26
* Autor                     : German Galaviz [germagafra]
* Versión                   : 1.0.0
* ID Requerimiento/Ticket   : NULL
*/
@Injectable()
export class PolizaPagadosService {

  constructor(private appsettings: AppsettingsComponent, private http: HttpClient, private router: Router) { }
  
  /*
  * Título                    : findPolizaPagadosByAll
  * Descripción               : Obtención de datos de las Polizas Pagados
  * Fecha de creación         : 2020-06-26
  * Autor                     : German Galaviz [germagafra]
  * Versión                   : 1.0.0
  * ID Requerimiento/Ticket   : NULL
  */
  findPolizaPagadosByAll(permisoE: number, idUsuario:number):Observable<any>{
    return this.http.get<PolizaPagados[]>(this.appsettings.API_ENDPOINT 
      + `/rutaporDefinir /${permisoE}/{$idUsuario}`);
  }

    /*
  * Título                    : findPolizaAndMovimientos
  * Descripción               : Obtención de los Movimientos de la Poliza por Id de Poliza
  * Fecha de creación         : 2020-07-09
  * Autor                     : German Galaviz [germagafra]
  * Versión                   : 1.0.0
  * ID Requerimiento/Ticket   : NULL
  */
  findPolizaAndMovimientos(poliza: string): Observable<any> {
    return this.http.get<MovimientosPolizaPagados[]>(this.appsettings.API_ENDPOINT 
      + `poliza/findPolizaAndMovimientosByIdPoliza/${poliza}`)
  }

    /*
  * Título                    : findPolizaMovimientosByFolio
  * Descripción               : Obtención de los Movimientos de la Poliza por Folio de Poliza
  * Fecha de creación         : 2020-06-26
  * Autor                     : German Galaviz [germagafra]
  * Versión                   : 1.0.0
  * ID Requerimiento/Ticket   : NULL
  */
  findPolizaMovimientosByFolio  (folio:number): Observable<any> {
    return this.http.get<PolizaPagados[]>(this.appsettings.API_ENDPOINT 
      + `poliza/findPolizaAndMovimientosByFolio/${folio}`)
  }
  
  /*
  * Título                    : findPolizaPagadosOficinaByRangoFechasConcepto
  * Descripción               : Listado de Polizas por Oficina, Rango de Fechas y Concepto
  * Fecha de creación         : 2020-07-27
  * Autor                     : German Galaviz [germagafra]
  * Versión                   : 1.0.0
  * ID Requerimiento/Ticket   : NULL
  */
  findPolizaPagadosOficinaByRangoFechasConcepto  (idOficina: number, finicio:string,  fFin:string, concepto: string): Observable<any> {
    return this.http.get<PolizaPagados[]>(this.appsettings.API_ENDPOINT 
      + `poliza/findPolizaByIdOficinaAndFechaAndFechaAndConcepto/${idOficina}/`
      + `fechaInicio/${finicio}/fechaFin/${fFin}/concepto/${concepto}`)
  }

  /*
  * Título                    : findPolizaPagadosOficinaByRangoFechas
  * Descripción               : Listado de Polizas por Oficina y Rango de Fechas
  * Fecha de creación         : 2020-07-27
  * Autor                     : German Galaviz [germagafra]
  * Versión                   : 1.0.0
  * ID Requerimiento/Ticket   : NULL
  */
  findPolizaPagadosOficinaByRangoFechas  (idOficina: number, finicio:string,  fFin:string): Observable<any> {
    return this.http.get<PolizaPagados[]>(this.appsettings.API_ENDPOINT 
      + `poliza/findPolizaByIdOficinaAndFechaRegistro/${idOficina}/`
      + `fechaInicio/${finicio}/fechaFin/${fFin}`)
  }
  /*
  * Título                    : findPolizaByIdOficinaAndMes
  * Descripción               : Listado de Polizas filtrados por Oficina y Mes
  * Fecha de creación         : 2020-07-09
  * Autor                     : German Galaviz [germagafra]
  * Versión                   : 1.0.0
  * ID Requerimiento/Ticket   : NULL
  */

  findPolizaByIdOficinaAndMes(oficina: number, mes: number): Observable<any>{
    return this.http.get<PolizaPagados[]>(this.appsettings.API_ENDPOINT 
      + `poliza/findPolizaByIdOficinaAndMes/${oficina}/mes/${mes}`)
  }

  /*
  * Título                    : procesaPolizaTalonByIdOficina
  * Descripción               : Contabilización de Talones por Oficina
  * Fecha de creación         : 2020-07-09
  * Autor                     : German Galaviz [germagafra]
  * Versión                   : 1.0.0
  * ID Requerimiento/Ticket   : NULL
  */

  procesaPolizaTalonByIdOficina(oficina: number, usuario: number): Observable<any>{
    return this.http.get<PolizaPagados[]>(this.appsettings.API_ENDPOINT 
      + `poliza/procesaPolizaTalonByIdOficina/${oficina}/usuario/${usuario}`)
  }
  /********* REPORTE DE TALONES ABONADOS NO CONTABILIZADOS *******************/
  /*
  * Título                    : findTalonesAbonadosByIdOficinaAndFechaAndFecha
  * Descripción               : Listado de Talones Abonados no contabilizados por Oficina y Rango de Fechas
  * Fecha de creación         : 2021-01-17
  * Autor                     : German Galaviz [germagafra]
  * Versión                   : 1.0.0
  * ID Requerimiento/Ticket   : NULL
  */

  findTalonesAbonadosByIdOficinaAndFechaAndFecha  (idOficina: number, finicio:string,  fFin:string): Observable<any> {
    return this.http.get<TalonesAbonadosNoContabilizados[]>(this.appsettings.API_ENDPOINT 
      + `poliza/findTalonesAbonadosByIdOficinaAndFechaAndFecha/${idOficina}/`
      + `fechaInicio/${finicio}/fechaFin/${fFin}`)
  }
  /*
  * Título                    : findTalonesAbonadosByIdOficinaAndFechaAndFecha
  * Descripción               : Listado de Talones Abonados Cancelados no contabilizados por Oficina y Rango de Fechas
  * Fecha de creación         : 2021-01-17
  * Autor                     : German Galaviz [germagafra]
  * Versión                   : 1.0.0
  * ID Requerimiento/Ticket   : NULL
  */

  findTalonesAbonadosCanceladosByIdOficinaAndFechaAndFecha  (idOficina: number, finicio:string,  fFin:string): Observable<any> {
    return this.http.get<TalonesAbonadosNoContabilizados[]>(this.appsettings.API_ENDPOINT 
      + `poliza/findTalonesAbonadosCanceladosByIdOficinaAndFechaAndFecha/${idOficina}/`
      + `fechaInicio/${finicio}/fechaFin/${fFin}`)
  }
   /*
  * Título                    : findTalonesAbonadosByIdOficinaAndFechaAndFecha
  * Descripción               : Listado de Talones Abonados Sustitutos no contabilizados por Oficina y Rango de Fechas
  * Fecha de creación         : 2021-01-17
  * Autor                     : German Galaviz [germagafra]
  * Versión                   : 1.0.0
  * ID Requerimiento/Ticket   : NULL
  */

  findTalonesAbonadosSustitutosByIdOficinaAndFechaAndFecha  (idOficina: number, finicio:string,  fFin:string): Observable<any> {
    return this.http.get<TalonesAbonadosNoContabilizados[]>(this.appsettings.API_ENDPOINT 
      + `poliza/findTalonesAbonadosSustitutosByIdOficinaAndFechaAndFecha/${idOficina}/`
      + `fechaInicio/${finicio}/fechaFin/${fFin}`)
  }

  /*
  * Título                    : procesaPolizaTalonByIdOficinaManual
  * Descripción               : Contabilización de Talones por Oficina
  * Fecha de creación         : 2020-07-09
  * Autor                     : German Galaviz [germagafra]
  * Versión                   : 1.0.0
  * ID Requerimiento/Ticket   : NULL
  */

  procesaPolizaTalonByIdOficinaManual(oficina: number, agencia:string,usuario: number,finicio:string,  fFin:string): Observable<any>{  
    debugger;
    return this.http.get<PolizaPagados[]>(this.appsettings.API_ENDPOINT 
      + `poliza/procesaPolizaTalonByIdOficinaManual/${oficina}/${agencia}/usuario/${usuario}/fechaI/${finicio}/fechaF/${fFin}`)
  }
}