import { Routes } from '@angular/router';
import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { LayoutPrintComponent } from './layouts/layout-print/layout-print.component';
import { PrintPolizaPagadosComponent } from './systems/contabilidad/poliza-pagados/print-poliza-pagados/print-poliza-pagados.component';
// tslint:disable-next-line: max-line-length
import { PrintDetallePolizaPagadosComponent } from './systems/contabilidad/poliza-pagados/print-detalle-poliza-pagados/print-detalle-poliza-pagados.component';
import { AuthGuard } from './authentication/login/guards/auth.guard';
import { RoleGuard } from './authentication/login/guards/role.guard';

export const AppRoutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: '',
        redirectTo: '/authentication/login',
        pathMatch: 'full'
      },
      {
        path: `dashboard`, loadChildren: () =>
          import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: `tinter-plaza`, loadChildren: () =>
          import('./systems/tinter-plaza/tinter-plaza.module').then(m => m.TinterPlazaModule)
      },
      {
        path: `contabilidad`, loadChildren: () =>
          import('./systems/contabilidad/contabilidad.module').then(m => m.ContabildadModule)
      },
      {
        path: `app-support`, loadChildren: () =>
          import('./systems/app-support/app-support.module').then(m => m.AppSupportModule)
      },
      {
        path: `carta-porte`, loadChildren: () =>
          import('./systems/complemento-carta-porte/complemento-carta-porte.module').then(m => m.ComplementoCartaPorteModule)
      },
      {
        path: `nomina-estibadores`, loadChildren: () =>
          import('./systems/nomina-estibadores/nomina-estibadores.module').then(m => m.CentralizacionNominaModule)
      },
      {
        path: `paqueteria`, loadChildren: () => 
        import('./systems/paqueteria/paqueteria.module').then(m => m.PaqueteriaModule)
      },
      { 
        path: 'herramientas-ti', loadChildren: () => 
          import('./systems/herramientas/herramientas.module').then(m => m.HerramientasModule) 
      }
    ]
  },
  { // Path para impresión, se debe de incluir los children necesarios para su carga
    path: 'print',
    outlet: 'print',
    component: LayoutPrintComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'print-poliza-pagados', component: PrintPolizaPagadosComponent },
      { path: 'print-detalle-poliza-pagados', component: PrintDetallePolizaPagadosComponent }
    ]
  },
  {
    path: '',
    component: AppBlankComponent,
    children: [
      {
        path: 'authentication',
        loadChildren:
          './authentication/authentication.module#AuthenticationModule'
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'authentication/404'
  }
];
