import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
/*
  * Título                    : print.service
  * Descripción               : Servicio para la impresión
  * Fecha de creación         : 2020-06-29
  * Autor                     : German Galaviz [germagafra]
  * Versión                   : 1.0.0
  * ID Requerimiento/Ticket   : NULL
  */
@Injectable({
  providedIn: 'root'
})
export class PrintService {
  isPrinting = false;

  constructor(private router: Router) {
    console.log(this.isPrinting);
  }

  /*
  * Título                    : imprimirDocumento
  * Descripción               : Almacena el componente a imprimir y lo dirige al router outlet de impresión
  * Fecha de creación         : 2020-06-29
  * Autor                     : German Galaviz [germagafra]
  * Versión                   : 1.0.0
  * ID Requerimiento/Ticket   : NULL
  * @param string: nombreDocumento ->  ruta del documento a imprimir
  * @param string[]: datosDocumentos ->  Arreglo de datos correspondientes a la impresión
  * @param any[]: cabecerasImp ->  Arreglo de datos correspondientes a las cabeceras de imp.
  * @param any[]: piepaginaImp ->  Arreglo de datos correspondientes al pie de pagina de imp.
  */
  imprimirDocumento(nombreDocumento: string, datosDocumentos: any[],cabecerasImp: any[],
    piepaginaImp: any[]) {
    setTimeout(() => {
      sessionStorage.setItem("datosImpresion",JSON.stringify(datosDocumentos));
      sessionStorage.setItem("cabecerasImpresion",JSON.stringify(cabecerasImp));
      sessionStorage.setItem("piepaginaImpresion", JSON.stringify(piepaginaImp));
      this.router.navigate(["/",
      { outlets: {
        'print': ['print', nombreDocumento]
      }}]);
    });
  }

  /*
  * Título                    : datosListos
  * Descripción               : Lanza la ventana de impresión
  * Fecha de creación         : 2020-06-29
  * Autor                     : German Galaviz [germagafra]
  * Versión                   : 1.0.0
  * ID Requerimiento/Ticket   : NULL
  */
  datosListos() {
    setTimeout(() => {
      console.log("nuevamente print: " +  this.isPrinting);
      window.print();
      this.isPrinting = false;
      console.log(this.isPrinting);
      this.router.navigate([{ outlets: { print: null }}]);
    });
  }

  setIsPrinting(isPrinting: boolean) {
    this.isPrinting = isPrinting;
  }
}
