import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MAT_RADIO_DEFAULT_OPTIONS, MatDialogRef } from '@angular/material';
import { NominaEstibadoresService } from '../../../services/nomina-estibadores.service';
import { ConceptoEspecialOficina } from '../../../interfaces/nomina-estibadores/concepto-especial-oficina';
import { Personal } from '../../../interfaces/personal';
import UtilsValidateFormat from '../../helpers/utils-validate-format';

@Component({
  selector: 'app-concepto-especial-oficina-dialog',
  templateUrl: './concepto-especial-oficina-dialog.component.html',
  styleUrls: ['./concepto-especial-oficina-dialog.component.css'],
  providers: [{
    provide: MAT_RADIO_DEFAULT_OPTIONS,
    useValue: { color: 'primary' },
  }]
})
export class ConceptoEspecialOficinaDialogComponent implements OnInit {
  public isLoading: boolean = false;

  nuevoConcepto: boolean = true;
  conceptoEspecialOficina: ConceptoEspecialOficina;
  isMensajeria: boolean = false;
  isAgencia: boolean = false;

  conceptoEspecialOficinaFormGroup: FormGroup;
  lstEstibadoresOficina: Personal[] = [];
  lstEstibadoresOficinaInitialSelected: Personal[] = [];

  constructor(public dialogRef: MatDialogRef<ConceptoEspecialOficinaDialogComponent>,
    private formBuilder: FormBuilder,
    private nominaEstibadoresService: NominaEstibadoresService,
    @Inject(MAT_DIALOG_DATA) public data: { nuevoConcepto: boolean, conceptoEspecialOficina: ConceptoEspecialOficina }
  ) {
    this.nuevoConcepto = data.nuevoConcepto;
    this.conceptoEspecialOficina = data.conceptoEspecialOficina;
    this.checkIfIsMensajeriaOrAgencia();

    this.obtenerEstibadoresOficina();
  }

  ngOnInit() {
    this.conceptoEspecialOficinaFormGroup = this.formBuilder.group({
      tipoControl: ["", Validators.required],
      conceptoControl: ["", Validators.required],
      montoControl: [null, Validators.required],
      estibadoresControl: [[], Validators.required]
    });

    this.conceptoEspecialOficinaFormGroup.controls['tipoControl'].setValue(this.conceptoEspecialOficina.tipoConcepto.toString());
    this.conceptoEspecialOficinaFormGroup.controls['conceptoControl'].setValue(this.conceptoEspecialOficina.concepto);
    this.conceptoEspecialOficinaFormGroup.controls['montoControl'].setValue(this.conceptoEspecialOficina.monto);
  }

  cerrarDialogo(): void {
    const array = {
      agregar: false
    };
    this.dialogRef.close(array);
  }

  Agregar(): void {
    this.conceptoEspecialOficina.tipoConcepto = this.conceptoEspecialOficinaFormGroup.controls['tipoControl'].value;
    this.conceptoEspecialOficina.concepto = this.conceptoEspecialOficinaFormGroup.controls['conceptoControl'].value;
    this.conceptoEspecialOficina.monto = this.conceptoEspecialOficinaFormGroup.controls['montoControl'].value;
    this.conceptoEspecialOficina.lstConceptoEspecialEstibador = [];
    this.conceptoEspecialOficinaFormGroup.controls['estibadoresControl'].value.forEach(estibador => {
      this.conceptoEspecialOficina.lstConceptoEspecialEstibador.push({ fechaAsignacion: "", conceptoEspecialOficina: null, personalEstibador: estibador });
    });

    const array = {
      agregar: true,
      conceptoEspecialOficina: this.conceptoEspecialOficina
    };
    this.dialogRef.close(array);
  }

  obtenerEstibadoresOficina() {
    this.isLoading = true;
    this.nominaEstibadoresService.obtenerEstibadoresPorOficinaYClasificacionYTipoOrdenYCuadrilla(this.conceptoEspecialOficina.configuracionCorteOficina.oficina.oficina,
      this.conceptoEspecialOficina.configuracionCorteOficina.clasificacionCorteOficina.idClasificacionCorte,
      this.conceptoEspecialOficina.configuracionCorteOficina.tipoOrden.idTipoOrden,
      (this.conceptoEspecialOficina.configuracionCorteOficina.tipoOrden.idTipoOrden == 2) ?
        this.conceptoEspecialOficina.configuracionCorteOficina.cuadrilla.idCuadrilla.toString() : '').subscribe(response => {
          this.lstEstibadoresOficina = response;

          this.conceptoEspecialOficina.lstConceptoEspecialEstibador.forEach(conceptoEspecialEstibador => {
            this.lstEstibadoresOficinaInitialSelected.push(
              this.lstEstibadoresOficina[this.lstEstibadoresOficina.findIndex(index => index.idPersonal == conceptoEspecialEstibador.personalEstibador.idPersonal)]
            )
          });

          this.conceptoEspecialOficinaFormGroup.controls['estibadoresControl'].setValue(this.lstEstibadoresOficinaInitialSelected);
          this.isLoading = false;
        });
  }

  checkIfIsMensajeriaOrAgencia() {
    this.isMensajeria = this.conceptoEspecialOficina.configuracionCorteOficina.clasificacionCorteOficina.idClasificacionCorte == 2 ? true : false;
    this.isAgencia = this.conceptoEspecialOficina.configuracionCorteOficina.clasificacionCorteOficina.idClasificacionCorte == 5 ? true : false;
    if(this.nuevoConcepto)
      this.conceptoEspecialOficina.tipoConcepto = this.isMensajeria || this.isAgencia ? 2 : 1;
  }

  validateNumberFormatKeyPress(event: any) {
    UtilsValidateFormat.validateNumberFormatEvent(event);
  }

}
