import { CorrecionTimbradoComponent } from './../../modals/correcion-timbrado/correcion-timbrado.component';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { IGuide } from '../../../interfaces/talones-oficina/guide';
import { IParamFilter } from '../../../interfaces/param-filter';
import { Talones } from '../../../interfaces/talones-oficina/talones';
import { TalonesService } from '../../../services/talones.service';
import { PdfDialogComponent } from '../../modals/pdf-dialog/pdf-dialog.component';
import { Trip } from '../../../interfaces/trip';
import { ComplementoCartaPorteService } from '../../../services/complemento-carta-porte.service';

@Component({
  selector: 'app-talones-list',
  templateUrl: './talones-list.component.html',
  styleUrls: ['./talones-list.component.css']
})
export class TalonesListComponent implements OnInit {
  @Input() talones: Talones[];
  @Input() disablePreviousPage: boolean;
  @Input() disableNextPage: boolean;
  @Output() onNextPage: EventEmitter<any>;
  @Output() onPreviousPage: EventEmitter<any>;

  // PDF
  private showPdfLoading = false;
  private tripFile: any;
  private file;

  constructor(
    public ccpService: ComplementoCartaPorteService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
    this.onNextPage = new EventEmitter();
    this.onPreviousPage = new EventEmitter();
  }

  ngOnInit() {

  }

  // Abre el PDF para su visualización
  async openPDF(talon: any) {
    try {
      this.snackBar.open('Cargando PDF', '🔌', { duration: 3000 });
      await this.search(talon);

      const dialogRef = this.dialog.open(PdfDialogComponent, {
        width: '80vw',
        height: '98%',
        data: { file: this.file, searchQuery: talon.claTalon }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });
    } catch (error) {
      this.snackBar.open('Error, aún no existe el PDF', '⛔', {
        duration: 3000
      });
    }
  }

  // Busca el PDF
  async search(talon) {
    try {
      this.showPdfLoading = true;
      this.tripFile = await this.ccpService.getTripByTrackingNumber(talon.claTalon);

      if (!this.tripFile) { throw new Error('Error getting trip file.'); }
      if (!this.tripFile.tripFile) { throw new Error('Error getting trip file.'); }

      this.file = this.base64ToArrayBuffer(this.tripFile.tripFile);
    } catch (error) {
      console.error(error);
      this.showPdfLoading = false;

      throw error;
    }
  }

  // Convierte el archivo
  base64ToArrayBuffer(base64) {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }

    return bytes.buffer;
  }

  // Abre el modal de error de timbrado (correcion)
  async openFixTimbradoDiagolg(talon) {
    // const loaderDialogRef = this.dialog.open(CustomLoaderComponent);
    
    try {
      // loaderDialogRef.close();
      // const oficina = await this.authService.oficinaActual;
      
      const dialogRef = this.dialog.open(CorrecionTimbradoComponent, {
        width: '50%',
        height: '80%',
        data: {
          claTalon: talon.claTalon,
          idoficina: talon.idoficina,
          claveoficina: talon.idoficina,
          consCcp: talon.complementocpConsecutivo.length
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });
    } catch (error) {
      this.snackBar.open('Error, aún no existe el PDF', '⛔', {
        duration: 3000
      });
    }
    
  }

  async nextPage($event) {
    console.log(this.disableNextPage);
    this.onNextPage.emit($event);
  }

  async previousPage($event) {
    console.log(this.disablePreviousPage);
    this.onPreviousPage.emit($event);
  }
}
