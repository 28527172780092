import { Component } from '@angular/core';

@Component({
  selector: 'app-progress-spinner',
  template: `<div class="spinner-wrapper">
        <div class="spinner-animation">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
        </div>
    </div>`
})
export class ProgressSpinnerComponent { }
