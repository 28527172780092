import { Component, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import Swal from 'sweetalert2';
import { PDFComplemento } from '../../interfaces/PDFComplemento';
import { ComplementoCartaPorteService } from '../../services/complemento-carta-porte.service';

@Component({
  selector: 'app-custom-pdf-viewer',
  templateUrl: './custom-pdf-viewer.component.html',
  styleUrls: ['./custom-pdf-viewer.component.css']
})
export class CustomPdfViewerComponent implements OnInit {
  @ViewChild('pdfViewer', { static: false }) pdfViewer: PdfViewerComponent;
  @Output() afterLoadComplete: EventEmitter<any>;
  @Output() onTextLayerRendered: EventEmitter<any>;
  @Output() onGoBack: EventEmitter<any>;
  @Input() file;
  @Input() pathUrl;
  @Input() renderText: boolean = true;
  @Input() originalSize: boolean = false;
  @Input() searchQuery: string = '';
  customZoom: number = 1;

  constructor() {
    this.afterLoadComplete = new EventEmitter();
    this.onTextLayerRendered = new EventEmitter();
    this.onGoBack = new EventEmitter();
  }

  ngOnInit() {

  }

  // Cargar pdf
  pdfLoaded($event) {
    this.afterLoadComplete.emit($event);
  }

  onPageRendered($event) {
    if(this.searchQuery) {
      this.pdfViewer.pdfFindController.executeCommand('find', {
        caseSensitive: false,
        findPrevious: undefined,
        highlightAll: false,
        phraseSearch: true,
        query: this.searchQuery
      });
    }

    this.onTextLayerRendered.emit($event);
  }

  // Regresar (Cerrar componenete)
  goBack($event) {
    this.customZoom = 1;
    this.onGoBack.emit($event);
  }

  // Zoom mas y menos al archvivo pdf
  zoomIn() {
    this.customZoom = this.pdfViewer.zoom + 0.05;
  }

  zoomOut() {
    this.customZoom = this.pdfViewer.zoom - 0.05;
  }

  // Descargar pdf
  downloadFile() {
    const blob = new Blob([this.file], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

}
