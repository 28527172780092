import {Component, ElementRef,Inject,OnInit, ViewChild,inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog,MatDialogRef, MatSnackBar, MatTableDataSource} from "@angular/material";
import { ReporteSolicitudesMaterial } from "../../../interfaces/paqueteria/reporte-solicitudes-material";
import { EyeService } from "../../../services/eye.service";
import { TalonesMaterial } from "../../../interfaces/paqueteria/talones-material";
import { Materiales } from "../../../interfaces/paqueteria/materiales";
import { FormGroup } from "@angular/forms";
import { TalonesSolicitudesEyE } from "../../../interfaces/paqueteria/talones-solicitudes-eye";
import { SalidaAlmacenEyE } from "../../../interfaces/paqueteria/salida-almacen-eye";
import { Solicitudes_Eye } from "../../../interfaces/paqueteria/solicitudes-eye";
import Swal from "sweetalert2";
import { AuthService } from "../../../authentication/login/auth.service";
import { SeguimientoSolicitudesEyE } from "../../../interfaces/paqueteria/seguimiento-solicitudes-eye";
import { Usuario } from "../../../interfaces/usuario";
import { UsuarioService } from "../../../services/usuario.service";
import { VerDatosTalonComponent } from "../ver-datos-talon/ver-datos-talon.component";
import { OficinaCompleta } from "../../../interfaces/paqueteria/Oficina";
import { OficinaService } from "../../../services/oficina.service";
import { UnidadMedida } from "../../../interfaces/paqueteria/unidad-medida";
import { Talones } from "../../../interfaces/paqueteria/talones";
import { Tr } from "../../../interfaces/talones-oficina/tr";
import { TalonEnviadoEye } from "../../../interfaces/paqueteria/talon-enviado";
import {ListaMaterialSap,SolicitudSap} from "../../../interfaces/paqueteria/solicitud-sap";
import { TalonesMaterialIndComponent } from "../talones-material-ind/talones-material-ind.component";
import { SalidaMaterial } from "../../../interfaces/paqueteria/salida-material";
import { Personal } from "../../../interfaces/paqueteria/personal";
import { cadenaMaterialesReacondicionamientoOficina2, cadenaMaterialesReacondicionamientoOficina3, cadenaMaterialesReacondicionamientoTodas, cadenaMaterialesVenta, validarPermisos } from "../../helpers/helpers";
import { Permisos } from "../../../interfaces/paqueteria/permisos";

export interface Boton {
  texto: string;
  accion: string;
  clase: string;
  disabled: boolean;
}

const SISTEMA = 18;
const MODULO = 96;

@Component({
  selector: "app-ver-editar-solicitud",
  templateUrl: "./ver-editar-solicitud.component.html",
  styleUrls: ["./ver-editar-solicitud.component.css"],
})
export class VerEditarSolicitudComponent implements OnInit {
  @ViewChild("fileInput", { static: true }) fileInput: ElementRef;

  formulario: FormGroup;
  displayedColumns = ["position","talon","tipoMaterial","cantidad","unidadMedida"];
  displayedColumnsGeneral = ["position", "tipoMaterial","cantidad","unidadMedida"];
  dataSource = new MatTableDataSource<TalonesMaterial>([]);
  lstTalonesModificados: TalonesMaterial[] = new Array();
  lstMateriales: Materiales[] = [];
  updateTalones: boolean = false;
  updateSalidasMaterial: boolean = false;
  updateSolicitud: boolean = false;
  updateArchivo: boolean = false;
  archivoNuevo: File = null;
  archivoViejo: string;
  lstNumerosValidos = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  soloLectura: boolean = false;
  lstBotones: Boton[];
  usuario: Usuario;
  usuarioCorpo: Personal;
  motivoRechazo: string = "";
  oficina: OficinaCompleta;
  lstUnidadesMedida: UnidadMedida[] = [];
  lstCambios: TalonesMaterial[] = [];
  loadding: boolean = false;
  dataSourceGeneral = new MatTableDataSource<TalonesMaterial>([]);
  esCorporativo: boolean = false;
  tipoSolicitud: number = 0;
  pendiente: boolean = false;
  lstTalonesMaterial: TalonesMaterial[] = new Array();
  accionBtn : string = "";
  enProgreso: boolean = false;
  modificar : boolean = true;
  lstPermisos: Permisos;


  constructor(
    private dialogRef: MatDialogRef<VerEditarSolicitudComponent>,
    @Inject(MAT_DIALOG_DATA) public datosReporte: ReporteSolicitudesMaterial,
    private eyeService: EyeService,
    private snack: MatSnackBar,
    private authService: AuthService,
    private usuarioService: UsuarioService,
    private dialog: MatDialog,
    private oficinaService: OficinaService
  ) {
 
    this.tipoSolicitud = this.datosReporte.tipoSolicitud == "venta" ? 1 : 2;
  }

  async ngOnInit() {
    this.getOficina();
    this.buscarUsuario();
    this.buscarUsuarioCorpo();
    this.lstPermisos = await this.authService.obtenerPermisosModulo(SISTEMA, MODULO);
    this.cargarInterfaz();
  }

  cargarInterfaz() : void {
    if (this.datosReporte.tipoSolicitud == "venta") {
      this.displayedColumns.splice(1, 1);
    } else {
      this.modificar = false;
    }
    if (this.authService.usuario.idOficina == "1") {
      this.soloLectura = true;
      this.esCorporativo = true;
      if (this.datosReporte.claveEstatus == "pendiente") {
        this.pendiente = true;
        this.lstBotones = [];
        if (this.lstPermisos.e == 1) {
          this.lstBotones.push({
            texto: "Autorizar",
            accion: "autorizar",
            clase: "blue",
            disabled: false,
          });
        }
        if (this.lstPermisos.f == 1) {
          this.lstBotones.push({
            texto: "Rechazar",
            accion: "rechazar",
            clase: "red",
            disabled: false,
          });
        }
        this.lstBotones.push({
          texto: "Cancelar",
          accion: "cancelar",
          clase: "basic",
          disabled: false,
        });
      } else if (
        this.datosReporte.claveEstatus == "autorizada" ||
        this.datosReporte.claveEstatus == "rechazada"
      ) {
        this.lstBotones = [
          {
            texto: "Cerrar",
            accion: "cerrar",
            clase: "basic",
            disabled: false,
          },
        ];
      }
    } else {
      this.esCorporativo = false;
      if (this.datosReporte.claveEstatus == "pendiente") {
        this.pendiente = true;
        this.soloLectura = false;
        this.lstBotones = [];
        if (this.lstPermisos.d == 1) {
          this.lstBotones.push({
            texto: "Guardar",
            accion: "guardar",
            clase: "blue",
            disabled: true,
          });
        }
        this.lstBotones.push({
          texto: "Cancelar",
          accion: "cancelar",
          clase: "basic",
          disabled: false,
        });
      } else {
        this.soloLectura = true;
        this.lstBotones = [
          {
            texto: "Cancelar",
            accion: "cancelar",
            clase: "basic",
            disabled: false,
          },
        ];
      }
    }
  }

  getOficina() : void {
    this.oficinaService
      .findOficinaByClave(this.authService.usuario.claveOficina)
      .subscribe((oficina) => {
        this.oficina = oficina;
        this.getMateriales();
      });
  }

  getMateriales() : void {
    let cadenaMateriales: string[] = [];

    if (this.datosReporte.tipoSolicitud == "venta") {
      cadenaMateriales= cadenaMaterialesVenta;
    } else {
      if (this.oficina.idTipoOficina == 2) {
        cadenaMateriales= cadenaMaterialesReacondicionamientoOficina2;
      } else if (this.oficina.idTipoOficina == 3) {
        cadenaMateriales= cadenaMaterialesReacondicionamientoOficina3;
      } else {
        cadenaMateriales= cadenaMaterialesReacondicionamientoTodas;
      }
    }

    this.eyeService
      .getMaterialesByNombre(cadenaMateriales)
      .subscribe((lstMat: Materiales[]) => {
        this.lstMateriales = lstMat;
        this.getUnidadesMedida();
        this.obtenerTalonesMaterial();
      });
  }

  getUnidadesMedida() : void {
    this.eyeService
      .getUnidadesMedida()
      .subscribe((lstUnidades: UnidadMedida[]) => {
        this.lstUnidadesMedida = lstUnidades;
      });
  }

  obtenerTalonesMaterial() : void {
    this.eyeService
      .getTalonesMaterial(
        this.datosReporte.idSolicitud,
        this.datosReporte.tipoSolicitud
      )
      .subscribe((lstTalonesMat: TalonesMaterial[]) => {
        this.lstTalonesMaterial = [...lstTalonesMat];

        if (this.authService.usuario.idOficina == "1") {
          this.llenarTablaGeneral();
        } else {
          this.llenarTablaInd(lstTalonesMat);
        }
      });
  }

  llenarTablaGeneral() : void {
    var lstTm: TalonesMaterial[] = new Array();
    lstTm = JSON.parse(JSON.stringify(this.lstTalonesMaterial));
    
    lstTm.forEach((element : TalonesMaterial) => {
      const material: Materiales = this.lstMateriales.find((materiales : Materiales) => {
        return materiales.idMaterial == element.idMaterial;
      });

      element.nombreMaterial = material.nombre;

      if (this.dataSourceGeneral.data.length == 0) {
        this.dataSourceGeneral.data.push(element);
      } else {
        const talonMaterial = this.dataSourceGeneral.data.find((response : TalonesMaterial) => {
          return response.idMaterial == element.idMaterial;
        });

        if (talonMaterial == undefined) {
          this.dataSourceGeneral.data.push(element);
        } else {
          let unidadesMedida: string[] = [];
          this.dataSourceGeneral.data.forEach((elementGeneral: TalonesMaterial) => {
            
            if (elementGeneral.idMaterial == talonMaterial.idMaterial) {

              unidadesMedida.push(element.unidadMedida);
              elementGeneral.unidadMedida =  unidadesMedida.sort((a, b) => { return unidadesMedida.filter(v => v===a).length - unidadesMedida.filter(v => v===b).length}).pop();

              elementGeneral.cantidadSolicitada += element.cantidadSolicitada;
            } else {
              unidadesMedida = [];
            }

          });
        }
      }
    });

    this.dataSourceGeneral.data.forEach((element: TalonesMaterial) => {
      element.cantidadSolicitada =
        this.datosReporte.tipoSolicitud == "venta"  || (this.datosReporte.claveEstatus == "salidaalmacen" && element.unidadMedida != "mts")
          ? element.cantidadSolicitada
          : this.eyeService.calcularCantidad(
              element.idMaterial,
              element.cantidadSolicitada
            );
      element.unidadMedida =
        this.datosReporte.tipoSolicitud == "venta" || (this.datosReporte.claveEstatus == "salidaalmacen" && element.unidadMedida != "mts")
          ? element.unidadMedida
          : this.eyeService.convertirUnidadMedida(
              element.idMaterial,
              element.unidadMedida
            );
    });

    this.dataSourceGeneral._updateChangeSubscription();
  }

  llenarTablaInd(lstTalonesMaterial: TalonesMaterial[]) : void {
    lstTalonesMaterial.map((element : TalonesMaterial) => {
      if (element.idTalon == null || element.idTalon == 0) {
        element.idTalon = 0;
        element.numeroTalon = "0";
      } else {
        element.numeroTalon = element.numeroTalon.trim();
      }

      const material: Materiales = this.lstMateriales.find((materiales : Materiales) => {
        return materiales.idMaterial === element.idMaterial;
      });

      element.nombreMaterial = material.nombre;
      element.cantidadSolicitada =
        this.authService.usuario.idOficina == "1"
          ? this.eyeService.convertirCantidad(
              element.nombreMaterial,
              element.cantidadSolicitada,
              element.unidadMedida
            )
          : element.cantidadSolicitada;

      element.unidadMedida = element.unidadMedida;
  
          
      this.dataSource.data.push(element);
    });
    this.dataSource._updateChangeSubscription();
  }

  registrarCambiosIndividuales(elemento: any, i: number) : void {
    if (this.soloLectura) {
      if (elemento.target.name == "anexo" && this.datosReporte.anexo != "") {
        this.descargarAnexo();
      }
      return;
    }

    const talonMat: TalonesMaterial = this.dataSource.data[i];

    if (this.lstTalonesModificados.length > 0) {
      const copiaTalon = this.lstTalonesModificados.find((value : TalonesMaterial) => {
        return talonMat.idSalida == value.idSalida;
      });

      if (copiaTalon != undefined) {
        this.lstTalonesModificados.forEach((element : TalonesMaterial) => {
          if (element.idSalida == copiaTalon.idSalida) {
            if (elemento.target.name == "talon") {
              this.updateTalones = true;
              element.numeroTalon = elemento.target.value.toString();
            } else if (elemento.target.name == "cantidad") {
              this.updateSalidasMaterial = true;
              element.cantidadSolicitada = elemento.target.value;
            }
          }
        });
      } else {
        if (elemento.target.name == "talon") {
          this.updateTalones = true;
          talonMat.numeroTalon = elemento.target.value.toString();
        } else if (elemento.target.name == "cantidad") {
          this.updateSalidasMaterial = true;
          talonMat.cantidadSolicitada = elemento.target.value;
        }

        this.lstTalonesModificados.push(talonMat);
      }
    } else {
      if (elemento.target.name == "talon") {
        this.updateTalones = true;
        talonMat.numeroTalon = elemento.target.value.toString();
      } else if (elemento.target.name == "cantidad") {
        this.updateSalidasMaterial = true;
        talonMat.cantidadSolicitada = elemento.target.value;
      }

      this.lstTalonesModificados.push(talonMat);
    }

    if (elemento.target.name == "tipoMaterial") {
      this.updateSalidasMaterial = true;
    } else if (elemento.target.name == "observaciones") {
      this.updateSolicitud = true;
      this.datosReporte.observaciones = elemento.target.value;
    } else if (elemento.target.name == "anexo") {
      this.fileInput.nativeElement.click();
    }

    if (elemento.target.name != "anexo") {
      this.lstBotones.forEach((boton: Boton) => {
        if (boton.accion == "guardar") {
          boton.disabled = false;
        }
      });
    }
  }

  registrarCambiosGenerales(evento: Event, element: TalonesMaterial) : void {
    const valor = (evento.target as HTMLInputElement).value;
    const talonMat = { ...element };

    if (this.lstTalonesModificados.length == 0) {
      talonMat.cantidadSolicitada = parseFloat(valor.toString());
      this.lstTalonesModificados.push(talonMat);
    } else {
      const copiaMaterial = this.lstTalonesModificados.find((response : TalonesMaterial) => {
        return response.idSalida == talonMat.idSalida;
      });

      if (copiaMaterial != undefined) {
        copiaMaterial.cantidadSolicitada = parseFloat(valor.toString());
      } else {
        talonMat.cantidadSolicitada = parseFloat(valor.toString());
        this.lstTalonesModificados.push(talonMat);
      }
    }
  }

  materialSeleccionado(event: any, index: number) : void {
    const material: Materiales = this.lstMateriales.find((element : Materiales) => {
      return element.nombre == event.value;
    });

    const unidadMedida: UnidadMedida = this.lstUnidadesMedida.find((response : UnidadMedida) => {
      return response.idUnidadMedida == material.idUnidadMedida;
    });

    this.dataSource.data[index].unidadMedida =
      this.datosReporte.tipoSolicitud == "venta"
        ? this.eyeService.convertirUnidadMedida(
            material.idMaterial,
            unidadMedida.nombre
          )
        : unidadMedida.nombre;
    this.dataSource.data[index].nombreMaterial = material.nombre;
    this.dataSource.data[index].idMaterial = material.idMaterial;
    this.dataSource.data[index].claveMaterial = material.claveMaterial;
    this.dataSource._updateChangeSubscription();
    this.updateSalidasMaterial = true;

    const talonMat: TalonesMaterial = this.dataSource.data[index];

    if (this.lstTalonesModificados.length > 0) {
      const copiaTalon = this.lstTalonesModificados.find((value : TalonesMaterial) => {
        return talonMat.idSalida == value.idSalida;
      });

      if (copiaTalon != undefined) {
        this.lstTalonesModificados.forEach((element : TalonesMaterial) => {
          if (element.idSalida == copiaTalon.idSalida) {
            element = copiaTalon;
          }
        });
      } else {
        this.lstTalonesModificados.push(talonMat);
      }
    } else {
      this.lstTalonesModificados.push(talonMat);
    }

    this.lstBotones.forEach((boton: Boton) => {
      if (boton.accion == "guardar") {
        boton.disabled = false;
      }
    });
  }

  accionBoton(accion: string) : void {
    this.accionBtn = accion;
    if (accion == "guardar") {
      this.guardarDatos();
    } else if (accion == "cancelar" || accion == "cerrar") {
      this.cancelar();
    } else if (accion == "autorizar") {
      this.autorizarSolicitud();
    } else if (accion == "rechazar") {
      this.rechazarSolicitud();
    } else {
      this.actualizarEstatus(accion);
    }
  }

  guardarDatos() : void {
    Swal.fire({
      position: "center",
      icon: "warning",
      title: "¿Seguro que desea editar los datos de esta solicitud?",
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (!result.isConfirmed) return;
      this.datosReporte.tipoSolicitud.toLowerCase() == "venta" ? this.guardarDatosVenta() : this.guardarDatosReacondicionamiento();
    });
  }

  guardarDatosVenta() : void {
    if (this.updateSalidasMaterial) {
      if (!this.validarMateriales()) return;
      if (!this.validarCantidades()) return;
      if (this.updateSalidasMaterial) this.actualizarSalidaAlmacen();
    }

    if (this.updateSolicitud) {
      this.datosReporte.observaciones = this.datosReporte.observaciones.trim();
      if (this.datosReporte.observaciones.length == 0) {
        this.snack.open("Debe agregar observaciones", "⚠️");
        return;
      }

      this.actualizarSolicitud();
    }
  }

  guardarDatosReacondicionamiento() : void {
    if (this.updateTalones) {
      this.validarTalones();
    }

    if (!this.updateTalones && this.updateSalidasMaterial) {
      if (!this.validarMateriales()) return;
      if (!this.validarCantidades()) return;
      if (this.updateTalones) this.actualizarTalones();
      if (this.updateSalidasMaterial) this.actualizarSalidaAlmacen();
    }

    if (this.updateSolicitud) {
      this.datosReporte.observaciones = this.datosReporte.observaciones.trim();
      if (this.datosReporte.observaciones.length == 0) {
        this.snack.open("Debe agregar observaciones", "⚠️", {
          duration: 3000,
        });
        return;
      }

      this.actualizarSolicitud();
    }
  }

  validarTalones() : void {
    for (let i = 0; i < this.lstTalonesModificados.length; i++) {
      const numeroTalon = this.lstTalonesModificados[i].numeroTalon;

      if (numeroTalon.length < 10) {
        this.snack.open(
          `El talón ${numeroTalon} no es válido, verifíquelo por favor.`,
          "⚠️",
          {
            duration: 3000,
          }
        );
        return;
      }

      const esTalonValido = /^\d+$/.test(numeroTalon);

      if (!esTalonValido) {
        this.snack.open(
          `El talón ${numeroTalon} no es válido, verifíquelo por favor.`,
          "⚠️",
          {
            duration: 3000,
          }
        );
        return;
      }

      this.eyeService
        .getTalonByClaTalon(numeroTalon)
        .subscribe((talon: Talones) => {
          if (talon.claTalon == null) {
            this.snack.open(
              `El talón ${numeroTalon} no es válido, verifíquelo por favor.`,
              "⚠️",
              {
                duration: 3000,
              }
            );
            return;
          } else {
            this.eyeService
              .getTrByClaTalon(talon.tabla.toString(), numeroTalon)
              .subscribe((tr: Tr) => {
                const fechaTr = new Date(
                  `${tr.fecha.toString()}T${tr.hora.toString()}`
                );
                const resta = Math.round(
                  (new Date().getTime() - fechaTr.getTime()) /
                    (1000 * 60 * 60 * 24)
                );

                if (resta > 90) {
                  this.snack.open(
                    `El talón ${numeroTalon} tiene una vigencia mayor a 90 días, verifíquelo por favor.`,
                    "⚠️",
                    {
                      duration: 3000,
                    }
                  );
                  return;
                } else {
                  this.eyeService
                    .getTalonEnviadoByNumeroTalon(numeroTalon)
                    .subscribe((talonEnv: TalonEnviadoEye) => {
                      if (
                        talonEnv.idTalonEnviado == 0 ||
                        talonEnv.idSolicitud == this.datosReporte.idSolicitud
                      ) {
                        if (i == this.lstTalonesModificados.length - 1) {
                          if (!this.validarMateriales()) return;
                          if (!this.validarCantidades()) return;
                          if (this.updateTalones) this.actualizarTalones();
                          if (this.updateSalidasMaterial)
                            this.actualizarSalidaAlmacen();
                        }
                      } else {
                        this.eyeService
                          .getSolicitudById(talonEnv.idSolicitud)
                          .subscribe((solicitud: Solicitudes_Eye) => {
                            this.snack.open(
                              `El talón ${numeroTalon} ya fue utilizado en la solicitud con el folio ${solicitud.folio}`,
                              "⚠️",
                              {
                                duration: 3500,
                              }
                            );
                          });
                      }
                    });
                }
              });
          }
        });
    }
  }

validarMateriales(): boolean {
  const duplicadoEncontrado = this.lstTalonesModificados.some(talon => {
    return this.dataSource.data.some(dataItem => {
      return talon.numeroTalon === dataItem.numeroTalon &&
             talon.idMaterial === dataItem.idMaterial &&
             talon.idSalida !== dataItem.idSalida;
    });
  });

  if (duplicadoEncontrado) {
    this.snack.open("No puede repetir material en un mismo talón y/o solicitud, verifíquelo por favor.", "⚠️", {
      duration: 3500
    });
  }

  return !duplicadoEncontrado;
}


  validarCantidades(): boolean {
    const talonMaterial = this.dataSource.data.find((value) => {
      return 0 == value.cantidadSolicitada;
    });

    if (talonMaterial != undefined) {
      this.snack.open(
        `Debe ingresar una cantidad válida para el material ${talonMaterial.nombreMaterial}`,
        "⚠️",
        {
          duration: 3000,
        }
      );
      return false;
    } else {
      return true;
    }
  }

  actualizarTalones() : void {
    var contador = 1;
    this.lstTalonesModificados.forEach((element : TalonesMaterial) => {
      const talon: TalonesSolicitudesEyE = {
        idTalon: element.idTalon,
        numeroTalon: element.numeroTalon,
        idSolicitud: this.datosReporte.idSolicitud,
        idSalida: 0,
      };

      this.eyeService.actualizarTalon(talon).subscribe((resp) => {
        if (!this.updateSalidasMaterial && !this.updateSolicitud) {
          if (contador == this.lstTalonesModificados.length) {
            this.dialogRef.close("success");
          }
        }
        contador++;
      });
    });
  }

  actualizarSalidaAlmacen() : void {
    var contador = 1;
    this.lstTalonesModificados.forEach((element : TalonesMaterial) => {
      const salidaAlmacen: SalidaAlmacenEyE = {
        idSalida: element.idSalida,
        idSolicitud: this.datosReporte.idSolicitud,
        idMaterial: element.idMaterial,
        cantidadSolicitada: element.cantidadSolicitada,
        unidadMedida: element.unidadMedida,
        claveMaterial: element.claveMaterial,
        estatusSalida: 0,
        cantidadSurtida: 0,
        idEmpaqueUtilizado: 0,
      };

      this.eyeService
        .actualizarSalidaAlmacen(salidaAlmacen)
        .subscribe((resp: SalidaAlmacenEyE) => {
          if (!this.updateSolicitud && contador == this.lstTalonesModificados.length) {
            if (
              this.accionBtn == "autorizar" &&
              this.datosReporte.tipoSolicitud != "venta"
            ) {
              this.actualizarEstatus(this.accionBtn);
            } else {
              this.dialogRef.close("success");
            }
          }

          contador++;
        });
    });
  }

  actualizarSolicitud() : void {
    const solicitud: Solicitudes_Eye = {
      idSolicitud: this.datosReporte.idSolicitud,
      folio: this.datosReporte.folio,
      idPersonal: 0,
      idOficina: 0,
      idEstatus: 1,
      anexo: this.datosReporte.anexo,
      documento: this.archivoNuevo,
      observaciones: this.datosReporte.observaciones,
      motivoRechazo: "",
      tipoSolicitud: this.datosReporte.tipoSolicitud,
      fecha: this.datosReporte.fecha,
      hora: "",
    };

      this.eyeService.actualizarSolicitud(solicitud).subscribe(
        (resp) => {
          this.dialogRef.close("success");
        },
        (error) => {
          this.dialogRef.close("error");
        }
      );
  }

  onFileSelected(file: File[]) : void {
    this.updateArchivo = true;
    this.updateSolicitud = true;
    this.archivoViejo = this.datosReporte.anexo;
    this.datosReporte.anexo = file[0].name;
    this.archivoNuevo = file[0];
    
    this.lstBotones.map((boton: Boton) => {
      if (boton.accion == "guardar") {
        boton.disabled = false;
      }
    });
  }

  cancelar() : void {
    this.dialogRef.close("cancel");
  }

  autorizarSolicitud() : void {
    Swal.fire({
      title: `¿Seguro que desea autorizar la solicitud ${this.datosReporte.folio}?`,
      icon: "warning",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: "#007a29",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then(async (resp) => {
      if (!resp.isConfirmed) return;

      if (this.datosReporte.tipoSolicitud.toLowerCase() == "venta") {
        this.guardarSolicitudSap(true, false);
      } else {
      await  this.guardarSolicitudReac();
       this.guardarSolicitudSap(false, true);
      }
    });
  }

  rechazarSolicitud() : void {
    Swal.fire({
      title: `¿Seguro que desea rechazar la solicitud ${this.datosReporte.folio}?`,
      icon: "warning",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: "#007a29",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then((response) => {
      if (!response.isConfirmed) return;

      const motivo = Swal.fire({
        title: "Motivo de rechazo",
        input: "textarea",
        showConfirmButton: true,
        confirmButtonText: "Aceptar",
      }).then((response) => {
        var motivo: string = response.value.toString();

        if (motivo.length == 0) {
          this.snack.open("Debe ingresar un motivo de rechazo válido", "⚠️", {
            duration: 3000,
          });
          return;
        }
        this.motivoRechazo = response.value.toString();
        this.actualizarEstatus("rechazar");
      });
    });
  }

  actualizarEstatus(accion: string) : void {
    var idEstatus = 0;
    var texto = "";

    if (accion == "autorizar") {
      idEstatus = 2;
    } else if (accion == "rechazar") {
      idEstatus = 3;
    } else if (accion == "salidaalmacen") {
      idEstatus = 4;
    } else {
      return;
    }

    const solicitud: Solicitudes_Eye = {
      idSolicitud: this.datosReporte.idSolicitud,
      folio: this.datosReporte.folio,
      idPersonal: 0,
      idOficina: 0,
      idEstatus: idEstatus,
      anexo: "",
      observaciones: this.datosReporte.observaciones,
      motivoRechazo: this.motivoRechazo,
      tipoSolicitud: this.datosReporte.tipoSolicitud,
      fecha: "",
      hora: "",
    };

    this.eyeService
      .actualizarSolicitud(solicitud)
      .subscribe((solicitudActualizada: Solicitudes_Eye) => {
        const seguimiento: SeguimientoSolicitudesEyE = {
          idSeguimiento: 0,
          idSolicitud: solicitudActualizada.idSolicitud,
          idPersonal: this.usuario.personal.idPersonal,
          idOficina: parseInt(this.authService.usuario.idOficina),
          idEstatus: idEstatus,
          fecha: new Date().toLocaleDateString(),
          hora: new Date().toLocaleTimeString(),
        };

        this.eyeService
          .guardarSeguimiento(seguimiento)
          .subscribe((seguimientoCreado: SeguimientoSolicitudesEyE) => {
            if (accion == "autorizar") {
              accion = "Autorizada";
              texto = `La solicitud ${solicitudActualizada.folio} fue autorizada con éxito!`;
            } else if (accion == "rechazar") {
              accion = "Rechazada";
              texto = `La solicitud ${solicitudActualizada.folio} fue rechazada`;
            }

            Swal.fire({
              title: accion,
              text: texto,
              icon: "success",
              showConfirmButton: true,
            }).then((result) => {
              this.dialogRef.close("updated");
            });
          });
      });
  }

  buscarUsuario() : void {
    this.usuarioService
      .getUsuarioByIdUsuario(this.authService.usuario.id)
      .subscribe((usuario: Usuario) => (this.usuario = usuario));
  }

  buscarUsuarioCorpo() : void {
    this.eyeService
      .getPersonalCorpoByIdUsuario(this.authService.usuario.username)
      .subscribe((personal: Personal) => (this.usuarioCorpo = personal));
  }

  verDatosTalon(numeroTalon: string) {
    if (!this.soloLectura) return;

    const dialogoTalonesRef = this.dialog.open(VerDatosTalonComponent, {
      width: "900px",
      data: numeroTalon,
      panelClass: "colorFondo",
    });
  }

  async guardarSolicitudSap(actualizar: boolean,convertir: boolean) : Promise<void> {
    this.loadding = true;
    const lstMaterialesSap: ListaMaterialSap[] = [];

    for(let i = 0; i < this.dataSourceGeneral.data.length; i++){
      const element = this.dataSourceGeneral.data[i];
      const talonMat = this.lstTalonesModificados.find((resp) => {
        return resp.idMaterial == element.idMaterial;
      });

      if (talonMat != undefined) {
        element.cantidadSolicitada = convertir ? await this.eyeService.convertirCantidadPromesa(element.nombreMaterial, talonMat.cantidadSolicitada, this.eyeService.convertirUnidadMedida(element.idMaterial, element.unidadMedida)) : talonMat.cantidadSolicitada;
      }
    }

    this.dataSourceGeneral.data.map((element : TalonesMaterial) => {
      const material: ListaMaterialSap = {
        claveMaterial: element.claveMaterial,
        cantidad: this.lstMateriales.find((response : Materiales) => { return response.claveMaterial == element.claveMaterial }).medidaCompras * element.cantidadSolicitada,
      };
      lstMaterialesSap.push(material);
    });

    this.oficinaService
      .findOficinaById(this.datosReporte.idOficina)
      .subscribe((oficina: OficinaCompleta) => {
        const solicitudSap: SolicitudSap = {
          idSalida: this.datosReporte.folio,
          observacion: this.datosReporte.observaciones.substring(0, 99),
          idOficinaSolicitud: oficina.clave,
          listMaterial: lstMaterialesSap,
        };

        this.eyeService.guardarSolicitudSap(solicitudSap).subscribe(
          (resp: string) => {
            this.dataSourceGeneral.data.map((element : TalonesMaterial) => {
               let cantidad = this.lstMateriales.find((response : Materiales) => { return response.claveMaterial == element.claveMaterial }).medidaCompras * element.cantidadSolicitada;
               
                let salidaAlmacen: SalidaAlmacenEyE = {
                  idSalida: element.idSalida,
                  idSolicitud: this.datosReporte.idSolicitud,
                  idMaterial: element.idMaterial,
                  cantidadSolicitada: cantidad,
                  unidadMedida: element.unidadMedida,
                  claveMaterial: element.claveMaterial,
                  estatusSalida: 0,
                  cantidadSurtida: 0,
                  idEmpaqueUtilizado: 0,
                };

              this.eyeService.actualizarSalidaAlmacen(salidaAlmacen).subscribe();
              
            });
            
            if(actualizar){
              this.actualizarEstatus("autorizar");
            }

            if(convertir){
              this.lstTalonesMaterial.forEach((element: TalonesMaterial) => {
                this.descontarMaterial(element);
              });
              this.actualizarEstatus(this.accionBtn);
            }
          
          },
          (error) => {
            this.loadding = false;
            Swal.fire({
              title: error.exception,
              text: error.message,
              icon: "error",
              showConfirmButton: true,
            });
          }
        );
      });
  }

  async guardarSolicitudReac() : Promise<void> {
    if (this.lstTalonesModificados.length > 0) {
      if (!this.validarCantidadesGenerales()) return;

      for (let i = 0; i < this.lstTalonesModificados.length; i++) {
        var iElement = this.lstTalonesModificados[i];
        var cantidadNueva: number = iElement.cantidadSolicitada;
        const talonMat = this.dataSourceGeneral.data.find((response : TalonesMaterial ) => {
          return response.idMaterial == iElement.idMaterial;
        });

        if (cantidadNueva == talonMat.cantidadSolicitada) return;

 
        cantidadNueva = await this.eyeService.convertirCantidadPromesa(
          iElement.nombreMaterial,
          cantidadNueva,
          iElement.unidadMedida
        );
    
        iElement.cantidadSolicitada = cantidadNueva;
        iElement.unidadMedida = iElement.unidadMedida != "mts" ? this.eyeService.convertirUnidadMedida( iElement.idMaterial, iElement.unidadMedida): iElement.unidadMedida;

        for (let i = 0; i < this.lstTalonesMaterial.length; i++) {
          if (this.lstTalonesMaterial[i].idSalida == iElement.idSalida) {
            this.lstTalonesMaterial[i] = { ...iElement };
            break;
          }
        }
      }
    }


  }

  validarCantidadesGenerales(): boolean {

    if (this.lstTalonesModificados.length == 0) return false;
    this.lstTalonesModificados.filter((element : TalonesMaterial) => {if (element.cantidadSolicitada <= 0) return false;});
    return true;
    
  }

  verTablaInd() {
    this.eyeService
      .getTalonesMaterial(
        this.datosReporte.idSolicitud,
        this.datosReporte.tipoSolicitud
      )
      .subscribe((lstTalonesMaterial: TalonesMaterial[]) => {
        const dialogRef = this.dialog.open(TalonesMaterialIndComponent, {
          width: "650px",
          data: { lstTalonesMaterial },
        });
      });
  }

  descontarMaterial(material: TalonesMaterial) : void {
    this.oficinaService.findOficinaById(this.datosReporte.idOficina).subscribe(
      (oficina: OficinaCompleta) => {
          this.guardarSalidaMaterial(
            material,
            material.cantidadSolicitada,
            oficina.clave
          );
      },
      (error) => {
        Swal.fire({
          title: "Error",
          text: `Ocurrió un error al obtener la oficina de la solicitud. ${error}`,
          icon: "error",
          showConfirmButton: true,
        });
      }
    );
  }

  guardarSalidaMaterial(talonMat: TalonesMaterial,cantidad: number,claveOficina: string) : void {
    const material = this.lstMateriales.find((response : Materiales) => {
      return response.idMaterial == talonMat.idMaterial;
    });

    const salidaMaterial: SalidaMaterial = {
      idSalidaMaterial: 0,
      idMaterial: talonMat.idMaterial,
      cantidad: cantidad,
      idEmbalaje: -2,
      idOficinaEmbalaje: claveOficina,
      idEmbalajeCo: -2,
      idDetalle: -2,
      idEmbalajeAdicional: 0,
      idPersonal: this.usuarioCorpo.idPersonal,
      fechaMod: new Date().toLocaleDateString(),
      horaMod: new Date().toLocaleTimeString(),
      idPoliza: -2,
      precioVenta: material != undefined ? material.precioVenta : 0,
      idTipoMovimiento: 12,
      precioCompra: material != undefined ? material.precioCompra : 0,
    };

    this.eyeService
      .guardarSalidaMaterial(claveOficina, salidaMaterial)
      .subscribe((resp: boolean) => {
        if (resp) {
          this.actualizarSA(talonMat);
        } else {
          Swal.fire({
            title: "Algo salió mal",
            text: "No fue posible guardar la salida del material",
            icon: "warning",
            showConfirmButton: true,
          });
        }
      });
  }

  actualizarSA(element: TalonesMaterial) : void {
    const salidaAlmacen: SalidaAlmacenEyE = {
      idSalida: element.idSalida,
      idSolicitud: this.datosReporte.idSolicitud,
      idMaterial: element.idMaterial,
      cantidadSolicitada: element.cantidadSolicitada,
      unidadMedida: element.unidadMedida,
      claveMaterial: element.claveMaterial,
      estatusSalida: 0,
      cantidadSurtida: 0,
      idEmpaqueUtilizado: 0,
    };

    this.eyeService.actualizarSalidaAlmacen(salidaAlmacen).subscribe();
  }

  descargarAnexo() : void {
    if (!this.esCorporativo ||this.datosReporte.anexo == null ||this.enProgreso) {
      return;
    }

    this.enProgreso = true;
    window.open(this.datosReporte.anexo,"_blank");
    this.enProgreso = false;
  }
}
