import { PdfDialogComponent } from './../../modals/pdf-dialog/pdf-dialog.component';
import { MatSnackBar, MatDialog } from '@angular/material';
import { TalonesService } from './../../../services/talones.service';
import { ComplementoCartaPorteService } from './../../../services/complemento-carta-porte.service';
import { IParamFilter } from './../../../interfaces/param-filter';
import { Trip } from './../../../interfaces/trip';
import { Talones } from './../../../interfaces/talones-oficina/talones';
import { IGuide } from './../../../interfaces/talones-oficina/guide';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-guide-talones-list',
  templateUrl: './guide-talones-list.component.html',
  styleUrls: ['./guide-talones-list.component.css']
})
export class GuideTalonesListComponent implements OnInit {
  @Input() guide: IGuide;

  talones: Talones[] = [];
  showPdfLoading = false;
  showLoading = false;
  tripFile: Trip;
  file;

  // Paginator
  isDisabledPreviousPage = true;
  isDisabledNextPage = false;
  itemsPerPage = 5;
  offset = 0;

  filters: IParamFilter = {
    fields: ['t.*'],
    where: {
      and: [
        {
          column: 'tabla',
          operator: '=',
          value: ''
        },
        {
          column: 'tg.no_guia',
          operator: '=',
          value: ''
        }
      ]
    },
    limit: {
      offset: this.offset,
      itemsPerPage: this.itemsPerPage
    }
  };

  constructor(
    public ccpService: ComplementoCartaPorteService,
    public talonesService: TalonesService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog
  ) { }

  async ngOnInit() {
    try {
      await this.applyFilters();
    } catch (error) {
      this.snackBar.open('Error al obtener los datos', '⛔', {
        duration: 3000
      });
      console.error(error);
    }
  }

  async getTalones() {
    try {
      const talones = await this.talonesService.getTalonesByGuide(this.filters);

      if (talones.length <= 0) {
        this.snackBar.open('Sin datos disponibles', '⛔', {
          duration: 3000
        });

        this.offset -= this.itemsPerPage;
        this.isDisabledNextPage = true;

        return;
      }

      this.talones = talones;
    } catch (error) {
      throw error;
    }
  }

  async openPDF(talon: Talones) {
    try {
      this.snackBar.open('Cargando PDF', '🔌', { duration: 3000 });
      await this.search(talon);

      const dialogRef = this.dialog.open(PdfDialogComponent, {
        width: '80vw',
        height: '98%',
        data: { file: this.file, searchQuery: talon.claTalon }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });
    } catch (error) {
      this.snackBar.open('Error, aún no existe el PDF', '⛔', {
        duration: 3000
      });
    }
  }

  async search(talon) {
    try {
      this.showPdfLoading = true;
      this.tripFile = await this.ccpService.getTripByTrackingNumber(talon.claTalon);

      if (!this.tripFile) { throw new Error('Error getting trip file.'); }
      if (!this.tripFile.tripFile) { throw new Error('Error getting trip file.'); }

      this.file = this.base64ToArrayBuffer(this.tripFile.tripFile);
    } catch (error) {
      console.error(error);
      this.showPdfLoading = false;

      throw error;
    }
  }

  base64ToArrayBuffer(base64) {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }

    return bytes.buffer;
  }

  async applyFilters() {

    try {
      this.filters.where.and[0].value = this.guide.tabla;
      this.filters.where.and[1].value = this.guide.noGuia;
      this.filters.limit.offset = this.offset;
      this.filters.limit.itemsPerPage = this.itemsPerPage;

      this.showLoading = true;
      await this.getTalones();

      if (this.talones.length < this.itemsPerPage) {
        this.isDisabledNextPage = true;
      }
      this.showLoading = false;
    } catch (error) {
      this.showLoading = false;
      throw error;
    }
  }

  async nextPage($event) {
    try {
      this.offset += this.itemsPerPage;

      await this.applyFilters();

      this.isDisabledPreviousPage = this.offset === 0;
    } catch (error) {
      console.error(error);
      this.snackBar.open('Sin datos disponibles', '⛔', {
        duration: 3000
      });
    }
  }

  async previousPage($event) {
    try {
      if (this.offset > 0) {
        this.offset -= this.itemsPerPage;
        this.isDisabledNextPage = false;
        await this.applyFilters();
      }

      this.isDisabledPreviousPage = this.offset === 0;
    } catch (error) {
      console.error(error);
      this.snackBar.open('Sin datos disponibles', '⛔', {
        duration: 3000
      });
    }
  }
}
