import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AutorizacionFaltasCorte } from '../../../interfaces/nomina-estibadores/autorizacion-faltas-corte';
import { AutorizacionFaltasDetalleCorte } from '../../../interfaces/nomina-estibadores/autorizacion-faltas-detalle-corte';
import { AuthService } from '../../../authentication/login/auth.service';

const SISTEMA: number = 13;
const MODULO: number = 75;

@Component({
  selector: 'app-dialog-autorizacion-faltas-corte',
  templateUrl: './dialog-autorizacion-faltas-corte.component.html',
  styleUrls: ['./dialog-autorizacion-faltas-corte.component.css']
})
export class DialogAutorizacionFaltasCorteComponent {
  private permisos;

  autorizacionFaltasFormGroup: FormGroup;
  autorizacionFaltasCorte: AutorizacionFaltasCorte;
  lstFechasPeriodoCorte: string[] = [];
  lstFechasPeriodoCorteInitialSelected: string[] = [];

  noFaltasInicial: number = 0;
  esOficinaCedis: boolean = false;

  constructor(public dialogRef: MatDialogRef<DialogAutorizacionFaltasCorteComponent>,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { autorizacionFaltasCorte: AutorizacionFaltasCorte, lstFechasPeriodoCorte: string[], esOficinaCedis: boolean }) {
    this.permisos = this.authService.obtenerPermisosModulo(SISTEMA, MODULO);

    this.autorizacionFaltasCorte = data.autorizacionFaltasCorte;
    this.lstFechasPeriodoCorte = data.lstFechasPeriodoCorte;
    this.noFaltasInicial = this.autorizacionFaltasCorte.faltasAutoriza;
    this.esOficinaCedis = data.esOficinaCedis;

    this.autorizacionFaltasFormGroup = this.formBuilder.group({
      faltasAutorizaControl: [this.autorizacionFaltasCorte.faltasAutoriza, Validators.required],
      comentarioAutorizaControl: [this.autorizacionFaltasCorte.comentarioAutoriza, Validators.required],
      fechasPeriodoControl: [[]]
    });

    if (this.esOficinaCedis) {
      this.autorizacionFaltasFormGroup.controls['faltasAutorizaControl'].disable();
      this.autorizacionFaltasFormGroup.controls['fechasPeriodoControl'].setValidators(Validators.required);
    }

    if (this.autorizacionFaltasCorte.idAutorizacionFaltas != null) {
      this.autorizacionFaltasCorte.lstAutorizacionFaltasDetalleCorte.forEach(fechaPeriodo => {
        this.lstFechasPeriodoCorteInitialSelected.push(
          this.lstFechasPeriodoCorte[this.lstFechasPeriodoCorte.findIndex(fecha => new Date(fecha).getTime() == new Date(fechaPeriodo.fechaFaltaAutoriza).getTime())]
        )
      });

      this.autorizacionFaltasFormGroup.controls['fechasPeriodoControl'].setValue(this.lstFechasPeriodoCorteInitialSelected)
      this.autorizacionFaltasFormGroup.controls['fechasPeriodoControl'].disable();
      this.autorizacionFaltasFormGroup.controls['faltasAutorizaControl'].disable();
      this.autorizacionFaltasFormGroup.controls['comentarioAutorizaControl'].disable();
    }
  }

  cerrarDialogo(): void {
    const array = {
      autorizar: false
    };
    this.dialogRef.close(array);
  }

  autorizar(): void {
    this.autorizacionFaltasCorte.faltasAutoriza = this.autorizacionFaltasFormGroup.controls['faltasAutorizaControl'].value;
    this.autorizacionFaltasCorte.comentarioAutoriza = this.autorizacionFaltasFormGroup.controls['comentarioAutorizaControl'].value;
    this.autorizacionFaltasCorte.lstAutorizacionFaltasDetalleCorte = [];
    this.autorizacionFaltasFormGroup.controls['fechasPeriodoControl'].value.forEach(fechaPeriodo => this.autorizacionFaltasCorte.lstAutorizacionFaltasDetalleCorte.push({ fechaFaltaAutoriza: fechaPeriodo }));

    const array = {
      autorizar: true,
      autorizacionFaltasCorte: this.autorizacionFaltasCorte
    };
    this.dialogRef.close(array);
  }

  eliminar(): void {
    const array = {
      eliminar: true
    };
    this.dialogRef.close(array);
  }

  selectDays() {
    this.autorizacionFaltasFormGroup.controls['faltasAutorizaControl'].setValue(this.noFaltasInicial - (this.autorizacionFaltasFormGroup.controls['fechasPeriodoControl'].value as AutorizacionFaltasDetalleCorte[]).length);
  }

  isOptionDisabled(fechaFaltaAutoriza: string): boolean {
    return this.autorizacionFaltasFormGroup.controls['faltasAutorizaControl'].value == 0 && !this.autorizacionFaltasFormGroup.controls['fechasPeriodoControl'].value.find(fecha => new Date(fecha).getTime() == new Date(fechaFaltaAutoriza).getTime());
  }

  getDate(date: string): string {
    let fecha: Date = new Date(date);
    return fecha.toLocaleString('es-MX',
      {
        weekday: 'long',
        day: '2-digit'
      }
    );
  }

  getDateTime(date: string): string {
    let fecha: Date = new Date(date);
    return fecha.toLocaleString('es-MX',
      {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      }
    );
  }

}
