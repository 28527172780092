import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrintService } from '../../../../services/print.service';
import { MatPaginator, MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-print-poliza-pagados',
  templateUrl: './print-poliza-pagados.component.html',
  styleUrls: ['./print-poliza-pagados.component.css']
})
export class PrintPolizaPagadosComponent implements OnInit {
  dataSource: any[];
   public displayedColumns = ['consecutivo', 'concepto',  'poliza',
     'referencia', 'fecha', 'importe'];

  constructor(private printService: PrintService) { }
  /* Obtiene los datos de impresión guardados previamente en localStorage */
  ngOnInit() {
    this.dataSource = JSON.parse(sessionStorage.getItem("datosImpresion"));
  }
  /* Una vez listos los datos se envian al servicio de impresión*/
  ngAfterViewInit()
  {
    setTimeout(() => {
      console.log(this.dataSource);
      this.printService.isPrinting = true;
      console.log("Desde Poliza Pagados: " +this.printService.isPrinting);
      this.printService.datosListos();
    });

  }


}