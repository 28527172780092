import { Component, OnInit, Inject, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatAutocompleteSelectedEvent  } from '@angular/material';
import { FormControl } from '@angular/forms';
import { Oficina } from '../../../interfaces/oficina';
import { OficinaActual } from '../../../interfaces/oficina-actual';
import { OficinaService } from '../../../services/oficina.service';
import { AuthService } from '../../../authentication/login/auth.service';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'dialog-cambio-oficina',
  templateUrl: './dialog-cambio-oficina.component.html',
  styleUrls: ['./dialog-cambio-oficina.component.css']
})

/*
* Título                    : cambio-oficina
* Descripción               : Modal para cambio de Oficina
* Compañía                  : Transportes Castores de Baja California S.A. de C.V. – Área de Desarrollo
* Fecha de creación         : 2020-07-20
* Autor                     : German Galaviz [germagafra]
* Versión                   : 1.0.0
* ID Requerimiento/Ticket   : NULL
*/
export class DialogCambioOficinaComponent implements OnInit, AfterContentChecked {

  private dataOficina: any;
  private oficinaVirtualPlaza: any;
  private oficinaVirtualDatos: OficinaActual;
  private statusError = 0;
  private oficinaControl = new FormControl();
  private filteredOptions: Observable<Oficina[]>;
  private datosOficinaSel: Oficina[];
  private spinner = 0;
  private oficinaActualCombinada: any;

 /* Inicialización de variables del constructor */
  constructor(public dialogRef: MatDialogRef<DialogCambioOficinaComponent>,
              private oficinaService: OficinaService,
              private authService: AuthService,
              private changeDetector: ChangeDetectorRef,
              @Inject(MAT_DIALOG_DATA) public data
  ) { }
  /* Inicializa las variables para comprobar la oficina actual y origen en caso de ser corporativo
     y manda llamar el cargado de oficinas */
  ngOnInit() {
    if (this.authService.oficinaActual !== undefined &&
            this.authService.oficinaActual.idOficina !== undefined &&
            this.authService.oficinaActual.idOficina !== null &&
            this.authService.oficinaActual.idOficina !== 1 &&
            !isNaN(this.authService.oficinaActual.idOficina)) {
      this.oficinaActualCombinada = ' -> ' + this.authService.oficinaActual.plaza;
    }
    this.cargaOficinas();
  }
  /* Aplica los cambios ante la detección de cambios */
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
  /* Carga Todas las Oficinas */
  cargaOficinas(): void {
    this.spinner = 1;
  this.dataOficina = [];
  this.oficinaService.findOficinasByAll().subscribe(
      response => {
        this.statusError = 1;
        this.dataOficina = response;
        this.filteredOptions = this.oficinaControl.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );
        this.statusError = 0;
        this.spinner = 0;
      },
      error => {
        this.statusError = 1;
        this.spinner = 0;
      });
  }
  /* Comprueba que el valor seleccionado sea valido */
  compruebaDatos(valor): void {
    if (valor === '' || valor === undefined || valor !==  null) {
      this.datosOficinaSel = [];
    }
  }
  /* Ante un cambio de oficina, prepara los datos que seran enviados, correspondientes a la selección */
  onSelectionChanged(event: MatAutocompleteSelectedEvent): void {
    setTimeout(() => {
      this.datosOficinaSel = event.option.value;
      this.oficinaVirtualPlaza = event.option.value.plaza;
      this.oficinaVirtualDatos = event.option.value;
    });
  }
  /* Realiza el filtro ingresado por el Usuario */
  private _filter(nombre: string): Oficina[] {

    const filterValue = nombre.toString().toLowerCase();

    return this.dataOficina.filter(option => option.plaza.toLowerCase().includes(filterValue));
  }
  /* Genera el cierre del modal */
  cerrarDialogo(): void {
    const array = {
      'respuesta': false,
    };
    this.dialogRef.close(array);
  }
  /* Confirma la oficina seleccionada y envia los datos seleccionados */
  confirmado(): void {
    setTimeout(() => {
      this.authService.guardarOficinaActual(this.oficinaVirtualDatos);
      sessionStorage.removeItem('historiaPolizaPagados');
      const array = {
          'respuesta': true,
          'datos': this.oficinaVirtualDatos
        };
      this.dialogRef.close(array);
    });
  }
}
