import { Component } from '@angular/core';
import { PrintService } from './services/print.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
	//Ponemos a dispocisión la variable isPrinting en el component
	constructor(public printService: PrintService){}

}
