import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { filter, take } from "rxjs/operators";
import { AppsettingsComponent } from "../app-settings/appsettings.component";
import { IGuide } from "../interfaces/talones-oficina/guide";
import { IParamFilter } from "../interfaces/param-filter";
import { Trip } from "../interfaces/trip";
import { Viaje } from "../interfaces/viaje";
import { Talones } from "../interfaces/talones-oficina/talones";

/*
 * Título                    : Correción de talones para timbrado
 * Descripción               : servicio para la correción de talones para poder ser timbrados
 * Compañía                  : Transportes Castores de Baja California S.A. de C.V. – Área de Desarrollo
 * Fecha de creación         : 2021-11-22
 * Autor                     : Alan A. López M. [desarrolloti21] - Moises Arrona [moisesarrona]
 * Versión                   : 1.0.0
 * ID Requerimiento/Ticket   : NULL
 */

@Injectable({
  providedIn: "root",
})
export class TalonesService {
  constructor(
    private appsettings: AppsettingsComponent,
    private http: HttpClient
  ) {}

  /*
   * Título                    : findViajesByFechaOficina
   * Descripción               : Obtener talones por oficina y fecha.
   * Fecha de creación         : 2021-11-22
   * Autor                     : Moises Arrona [moisesarrona]
   * Versión                   : 1.0.0
   * ID Requerimiento/Ticket   : NULL
   */
  async findViajesByFechaOficina(
    finicio: string,
    fFin: string,
    idOficina: number
  ): Promise<Viaje[]> {
    let response;
    try {
      response = await this.http
        .get<{ data: Viaje[] }>(
          this.appsettings.API_ENDPOINT +
            `talones/findViajesByFechaOficina/${finicio}/${fFin}/${idOficina}`
        )
        .toPromise();

      if (!response || !response.data) {
        throw new Error("Error, getting empty server response.");
      }
    } catch (error) {
      console.error(error);
    }

    return response ? response.data : null;
  }

  /*
   * Título                    : findTalonesByViaje
   * Descripción               : Obtener talones por viaje. (Inluye no_guia y cfdi)
   * Fecha de creación         : 2021-11-24
   * Autor                     : Alan A. López M. [desarrolloti21]
   * Versión                   : 1.0.0
   * ID Requerimiento/Ticket   : NULL
   */
  async findTalonesByViaje(idViaje, idOficina, fechaViaje) {
    let response;

    try {
      const body = {
        idViaje,
        idOficina,
        fechaViaje,
      };
      response = await this.http
        .post(
          this.appsettings.API_ENDPOINT + `talones/findTalonesByViaje`,
          body
        )
        .toPromise();

      if (!response) {
        throw new Error("Error, getting empty server response.");
      }
    } catch (error) {
      console.error(error);
      response = null;
      throw new Error("Error getting data.");
    }

    return response;
  }

  /*
   * Título                    : findViajesByFechaOficina
   * Descripción               : Obtener talones por oficina y fecha.
   * Fecha de creación         : 2021-11-22
   * Autor                     : Moises Lopez Arrona [moisesarrona]
   * Versión                   : 1.0.0
   * ID Requerimiento/Ticket   : NULL
   */
  async findViajesByFilters(filters: IParamFilter): Promise<Viaje[]> {
    let response;
    try {
      response = await this.http
        .post<{ data: Viaje[] }>(
          this.appsettings.API_ENDPOINT + `talones/viajesFiltered/`,
          filters
        )
        .toPromise();

      if (!response || !response.data) {
        throw new Error("Error, getting empty server response.");
      }
    } catch (error) {
      console.error(error);
    }

    return response ? response.data : null;
  }

  /*
   * Título                    : getTalonesByGuide
   * Descripción               : Obtener talones por guia.
   * Fecha de creación         : 2021-12-09
   * Autor                     : Alan A. López M. [desarrolloti21]
   * Versión                   : 1.0.0
   * ID Requerimiento/Ticket   : NULL
   */
  async getTalonesByGuide(filters: IParamFilter): Promise<Talones[]> {
    let talones: Talones[] = [];

    try {
      const response = await this.http
        .post<{ data: Talones[] }>(
          this.appsettings.API_ENDPOINT + `talones/talonesFilteredByTg`,
          filters
        )
        .toPromise();

      if (response && response.data) {
        talones = response.data;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }

    return talones;
  }

  /*
   * Título                    : findStatusSatByTalon
   * Descripción               : Otener el estatus de un consecutivo en el portal del SAT
   * Fecha de creación         : 2022-03-24
   * Autor                     : Alma Laura Meza M. [almalaurameza]
   * Versión                   : 1.0.0
   * ID Requerimiento/Ticket   : NULL
   */
  findStatusSatByTalon(claTalon: string, consCcp: string): Observable<any> {
    return this.http.get(
      this.appsettings.API_ENDPOINT +
        `talones/findStatusSatByTalon/${claTalon}/${consCcp}`
    );
  }
}
