import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { EstibadorOrden } from '../../../interfaces/nomina-estibadores/estibador-orden';
import { NominaEstibadoresService } from '../../../services/nomina-estibadores.service';
import { Personal } from '../../../interfaces/personal';

@Component({
  selector: 'app-dialog-estibador-orden',
  templateUrl: './dialog-estibador-orden.component.html',
  styleUrls: ['./dialog-estibador-orden.component.css']
})
export class DialogEstibadorOrdenComponent {
  public isLoading: boolean = false;

  public personalOrdenFormGroup: FormGroup;
  lstPersonalSinOrden: Personal[] = [];
  lstEstibadorOrden: EstibadorOrden[] = [];
  lstPersonalSinOrdenInitialSelected: Personal[] = [];

  idOficina: number = 0;
  idClasificacionCorte: number = 0;
  idTipoOrden: number = 0;

  constructor(public dialogRef: MatDialogRef<DialogEstibadorOrdenComponent>,
    private formBuilder: FormBuilder,
    private nominaEstibadoresService: NominaEstibadoresService,
    @Inject(MAT_DIALOG_DATA) public data: { idOficina: number, idClasificacionCorte: number, idTipoOrden: number, lstEstibadorOrden: EstibadorOrden[] }) {
    this.idOficina = data.idOficina;
    this.idClasificacionCorte = data.idClasificacionCorte;
    this.idTipoOrden = data.idTipoOrden;
    this.lstEstibadorOrden = data.lstEstibadorOrden;

    this.personalOrdenFormGroup = this.formBuilder.group({
      personalOrdenControl: [[], Validators.required]
    });

    this.obtenerEstibadoresOficina();
  }

  obtenerEstibadoresOficina() {
    this.isLoading = true;
    this.nominaEstibadoresService.obtenerEstibadoresSinOrdenPorOficinaYClasificacion(this.idOficina, this.idClasificacionCorte).subscribe(response => {
      this.lstPersonalSinOrden = response;

      this.lstEstibadorOrden.forEach(estibadorOrden => {
        let index: number = this.lstPersonalSinOrden.findIndex(index => index.idPersonal == estibadorOrden.personalEstibador.idPersonal);
        if (index >= 0) {
          this.lstPersonalSinOrdenInitialSelected.push(this.lstPersonalSinOrden[index]);
        }
      });

      if (this.lstPersonalSinOrdenInitialSelected.length > 0) this.personalOrdenFormGroup.controls['personalOrdenControl'].setValue(this.lstPersonalSinOrdenInitialSelected);
      this.isLoading = false;
    });
  }

  cerrarDialogo(): void {
    const array = {
      generar: false
    };
    this.dialogRef.close(array);
  }

  guardar(): void {
    const array = {
      guardar: true,
      lstNewPersonalConOrden: this.personalOrdenFormGroup.controls['personalOrdenControl'].value as Personal[]
    };
    this.dialogRef.close(array);
  }

  getDate(date: string): string {
    let fecha: Date = new Date(date);
    return fecha.toLocaleString('es-MX',
      {
        weekday: 'long',
        day: '2-digit'
      }
    );
  }

  getDateTime(date: string): string {
    let fecha: Date = new Date(date);
    return fecha.toLocaleString('es-MX',
      {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      }
    );
  }

}
