import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout-print',
  templateUrl: './layout-print.component.html',
  styleUrls: ['./layout-print.component.css']
})

/*
* Título                    : layout-print
* Descripción               : Layout para imprimir
* Compañía                  : Transportes Castores de Baja California S.A. de C.V. – Área de Desarrollo
* Fecha de creación         : 2020-06-29
* Autor                     : German Galaviz [germagafra]
* Versión                   : 1.0.0
* ID Requerimiento/Ticket   : NULL
*/
export class LayoutPrintComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
