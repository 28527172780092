import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Trip } from '../../../interfaces/trip';
import { Viaje } from '../../../interfaces/viaje';
import { ComplementoCartaPorteService } from '../../../services/complemento-carta-porte.service';
import { PdfDialogComponent } from '../../modals/pdf-dialog/pdf-dialog.component';

@Component({
  selector: 'app-trip-accordion',
  templateUrl: './trip-accordion.component.html',
  styleUrls: ['./trip-accordion.component.css']
})
export class TripAccordionComponent implements OnInit {
  @Input() trip: Viaje
  file
  showPdfLoading = false
  tripFile: Trip

  private tripStatus: string = ""

  constructor(
    public snackBar: MatSnackBar,
    public ccpService: ComplementoCartaPorteService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.setTripStatus()
  }

  async openPDF(trip) {
    try {
      this.snackBar.open('Cargando PDF', '🔌', { duration: 3000 })
      await this.search(trip)

      // console.log(this.trip.fileRoute)
      const dialogRef = this.dialog.open(PdfDialogComponent, {
        width: '80vw',
        height: '98%',
        data: { file: this.file, searchQuery: trip.cla_talon, pathUrl: this.trip.fileRoute }
      })

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed')
      })
    } catch (error) {
      this.snackBar.open('Error, aún no existe el PDF', '⛔', {
        duration: 3000
      })
    }
  }

  async search(trip) {
    try {
      this.showPdfLoading = true;
      this.tripFile = await this.ccpService.getTripFile(trip.fileRoute);

      if (!this.tripFile) { throw new Error('Error getting trip file.'); }

      this.file = this.base64ToArrayBuffer(this.tripFile);
    } catch (error) {
      console.error(error);
      this.showPdfLoading = false;

      throw error;
    }
  }

  base64ToArrayBuffer(base64) {
    const binary_string = window.atob(base64);
    const len = binary_string.length
    const bytes = new Uint8Array(len)

    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i)
    }

    return bytes.buffer
  }

  setTripStatus() {
    switch (this.trip.estatus) {
      case 1:
        this.tripStatus = 'Previaje';
        break;
      case 2:
        this.tripStatus = 'Viaje';
        break;
      case 3:
        this.tripStatus = 'Terminado';
        break;
      case 4:
        this.tripStatus = 'Carga descarga';
        break;
      case 5:
        this.tripStatus = 'En modificación';
        break;
      case 6:
        this.tripStatus = 'Terminado por transbordo';
        break;
      case 7:
        this.tripStatus = 'En recolección';
        break;
      case 0:
        this.tripStatus = 'Cancelado';
        break;
    }
  }
}
