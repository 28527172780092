import { Component, Inject } from '@angular/core';
import { AuthService } from '../../../authentication/login/auth.service';
import { NominaEstibadoresService } from '../../../services/nomina-estibadores.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import UtilsValidateFormat from '../../helpers/utils-validate-format';
import { EstibadorNuevo } from '../../../interfaces/nomina-estibadores/estibador-nuevo';
import { EstibadoresNuevosAgencia } from '../../../interfaces/nomina-estibadores/estibadores-nuevos-agencia.interface';
import { forkJoin } from 'rxjs';

const SISTEMA: number = 13;
const MODULO: number = 75;

@Component({
  selector: 'app-dialog-sueldo-estibador-nuevo',
  templateUrl: './dialog-sueldo-estibador-nuevo.component.html',
  styleUrls: ['./dialog-sueldo-estibador-nuevo.component.css']
})
export class DialogSueldoEstibadorNuevoComponent {
  public isLoading: boolean = false;
  private permisos;
  public arrEstibadorNuevo: EstibadorNuevo[] = [];
  public arrJoinEstibadoresNuevosAgencias: EstibadorNuevo[] = []

  public isArrEstibadorNuevoValid: boolean = true;

  public esOficinaConAgencias: boolean = false;
  public arrEstibadoresNuevosAgencia: EstibadoresNuevosAgencia[] = [];
  public isArrEstibadorNuevoAgenciaValid: boolean = true;

  constructor(public dialogRef: MatDialogRef<DialogSueldoEstibadorNuevoComponent>,
    private authService: AuthService,
    private nominaEstibadoresService: NominaEstibadoresService,
    @Inject(MAT_DIALOG_DATA) public data: { idOficina: number }) {
    this.permisos = this.authService.obtenerPermisosModulo(SISTEMA, MODULO);

    this.obtenerEstibadoresNuevos(data.idOficina);
  }

  obtenerEstibadoresNuevos(idOficina: number): void {
    this.isLoading = true;
    this.nominaEstibadoresService.obtenerEstibadoresNuevosActivos(idOficina).subscribe(response => {
      this.arrEstibadorNuevo = response as EstibadorNuevo[];

      this.obtenerEstibadoresNuevosAgenciasDeMatriz(idOficina);
    });
  }

  obtenerEstibadoresNuevosAgenciasDeMatriz(idOficina: number) {
    this.nominaEstibadoresService.obtenerOficinasAgenciasDeMatriz(idOficina.toString()).subscribe(response => {
      let arrOficinasAgencias = response;
      this.esOficinaConAgencias = arrOficinasAgencias.length > 0 ? true : false;
      if (this.esOficinaConAgencias) {
        var allObservablesEstibadoresNuevosAgencias = [];
        arrOficinasAgencias.forEach(oficina => {
          allObservablesEstibadoresNuevosAgencias.push(this.nominaEstibadoresService.obtenerEstibadoresNuevosActivos(oficina.oficina));
        });

        forkJoin(allObservablesEstibadoresNuevosAgencias)
          .subscribe((response) => {
            arrOficinasAgencias.forEach((oficina, index) => {
              if ((response[index] as EstibadorNuevo[]).length > 0) {
                this.arrEstibadoresNuevosAgencia.push({
                  agencia: oficina,
                  arrEstibadorNuevo: response[index] as EstibadorNuevo[]
                });
              }

              this.isLoading = false;
            });
            this.joinArraysEstibadoresNuevosAgencias();
          });
      } else {
        this.isLoading = false;
      }
    });
  }

  cerrarDialogo(): void {
    const array = {
      calcular: false
    };
    this.dialogRef.close(array);
  }

  guardar() {
    let arrEstibadorNuevoFinal: EstibadorNuevo[] = [];
    arrEstibadorNuevoFinal.push.apply(arrEstibadorNuevoFinal, this.arrEstibadorNuevo);
    this.arrEstibadoresNuevosAgencia.forEach(estibadoresNuevosAgencia => arrEstibadorNuevoFinal.push.apply(arrEstibadorNuevoFinal, estibadoresNuevosAgencia.arrEstibadorNuevo));

    const array = {
      siguiente: true,
      arrEstibadorNuevo: arrEstibadorNuevoFinal
    };
    this.dialogRef.close(array);
  }

  checkIfArrEstibadorNuevoValid(): void {
    this.isArrEstibadorNuevoValid = this.arrEstibadorNuevo.filter(estibadorNuevo => estibadorNuevo.sueldoUltimoCorte == null).length ? false : true;
    this.joinArraysEstibadoresNuevosAgencias();
  }

  checkIfArrEstibadorNuevoAgenciaValid(): void {
    this.joinArraysEstibadoresNuevosAgencias();
    this.isArrEstibadorNuevoAgenciaValid = this.arrJoinEstibadoresNuevosAgencias.filter(estibadorNuevo => estibadorNuevo.sueldoUltimoCorte == null).length ? false : true;
  }

  joinArraysEstibadoresNuevosAgencias() {
    this.arrJoinEstibadoresNuevosAgencias = [];
    this.arrEstibadoresNuevosAgencia.forEach(estibadoresNuevosAgencia => this.arrJoinEstibadoresNuevosAgencias.push.apply(this.arrJoinEstibadoresNuevosAgencias, estibadoresNuevosAgencia.arrEstibadorNuevo));
  }

  validateNumberFormatKeyPress(event: any) {
    UtilsValidateFormat.validateNumberFormatEvent(event);
  }

}
