import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppsettingsComponent } from '../app-settings/appsettings.component';

@Injectable({
  providedIn: 'root'
})
export class TalonesLogService {

  constructor(
    private appsettings: AppsettingsComponent,
    private http: HttpClient
  ) { }

  /*
  * Título                    : findAllCorrecionTimbrado
  * Descripción               : Obtiene todas las corecciones a los talones.
  * Fecha de creación         : 2022-01-25
  * Autor                     : Moises Lopez Arrona. [moisesarrona]
  * Versión                   : 1.0.0
  * ID Requerimiento/Ticket   : NULL
  */
  findAllCorrecionTimbrado(): Observable<any> {
    return this.http.get(this.appsettings.API_ENDPOINT + 'talones/findAllCorrecionTimbrado').pipe(
      map((response: any) => response as any),
      catchError(e => {
        return throwError(e);
      })
    );
  }

  /*
  * Título                    : findAllCorrecionTimbrado
  * Descripción               : Obtiene todas las corecciones de un talon en especifico.
  * Fecha de creación         : 2022-01-25
  * Autor                     : Moises Lopez Arrona. [moisesarrona]
  * Versión                   : 1.0.0
  * ID Requerimiento/Ticket   : NULL
  */
  findByClaTalon(claTalon: String): Observable<any> {
    return this.http.get(this.appsettings.API_ENDPOINT + 'talones/findByClaTalon/' + claTalon).pipe(
      map((response: any) => response as any),
      catchError(e => {
        return throwError(e);
      })
    );
  }

  /*
  * Título                    : findAllCorrecionTimbrado
  * Descripción               : Obtiene todas las corecciones de un talon en especifico.
  * Fecha de creación         : 2022-01-25
  * Autor                     : Moises Lopez Arrona. [moisesarrona]
  * Versión                   : 1.0.0
  * ID Requerimiento/Ticket   : NULL
  */
  findAllCorrecionTimbradoByFilter(correcionTimbrado: any): Observable<any> {
    return this.http.post(this.appsettings.API_ENDPOINT + 'talones/findAllCorrecionTimbradoByFilter/', correcionTimbrado).pipe(
      map((response: any) => response as any),
      catchError(e =>{
        return throwError(e);
      })
    );
  }
}
