import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
/*
  * Título                    : fecha.service
  * Descripción               : Servicio para proporcionar los periodos de las  fechas en funcion del año en curso
  * Fecha de creación         : 2020-07-22
  * Autor                     : German Galaviz [germagafra]
  * Versión                   : 1.0.0
  * ID Requerimiento/Ticket   : NULL
  */
@Injectable({
  providedIn: 'root'
})
export class FechaService {

 	public getPeriodo(mes: number)
 	{
 		let fecha = new Date();
 		let respuesta = {
 					"periodoini": "",
 					"periodofin": ""
 				}
 		mes = mes * 1;
 		mes = (mes != undefined && mes != null && !isNaN(mes) && mes > 0 && mes <= 12 && Number.isInteger(mes)) ? mes: fecha.getMonth() + 1;
 		console.log(mes);
 		let anio = fecha.getFullYear();
 		console.log("resto: " + anio % 100);
 		switch (mes) {
 			case 1:
 				respuesta['periodoini'] = "01-01-"+anio;
 				respuesta['periodofin'] = "31-01-"+anio;
 				break;
 			case 2:
 				respuesta['periodoini'] = "01-02-"+anio;
 				respuesta['periodofin'] = (((anio % 4 == 0) && (anio % 100 != 0 )) 
 					|| (anio % 400 == 0))?  "29-02-"+ anio : "28-02-"+ anio;
 				break;
 			case 3:
 				respuesta['periodoini'] = "01-03-"+anio;
 				respuesta['periodofin'] = "31-03-"+anio;
 				break;
 			case 4:
 				respuesta['periodoini'] = "01-04-"+anio;
 				respuesta['periodofin'] = "30-04-"+anio;
 				break;
 			case 5:
 				respuesta['periodoini'] = "01-05-"+anio;
 				respuesta['periodofin'] = "31-05-"+anio;
 				break;
 			case 6:
 				respuesta['periodoini'] = "01-06-"+anio;
 				respuesta['periodofin'] = "30-06-"+anio;
 				break;
 			case 7:
 				respuesta['periodoini'] = "01-07-"+anio;
 				respuesta['periodofin'] = "31-07-"+anio;
 				break;
 			case 8:
 				respuesta['periodoini'] = "01-08-"+anio;
 				respuesta['periodofin'] = "31-08-"+anio;
 				break;
 			case 9:
 				respuesta['periodoini'] = "01-09-"+anio;
 				respuesta['periodofin'] = "30-09-"+anio;
 				break;
 			case 10:
 				respuesta['periodoini'] = "01-10-"+anio;
 				respuesta['periodofin'] = "31-10-"+anio;
 				break;
 			case 11:
 				respuesta['periodoini'] = "01-11-"+anio;
 				respuesta['periodofin'] = "30-11-"+anio;
 				break;
 			case 12:
 				respuesta['periodoini'] = "01-12-"+anio;
 				respuesta['periodofin'] = "31-12-"+anio;
 				break;
 		}
 		return respuesta;


 	}

}