import { Component, Inject, OnInit } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
  MatTableDataSource,
} from "@angular/material";
import { TalonesMaterial } from "../../../interfaces/paqueteria/talones-material";
import { VerDatosTalonComponent } from "../ver-datos-talon/ver-datos-talon.component";
import { EyeService } from "../../../services/eye.service";
import { Materiales } from "../../../interfaces/paqueteria/materiales";

@Component({
  selector: "app-talones-material-ind",
  templateUrl: "./talones-material-ind.component.html",
  styleUrls: ["./talones-material-ind.component.css"],
})
export class TalonesMaterialIndComponent implements OnInit {
  displayedColumns = [
    "position",
    "talon",
    "tipoMaterial",
    "cantidad",
    "unidadMedida",
  ];
  dataSource = new MatTableDataSource<TalonesMaterial>([]);

  constructor(
    private dialogRef: MatDialogRef<TalonesMaterialIndComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { lstTalonesMaterial: TalonesMaterial[] },
    private dialog: MatDialog,
    private eyeService: EyeService
  ) {
    this.dataSource.data = this.data.lstTalonesMaterial;
    this.getMateriales();
  }

  ngOnInit() {}

  getMateriales() {
    this.eyeService.getMateriales().subscribe((lstMateriales: Materiales[]) => {
      this.dataSource.data.forEach((element) => {
        const material = lstMateriales.find((resp) => {
          return resp.idMaterial == element.idMaterial;
        });
        element.nombreMaterial =
          material != undefined ? material.nombre : "material";
      });
      this.dataSource._updateChangeSubscription();
    });
  }

  verDatosTalon(numeroTalon: string) {
    const dialogoTalonesRef = this.dialog.open(VerDatosTalonComponent, {
      width: "900px",
      data: numeroTalon,
      panelClass: "colorFondo",
    });
  }
}
