import { Component, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";

import { MatSnackBar } from '@angular/material';
import Swal from 'sweetalert2';

/**
 * @title Clase base para implementar en clase componente (alertas base y destruir subscripciones).
 * @since 2022-03-30
 */

@Component({
  template: ``
})
export class BaseComponent implements OnDestroy {
  unsubscribe$ = new Subject<void>();

  constructor(protected snackBar: MatSnackBar) { }

  /**
   * openSnackBar: Función generica para mostrar snackbar
   *
   * @void
   * @author Alma Laura Meza M. [almalaurameza]
   * @since 2022-03-30
   */
  protected openSnackBar(message: string, action: string, tiempo: number): void {
    this.snackBar.open(message, action, {
      duration: tiempo
    });
  }

  /**
   * alertBase: Función genérica para mostrar alertas informativas
   *
   * @void
   * @author Alma Laura Meza M. [almalaurameza]
   * @since 2022-03-30
   */
  protected alertBase(icon: any, title: any, text: any): void {
    Swal.fire({
      icon: icon,
      title: title,
      text: text,
      showConfirmButton: true
    });
  }

  /**
   * confirmAcction: TOMA LA RESPUESTA DEL CLIENTE.
   *
   * @void
   * @author Moises Lopez Arrona. [moisesarrona]
   * @update Alma Laura Meza M. [almalaurameza]
   * @since 2022-04-13
   */
  protected confirmAcction(title: any, text: any, icon: any, confirmButtonText: any): any {
    return Swal.fire({
      title: title,
      text: text,
      icon: icon,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: confirmButtonText
    })
  }

  ngOnDestroy() {
    // Destruye las subscripciones al cerrar el componente
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}