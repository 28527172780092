import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-simple-paginator',
  templateUrl: './simple-paginator.component.html',
  styleUrls: ['./simple-paginator.component.css']
})
export class SimplePaginatorComponent implements OnInit {
  @Input() disablePreviousPage = false;
  @Input() disableNextPage = false;
  @Output() onNextPage: EventEmitter<any>;
  @Output() onPreviousPage: EventEmitter<any>;

  constructor() {
    this.onNextPage = new EventEmitter();
    this.onPreviousPage = new EventEmitter();
  }

  ngOnInit() {
  }

}
