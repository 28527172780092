import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class AppsettingsComponent {
  API_ENDPOINT = 'http://back.castores.com.mx:8090/castores/services/';
  ENDPOINT = 'http://back.castores.com.mx:8090/';
  WSFACTURACION = 'http://webservices.castores.com.mx/WSFacturacion/app/services/';
  REPORTES_ENDPOINT = "http://back.castores.com.mx:8090/castores/services/reporte/ventas/";
  SOLICITUDEYE_ENDPOINT = "http://back.castores.com.mx:8090/castores/services/empaque/embalaje/";
  KARDEXEYE_ENDPOINT = "http://back.castores.com.mx:8090/castores/services/kardex/";
  SAP_ENDPOINT = "http://10.3.1.234:8090/castores/services/sap/solicitud-eye/v1/";
  HERRAMIENTA_ENDPOINT = 'http://back.castores.com.mx:8090/castores/service/';
  VERSION = "2.1.17.0";
}
