import { Component, Input, OnInit } from "@angular/core";
import { MatDialog, MatSnackBar } from "@angular/material";
import { Router } from "@angular/router";
import { TalonEstatusEnum } from "../../../interfaces/correccion-timbrado/talon-estatus-enum";
import { ComplementoCartaPorteService } from "../../../services/complemento-carta-porte.service";
import { CorrecionTimbradoComponent } from "../../modals/correcion-timbrado/correcion-timbrado.component";
import { EstatusSatConsecutivoComponent } from "../../modals/estatus-sat-consecutivo/estatus-sat-consecutivo.component";
import { PdfDialogComponent } from "../../modals/pdf-dialog/pdf-dialog.component";
import * as fileSaver from "file-saver";
import { FileSaverOptions } from "file-saver";

@Component({
  selector: "app-talones-item",
  templateUrl: "./talones-item.component.html",
  styleUrls: ["./talones-item.component.css"],
})
export class TalonesItemComponent implements OnInit {
  // variables del timbrado
  private status: String = "";
  private talonStatus = TalonEstatusEnum;

  // PDF
  @Input() trip: any;
  tripFile: any;
  private guides = [];
  private showLoading = false;
  private showPdfLoading = false;
  private file;
  private filepdf;

  // Talon´s body CCP
  @Input() talon: any;
  complementocpConsecutivo: any = [];

  constructor(
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public ccpService: ComplementoCartaPorteService,
    private router: Router
  ) {}

  ngOnInit() {
    this.statusTimbrado();
    this.consecutivoValidator();
  }

  /**
   * openFixTimbradoDiagolg: Abre el modal de CorreccionTimnbrado validando el consecutivo
   * para tomar el ultimo destino, y fechas de 72 hrs.
   *
   * @void
   * @author Moises Lopez Arrona [moisesarrona]
   * @since 2021-11-30
   */
  async openFixTimbradoDiagolg(talon) {
    try {
      let consCcp: any;
      if (talon.complementocpConsecutivo) {
        consCcp = talon.complementocpConsecutivo.length;
      } else {
        consCcp = 0;
      }

      let data;
      if (consCcp === 0 || consCcp === 1) {
        
        data = {
          claTalon: talon.claTalon,
          idoficina: talon.idoficina,
          claveoficina: talon.idoficina,
          consCcp: consCcp,
          tipoPago: talon.tr.tipopago
        };
      } else {
        if(talon.cfdi.fecha>='2022-09-01T00:00:00'){
          data = {
            claTalon: talon.claTalon,
            idoficina: talon.idoficinaorigen,
            claveoficina: talon.idoficina,
            consCcp: consCcp,
            tipoPago: talon.tr.tipopago
          };
        }
        if(talon.cfdi.fecha<'2022-09-01T00:00:00'){
        data = {        
          claTalon: talon.claTalon,
          idoficina:
            talon.complementocpConsecutivo[consCcp - 2].idoficinadestino,
          claveoficina:
            talon.complementocpConsecutivo[consCcp - 2].idoficinadestino,
          consCcp: consCcp,
          tipoPago: talon.tr.tipopago
        };
      }
      }
      // validacion de fechas
      let fecha;
      if (talon.cfdi) {
        if (talon.cfdi.fecha) {
          fecha = talon.cfdi.fecha;
        } else {
          fecha = Date.now();
        }
      } else {
        fecha = Date.now();
      }

      data.fecha = talon.cfdi ? talon.cfdi.fecha : "";
      const dialogRef = this.dialog.open(CorrecionTimbradoComponent, {
        width: "550px",
        height: "500px",
        data
      });

      dialogRef.afterClosed().subscribe((result) => {});
    } catch (error) {
      console.error(error);
      this.snackBar.open("Error al abrir el modal", "⛔", {
        duration: 3000,
      });
    }
  }

  openFixEstatusSatDiagolg(claTalon: string, consCcp: string) {
    let data = {
      claTalon: claTalon,
      consCcp: consCcp
    };
    const dialogRef = this.dialog.open(EstatusSatConsecutivoComponent, {
      width: "550px",
      height: "300px",
      data
    });

    dialogRef.afterClosed();
  }

  // Abre el PDF para su visualización
  async openPDF(talon: any) {
    try {
      this.snackBar.open("Cargando PDF", "🔌", { duration: 3000 });
      await this.search(talon);

      const dialogRef = this.dialog.open(PdfDialogComponent, {
        width: "80vw",
        height: "98%",
        data: {
          file: this.file,
          searchQuery: talon.claTalon,
          trip: this.tripFile
        },
      });

      dialogRef.afterClosed().subscribe((result) => {});
    } catch (error) {
      this.snackBar.open(
        "Error, aún no existe el PDF, Verificar PDF EN Consecutivos",
        "⛔",
        {
          duration: 3000
        }
      );
    }
  }

  // Desacarga PDF Directamente

  async descargarPDF(talon: any, consecutivo: any) {
    try {
      let consecutivopdf = 0;
      while (consecutivopdf < talon.complementocpConsecutivo.length) {
        if (
          talon.complementocpConsecutivo[consecutivopdf].consCcp == consecutivo
        ) {
          this.snackBar.open("Descargando PDF", "🔌", { duration: 3000 });
          await this.searchpdf(talon, consecutivopdf);

          const blob = new Blob([this.filepdf], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        }
        consecutivopdf++;
      }
    } catch (error) {
      this.snackBar.open("Error, aún no existe el PDF", "⛔", {
        duration: 3000,
      });
    }
  }

  // Desacarga XML Directamente
  // Busca el XML en las propiedades del talon y lo descarga en formato XML
  async openXML(talon: any, consecutivo: number) {
    try {
      let consecutivoxml = 0;
      while (consecutivoxml < talon.complementocpXml.length) {
        if (talon.complementocpXml[consecutivoxml].consCcp == consecutivo) {
          this.snackBar.open("Descargando XML", "🔌", { duration: 3000 });

          const blob = new Blob([talon.complementocpXml[consecutivoxml].xml], {
            type: "text/plain"
          });
          fileSaver.saveAs(blob, talon.claTalon + ".xml");
        }
        consecutivoxml++;
      }
    } catch (error) {
      this.snackBar.open("Error, aún no existe el XML", "⛔", {
        duration: 3000
      });
    }
  }

  // Busca el PDF
  async search(talon: any) {
    try {
      this.showPdfLoading = true;
      this.tripFile = await this.ccpService.getTripByTrackingNumber(
        talon.claTalon
      );

      if (!this.tripFile) {
        throw new Error("Error getting trip file.");
      }
      if (!this.tripFile.tripFile) {
        throw new Error("Error getting trip file.");
      }

      this.file = this.base64ToArrayBuffer(this.tripFile.tripFile);
    } catch (error) {
      console.error(error);
      this.showPdfLoading = false;

      throw error;
    }
  }
  //Busca PDF manual para descarga
  async searchpdf(talon: any, consecutivopdf: number) {
    try {
      this.showPdfLoading = true;
      var pdf = await this.ccpService.getTripFilepdf(
        talon.claTalon,
        talon.complementocpConsecutivo[consecutivopdf].consCcp
      );

      this.filepdf = this.base64ToArrayBuffer(pdf);
    } catch (error) {
      console.error(error);
      this.showPdfLoading = false;

      throw error;
    }
  }

  // Convierte el archivo
  base64ToArrayBuffer(base64) {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }

    return bytes.buffer;
  }

  // Redirige la vista del destalle del consecutivo que se desea modificar
  viewConsecutivo(talon) {
    let consCcp: any;
    if (talon.complementocpConsecutivo) {
      consCcp = talon.complementocpConsecutivo.length;
    } else {
      consCcp = 0;
    }

    let officeCCP: string = "";
    if (consCcp === 0 || consCcp === 1) {
      officeCCP = talon.idoficina;
    } else {
      officeCCP = talon.complementocpConsecutivo[consCcp - 2].idoficinadestino;
    }
    this.router.navigate([
      "/carta-porte/consecutivo/" + talon.claTalon + "/" + officeCCP
    ]);
  }

  // Función generica para mostrar los mensajes con las notificaciones de los resultados de las acciones
  openSnackBar(message: string, action: string, tiempo: number): void {
    this.snackBar.open(message, action, {
      duration: tiempo
    });
  }

  /**
   * statusTimbrado: Detecta el estatus del talon tomando validaciones sobre tipo de talon,
   * documento del SAT, Consecutivos, CFDI.
   *
   * @void
   * @author Moises Lopez Arrona [moisesarrona]
   * @since 2021-11-30
   */
  statusTimbrado() {
    // Valida que tenga CFDI
    if (this.talon.cfdi) {
      // Valida que el documento se de factura (iddocumentosat)
      if (this.talon.cfdi.idtipo === 13 && this.talon.tr.iddocumentosat === 1) {
        // Valida que el talon este timbrado con XML
        if (
          this.talon.cfdi.estatus === 2 &&
          this.talon.documentoCfdi.length > 0
        ) {
          this.status = this.talonStatus.TIMBRADO; // 'Talón timbrado';

          // Valida que el talon este timbrado sin XML
        } else if (
          this.talon.cfdi.estatus === 2 &&
          this.talon.documentoCfdi.length === 0
        ) {
          this.status = this.talonStatus.SINXML;

          // Valida que el talon no este timbrado con XML
        } else if (
          this.talon.cfdi.estatus !== 2 &&
          this.talon.documentoCfdi.length > 0
        ) {
          this.status = this.talonStatus.TIMBRADO; //.NOCONTEMPLADO;

          // Valida que el talon no este timbrado
        } else if (
          this.talon.cfdi.estatus !== 2 &&
          this.talon.documentoCfdi.length === 0
        ) {
          this.status = this.talonStatus.NOTIMBRADO;
        }

        // Valida que el talon no sea apto para timbrar
      } else if (
        this.talon.cfdi.idtipo === 13 &&
        this.talon.tr.iddocumentosat === 3
      ) {
        this.status = this.talonStatus.NOAPTO;

        // Valida que sean aptos para timbrar
      } else if (
        (this.talon.tr.iddocumentosat === 1 ||
          this.talon.tr.iddocumentosat === 3) &&
        (this.talon.cfdi.idtipo === 26 || this.talon.cfdi.idtipo === 27)
      ) {
        // Valida que tenga consecutivos
        if (this.talon.complementocpConsecutivo.length > 0) {
          // Solo toma el último consecutivo
          const consecutivo = this.talon.complementocpConsecutivo.at(-1);
          let xmlEncontrado;
          let porCancelarEncontrado;

          xmlEncontrado = this.talon.complementocpXml.find(
            (xml) => xml.consCcp === consecutivo.consCcp
          );
          porCancelarEncontrado = this.talon.complementocpPorCancelar.find(
            (cancelar) => cancelar.consCcp === consecutivo.consCcp
          );

          if (xmlEncontrado) {
            this.status = this.talonStatus.CONSTIMBRADO;
          } else {
            this.status = this.talonStatus.CONSNOTIMBRADO;
          }
          if (porCancelarEncontrado) {
            this.status = this.talonStatus.CONSCANCELADO;
          }

          // Valida si esta timbrado el consecutivo
          if (this.talon.complementocpXml.length > 0) {
            this.talon.complementocpXml.forEach((xml) => {
              // Verifica si el consecutivo esta timbrado
              if (consecutivo.consCcp === xml.consCcp) {
                this.status = this.talonStatus.CONSTIMBRADO;
              }
            });

            // Valida si esta cancelado el consecutivo
          } else if (this.talon.complementocpPorCancelar.length > 0) {
            this.talon.complementocpPorCancelar.forEach((cancelar) => {
              // Verifica si el consecutivo esta cancelado
              if (consecutivo.consCcp === cancelar.consCcp) {
                this.status = this.talonStatus.CONSCANCELADO;
              }
            });
          }
        } else {
          this.status = this.talonStatus.ERROR;
        }
      } else {
        this.status = this.talonStatus.NOCONTEMPLADO;
      }

      // Valida que no tenga CFDI
    } else if (this.talon.documentoCfdi.length > 0) {
      this.status = this.talonStatus.TIMBRADO;
    } else {
      this.status = this.talonStatus.NOTIMBRADO;
    }
  }

  /**
   * consecutivoValidator: Valida los consecutivo y asigna sus XML y Cancelados a un @Objecto
   * y asigna reglas de botones para la vista.
   *
   * @void
   * @author Moises Lopez Arrona [moisesarrona]
   * @since 2022-03-30
   */
  consecutivoValidator() {
    if (this.talon.complementocpConsecutivo) {
      this.talon.complementocpConsecutivo.forEach((cons) => {
        let consTemp: any = {};
        consTemp.consecutivo = cons;
        if (this.talon.complementocpXml) {
          let xml = this.talon.complementocpXml.find(
            (xml) => xml.consCcp == cons.consCcp
          );
          xml == undefined
            ? (consTemp.xml = xml)
            : (consTemp.xml = this.xmlStatus(xml));
          if (consTemp.xml)
            consTemp.estatusXML = "El consecutivo del talón esta timbrado";
        }
        if (this.talon.complementocpPorCancelar) {
          let cancelar = this.talon.complementocpPorCancelar.find(
            (cancelar) => cancelar.consCcp == cons.consCcp
          );
          cancelar == undefined
            ? (consTemp.cancelar = cancelar)
            : (consTemp.cancelar = this.canceladoStatus(cancelar));
          if (consTemp.cancelar) {
            consTemp.estatusCancelado =
              "El consecutivo del talón se intento cancelar";
          }
        }
        consTemp.buttonError = this.buttonErrorValidator(
          this.talon.complementocpConsecutivo.length,
          cons.consCcp
        );
        consTemp.buttonCons = this.buttonConsValidator(
          this.talon.complementocpConsecutivo.length,
          cons.consCcp,
          consTemp.cancelar
        );
        consTemp.buttonSat = this.buttonSatValidator(
          consTemp.xml,
          consTemp.cancelar
        );
        this.complementocpConsecutivo.push(consTemp);
      });
    } else {
      this.status == this.talonStatus.NOTIMBRADO ||
      this.status == this.talonStatus.SINXML
        ? (this.talon.buttonError = true)
        : (this.talon.buttonError = false);
    }
  }

  /**
   * xmlStatus: Cambia de @int a @string los tipodocumentos.
   *
   * @return
   * @author Moises Lopez Arrona [moisesarrona]
   * @since 2022-03-30
   */
  xmlStatus(xml: any) {
    switch (xml.tipodocumento) {
      case 1:
        xml.tipodocumento = "Factura";
        break;
      case 3:
        xml.tipodocumento = "Traslado";
        break;
      default:
        xml.tipodocumento = "Tipo no reconocido";
    }
    return xml;
  }

  /**
   * canceladoStatus: Cambia de @int a @string los estatus de los cancelados.
   *
   * @return
   * @author Moises Lopez Arrona [moisesarrona]
   * @since 2022-03-30
   */
  canceladoStatus(cancelar: any) {
    switch (cancelar.estatus) {
      case 0:
        cancelar.estatus = "Error al cancelar";
        break;
      case 1:
        cancelar.estatus = "Cancelado pendiente";
        break;
      case 2:
        cancelar.estatus = "Se canceló";
        break;
      default:
        cancelar.estatus = "Estatus no reconocido";
    }
    return cancelar;
  }

  /**
   * buttonErrorValidator: Valida la regla de mostrar el botton de editar errores.
   *
   * @return
   * @author Moises Lopez Arrona [moisesarrona]
   * @since 2022-03-30
   */
  buttonErrorValidator(lengthCons: number, consCcp: number) {
    if (
      lengthCons == consCcp &&
      this.status === this.talonStatus.CONSNOTIMBRADO
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * buttonConsValidator: Valida la regla de mostrar el botton de editar consecutivo.
   *
   * @return
   * @author Moises Lopez Arrona [moisesarrona]
   * @since 2022-03-30
   */
  buttonConsValidator(lengthCons: number, consCcp: number, cancelar: any) {
    if (lengthCons == consCcp && !cancelar) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * buttonSatValidator: Valida la regla de mostrar el botton de mostrar estatus del SAT.
   *
   * @return
   * @author Moises Lopez Arrona [moisesarrona]
   * @since 2022-03-30
   */
  buttonSatValidator(xml: any, cancelar: any) {
    if (xml || cancelar) {
      return true;
    } else {
      return false;
    }
  }
}
