import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfiguracionCorteOficina } from '../../../interfaces/nomina-estibadores/configuracion-corte-oficina';
import { ConceptoEspecialEstibador } from '../../../interfaces/nomina-estibadores/concepto-especial-estibador';
import { EstibadoresPorConfiguracion } from '../../../interfaces/nomina-estibadores/estibadores-por-configuracion.interface';

@Component({
  selector: 'app-configuracion-corte-oficina-dialog',
  templateUrl: './configuracion-corte-oficina-dialog.component.html',
  styleUrls: ['./configuracion-corte-oficina-dialog.component.css']
})
export class ConfiguracionCorteOficinaDialogComponent {

  public arrConfiguracionCorteOficina: ConfiguracionCorteOficina[] = [];
  public arrEstibadoresPorConfiguracion: EstibadoresPorConfiguracion[] = [];

  constructor(public dialogRef: MatDialogRef<ConfiguracionCorteOficinaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { arrConfiguracionCorteOficina: ConfiguracionCorteOficina[], arrEstibadoresPorConfiguracion: EstibadoresPorConfiguracion[] }
  ) {
    this.arrConfiguracionCorteOficina = data.arrConfiguracionCorteOficina;
    this.arrEstibadoresPorConfiguracion = data.arrEstibadoresPorConfiguracion;

    this.arrConfiguracionCorteOficina.forEach(configuracionCorteOficina => {
      configuracionCorteOficina.lstConceptoEspecialOficina = configuracionCorteOficina.lstConceptoEspecialOficina.filter((conceptoEspecialOficina) => conceptoEspecialOficina.estaActivo == 1);
    });
  }

  cerrarDialogo(): void {
    this.dialogRef.close();
  }

  numeroEstibadores(idConfiguracionCorte: number): number {
    return this.arrEstibadoresPorConfiguracion.find(index => index.idConfiguracionCorte == idConfiguracionCorte).numeroEstibadoresOficina;
  }

  mostrarEstibadores(lstConceptoEspecialEstibador: ConceptoEspecialEstibador[]): String {
    let cadenaEstibadores = "";
    lstConceptoEspecialEstibador.forEach(conceptoEspecialEstibador => {
      cadenaEstibadores += cadenaEstibadores.length > 0 ? ", " + conceptoEspecialEstibador.personalEstibador.nombreCompleto : conceptoEspecialEstibador.personalEstibador.nombreCompleto;
    });
    return cadenaEstibadores;
  }

  showTabName(configuracionCorteOficina: ConfiguracionCorteOficina): string {
    let tabName: string = "";
    switch (configuracionCorteOficina.tipoOrden.idTipoOrden) {
      case 1: //1.- GENERAL
        tabName = configuracionCorteOficina.clasificacionCorteOficina.nombreClasificacion;
        break;
      case 2: //2.- CUADRILLA
        tabName = configuracionCorteOficina.cuadrilla.nombre;
        break;
      default: //3.- GUIA, 4.- AYUDANTE
        tabName = configuracionCorteOficina.tipoOrden.nombre;
        break;
    }
    return tabName.toUpperCase();
  }
}
