import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSelect } from '@angular/material';
import { ReplaySubject, Subject } from 'rxjs';
import { Oficina } from '../../../interfaces/oficina';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../authentication/login/auth.service';
import { NominaEstibadoresService } from '../../../services/nomina-estibadores.service';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-dialog-oficina-estibador-nuevo',
  templateUrl: './dialog-oficina-estibador-nuevo.component.html',
  styleUrls: ['./dialog-oficina-estibador-nuevo.component.css']
})
export class DialogOficinaEstibadorNuevoComponent {
  public isLoading: boolean = false;

  oficinaEstibadorNuevoFormGroup: FormGroup;
  private oficinas: Oficina[];
  public oficinasFiltradas: ReplaySubject<Oficina[]> = new ReplaySubject<Oficina[]>(1);
  protected _onDestroy = new Subject<void>();

  public esOficinaConAgencias: boolean = false;
  private arrOficinasAgencias: Oficina[] = [];
  private idOficina: string = "";

  @ViewChild('singleSelect', { static: false }) singleSelect: MatSelect;

  constructor(public dialogRef: MatDialogRef<DialogOficinaEstibadorNuevoComponent>,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private nominaEstibadoresService: NominaEstibadoresService,
    @Inject(MAT_DIALOG_DATA) public data: { idOficina: string, esOficinaConAgencias: boolean, arrOficinasAgencias: Oficina[] }) {

    this.oficinaEstibadorNuevoFormGroup = this.formBuilder.group({
      tipoControl: [null],
      oficinaControl: ['', Validators.required],
      oficinaFiltroControl: ''
    });

    this.oficinaEstibadorNuevoFormGroup.controls['tipoControl'].setValue("1");

    this.esOficinaConAgencias = data.esOficinaConAgencias;
    this.arrOficinasAgencias = data.arrOficinasAgencias;
    this.idOficina = data.idOficina;
    this.esOficinaConAgencias ? this.cargaOficinasAgencias() : this.cargaOficinas();
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  protected setInitialValue() {
    if (!this.esOficinaConAgencias) {
      this.oficinasFiltradas
        .pipe(take(1), takeUntil(this._onDestroy))
        .subscribe(() => {
          this.singleSelect.compareWith = (a: Oficina, b: Oficina) => a && b && a.clave === b.clave;
        });
    }
  }

  protected filtrarOficinas() {
    if (this.oficinas) {
      let filtro = this.oficinaEstibadorNuevoFormGroup.controls['oficinaFiltroControl'].value;
      (filtro && (filtro = filtro.toLowerCase()))
        ? this.oficinasFiltradas.next(this.oficinas.filter(oficina => oficina.plaza.toLowerCase().indexOf(filtro) > -1 || oficina.clave.toLowerCase().indexOf(filtro) > -1))
        : this.oficinasFiltradas.next(this.oficinas.slice());
    }
  }

  /* Carga Todas las Oficinas */
  cargaOficinas(): void {
    this.isLoading = true;
    this.nominaEstibadoresService.obtenerOficinasParaCorte().subscribe(
      response => {
        this.oficinas = response as Oficina[];

        this.oficinaEstibadorNuevoFormGroup.controls['oficinaControl'].setValue(this.oficinas[this.oficinas.findIndex(oficina => oficina.clave == this.authService.usuario.claveOficina)]);

        this.oficinasFiltradas.next(this.oficinas.slice());

        this.oficinaEstibadorNuevoFormGroup.controls['oficinaFiltroControl'].valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filtrarOficinas();
          });

        this.isLoading = false;
      });
  }

  /* Carga Todas las Oficinas Agencias pertenecientes a la Matriz */
  cargaOficinasAgencias(): void {
    this.oficinas = this.arrOficinasAgencias;

    this.oficinasFiltradas.next(this.oficinas.slice());

    this.oficinaEstibadorNuevoFormGroup.controls['oficinaFiltroControl'].valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filtrarOficinas();
      });
  }

  cerrarDialogo(): void {
    const array = {
      calcular: false
    };
    this.dialogRef.close(array);
  }

  continuar() {
    const array = {
      continuar: true,
      idOficina: (this.esOficinaConAgencias && this.oficinaEstibadorNuevoFormGroup.controls['tipoControl'].value == 1) ? this.idOficina : this.oficinaEstibadorNuevoFormGroup.controls['oficinaControl'].value.oficina
    };
    this.dialogRef.close(array);
  }

}
