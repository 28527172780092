import { NgModule } from '@angular/core';

import { MenuItems } from './menu-items/menu-items';
import {
  AccordionAnchorDirective,
  AccordionLinkDirective,
  AccordionDirective
} from './accordion';
import { CustomPdfViewerComponent } from './custom-pdf-viewer/custom-pdf-viewer.component';
import { DemoMaterialModule } from '../base-material-module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CommonModule } from '@angular/common';
import { HasPermissionDirectiveDirective } from './directives/has-permission-directive.directive';
import { TripAccordionComponent } from './components/trip-accordion/trip-accordion.component';
import { GuidesAccordionComponent } from './components/guides-accordion/guides-accordion.component';
import { PdfDialogComponent } from './modals/pdf-dialog/pdf-dialog.component';
import { CorrecionTimbradoComponent } from './modals/correcion-timbrado/correcion-timbrado.component';
import { FormsModule, NgModel, ReactiveFormsModule } from '@angular/forms';
import { TalonesListComponent } from './components/talones-list/talones-list.component';
import { SimplePaginatorComponent } from './components/simple-paginator/simple-paginator.component';
import { GuideTalonesListComponent } from './components/guide-talones-list/guide-talones-list.component';
import { TalonesItemComponent } from './components/talones-item/talones-item.component';
import { ConfirmationDialogComponent } from './modals/confirmation-dialog/confirmation-dialog.component';
import { CorreccionTimbradoLogComponent } from './modals/correccion-timbrado-log/correccion-timbrado-log.component';
import { MatAutocompleteModule, MatBottomSheetModule, MatExpansionModule } from '@angular/material';
import { TalonesLogItemComponent } from './components/talones-log-item/talones-log-item.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { CorreccionTimbradoTipoComponent } from './modals/correccion-timbrado-tipo/correccion-timbrado-tipo.component';
import { EstatusSatConsecutivoComponent } from './modals/estatus-sat-consecutivo/estatus-sat-consecutivo.component';
import { BaseComponent } from './helpers/base.component';
import { ConfiguracionCorteOficinaDialogComponent } from './modals/configuracion-corte-oficina-dialog/configuracion-corte-oficina-dialog.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ValesNominaDialogComponent } from './modals/vales-nomina-dialog/vales-nomina-dialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ConceptoEspecialOficinaDialogComponent } from './modals/concepto-especial-corte-dialog/concepto-especial-oficina-dialog.component';
import { DialogEstatusAutorizacionCorteComponent } from './modals/dialog-estatus-autorizacion-corte/dialog-estatus-autorizacion-corte.component';
import { DialogPeriodoCorteComponent } from './modals/dialog-periodo-corte/dialog-periodo-corte.component';
import { RouterModule } from '@angular/router';
import { DialogAutorizacionFaltasCorteComponent } from './modals/dialog-autorizacion-faltas-corte/dialog-autorizacion-faltas-corte.component';
import { AgregarMaterialEyeComponent } from './modals/agregar-material-eye/agregar-material-eye.component';
import { AlertaSolicitudEyeComponent } from './modals/alerta-solicitud-eye/alerta-solicitud-eye.component';
import { SeguimientoSolicitudEyeComponent } from './modals/seguimiento-solicitud-eye/seguimiento-solicitud-eye.component';
import {SeguimientoSolicitudSapEyeComponent} from './modals/seguimiento-solicitud-sap-eye/seguimiento-solicitud-sap-eye.component';
import { HeaderPaqueteriaComponent } from './components/header-paqueteria/header-paqueteria.component';
import { ConfirmarDatosSolicitudComponent } from './modals/confirmar-datos-solicitud/confirmar-datos-solicitud.component';
import { VerEditarSolicitudComponent } from './modals/ver-editar-solicitud/ver-editar-solicitud.component';
import { AgregarEntradaComponent } from './modals/agregar-entrada/agregar-entrada.component';
import { VerDatosTalonComponent } from './modals/ver-datos-talon/ver-datos-talon.component';
import { TalonesMaterialIndComponent } from './modals/talones-material-ind/talones-material-ind.component';
import { DialogDesgloseDiarioCorteComponent } from './modals/dialog-desglose-diario-corte/dialog-desglose-diario-corte.component';
import { DialogDescuentoCorteComponent } from './modals/dialog-descuento-corte/dialog-descuento-corte.component';
import { DialogEstibadorNuevoComponent } from './modals/dialog-estibador-nuevo/dialog-estibador-nuevo.component';
import { DialogCorteEstibadorNuevoComponent } from './modals/dialog-corte-estibador-nuevo/dialog-corte-estibador-nuevo.component';
import { DialogSueldoEstibadorNuevoComponent } from './modals/dialog-sueldo-estibador-nuevo/dialog-sueldo-estibador-nuevo.component';
import { DialogOficinaEstibadorNuevoComponent } from './modals/dialog-oficina-estibador-nuevo/dialog-oficina-estibador-nuevo.component';
import { DialogEstibadorOrdenComponent } from './modals/dialog-estibador-orden/dialog-estibador-orden.component';
import { ProgressSpinnerComponent } from './progress-spinner.component';
import { DialogIncentivoCorteComponent } from './modals/dialog-incentivo-corte/dialog-incentivo-corte.component';
import { SafeCurrencyPipe } from './helpers/safe-currency.pipe';

@NgModule({
  declarations: [
    CustomPdfViewerComponent,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    HasPermissionDirectiveDirective,
    TripAccordionComponent,
    GuidesAccordionComponent,
    PdfDialogComponent,
    CorrecionTimbradoComponent,
    TalonesListComponent,
    SimplePaginatorComponent,
    GuideTalonesListComponent,
    TalonesItemComponent,
    ConfirmationDialogComponent,
    CorreccionTimbradoLogComponent,
    TalonesLogItemComponent,
    CorreccionTimbradoTipoComponent,
    EstatusSatConsecutivoComponent,
    BaseComponent,
    ConfiguracionCorteOficinaDialogComponent,
    ValesNominaDialogComponent,
    ConceptoEspecialOficinaDialogComponent,
    DialogEstatusAutorizacionCorteComponent,
    DialogPeriodoCorteComponent,
    DialogAutorizacionFaltasCorteComponent,
    AgregarMaterialEyeComponent,
    AlertaSolicitudEyeComponent,
    SeguimientoSolicitudEyeComponent,
    SeguimientoSolicitudSapEyeComponent,
    HeaderPaqueteriaComponent,
    ConfirmarDatosSolicitudComponent,
    VerEditarSolicitudComponent,
    AgregarEntradaComponent,
    VerDatosTalonComponent,
    TalonesMaterialIndComponent,
    DialogDesgloseDiarioCorteComponent,
    DialogDescuentoCorteComponent,
    DialogEstibadorNuevoComponent,
    DialogCorteEstibadorNuevoComponent,
    DialogSueldoEstibadorNuevoComponent,
    DialogOficinaEstibadorNuevoComponent,
    DialogEstibadorOrdenComponent,
    ProgressSpinnerComponent,
    DialogIncentivoCorteComponent,
    SafeCurrencyPipe
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    HasPermissionDirectiveDirective,
    CustomPdfViewerComponent,
    TripAccordionComponent,
    SimplePaginatorComponent,
    GuideTalonesListComponent,
    TalonesListComponent,
    TalonesItemComponent,
    CorreccionTimbradoTipoComponent,
    BaseComponent,
    HeaderPaqueteriaComponent,
    ProgressSpinnerComponent,
    SafeCurrencyPipe
  ],
  imports: [
    DemoMaterialModule,
    PdfViewerModule,
    CommonModule,
    ReactiveFormsModule,
    MatBottomSheetModule,
    FormsModule,
    NgxPaginationModule,
    NgxMatSelectSearchModule,
    FlexLayoutModule,
    RouterModule,
    MatExpansionModule,
    MatAutocompleteModule
  ],
  entryComponents: [
    PdfDialogComponent,
    CorrecionTimbradoComponent,
    ConfirmationDialogComponent,
    CorreccionTimbradoLogComponent,
    CorreccionTimbradoTipoComponent,
    EstatusSatConsecutivoComponent,
    ConfiguracionCorteOficinaDialogComponent,
    ValesNominaDialogComponent,
    ConceptoEspecialOficinaDialogComponent,
    DialogEstatusAutorizacionCorteComponent,
    DialogPeriodoCorteComponent,
    DialogAutorizacionFaltasCorteComponent,
    SeguimientoSolicitudEyeComponent,
    SeguimientoSolicitudSapEyeComponent,
    AgregarMaterialEyeComponent,
    AlertaSolicitudEyeComponent,
    ConfirmarDatosSolicitudComponent,
    VerEditarSolicitudComponent,
    AgregarEntradaComponent,
    VerDatosTalonComponent,
    TalonesMaterialIndComponent,
    DialogDesgloseDiarioCorteComponent,
    DialogDescuentoCorteComponent,
    DialogEstibadorNuevoComponent,
    DialogCorteEstibadorNuevoComponent,
    DialogSueldoEstibadorNuevoComponent,
    DialogOficinaEstibadorNuevoComponent,
    DialogEstibadorOrdenComponent,
    DialogIncentivoCorteComponent,
  ],
  providers: [MenuItems]
})
export class SharedModule { }
