import { Component, OnInit } from '@angular/core';
import { PiePaginaImp } from '../../../interfaces/piepaginaimp';

@Component({
  selector: 'app-layout-footer-print',
  templateUrl: './layout-print-footer.component.html',
  styleUrls: ['./layout-print-footer.component.css']
})

/*
* Título                    : layout-footer-print
* Descripción               : Layout - Footer para imprimir
* Compañía                  : Transportes Castores de Baja California S.A. de C.V. – Área de Desarrollo
* Fecha de creación         : 2020-06-29
* Autor                     : German Galaviz [germagafra]
* Versión                   : 1.0.0
* ID Requerimiento/Ticket   : NULL
*/
export class LayoutPrintFooterComponent implements OnInit {
	dataPiePagina:PiePaginaImp[];
	fechaActual : any;

  	constructor() { }
  	/* Obtiene los datos del pie de pagina para la impresión */
	ngOnInit() {
	  	setTimeout(() => {
	  		this.fechaActual = new Date();
	  		this.dataPiePagina = JSON.parse(sessionStorage.getItem("piepaginaImpresion"));
	  		console.log(this.dataPiePagina);
	  	});
	}
}
