import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Personal } from '../../../interfaces/personal';

@Component({
  selector: 'app-dialog-estatus-autorizacion-corte',
  templateUrl: './dialog-estatus-autorizacion-corte.component.html',
  styleUrls: ['./dialog-estatus-autorizacion-corte.component.css']
})
export class DialogEstatusAutorizacionCorteComponent {

  personalAutoriza: Personal;
  estatusAutorizacion: number;
  fechaAutoriza: string;
  comentarioAutoriza: string;

  constructor(public dialogRef: MatDialogRef<DialogEstatusAutorizacionCorteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { personalAutoriza: Personal, estatusAutorizacion: number, fechaAutoriza: string, comentarioAutoriza: string }) {
    this.personalAutoriza = data.personalAutoriza;
    this.estatusAutorizacion = data.estatusAutorizacion;
    this.fechaAutoriza = data.fechaAutoriza;
    this.comentarioAutoriza = this.estatusAutorizacion == 1 ? "Corte en proceso de validación, cualquier duda comunicarse con el departamento de Maniobras." : data.comentarioAutoriza;
  }

  cerrarDialogo(): void {
    this.dialogRef.close();
  }

  getDateTime(date: string): string {
    let fecha: Date = new Date(date);
    return fecha.toLocaleString('es-MX',
      {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      }
    );
  }
}
