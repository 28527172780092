import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import Swal from "sweetalert2";
import { PDFComplemento } from "../../../interfaces/PDFComplemento";
import { ComplementoCartaPorteService } from "../../../services/complemento-carta-porte.service";

@Component({
  selector: "app-pdf-dialog",
  templateUrl: "./pdf-dialog.component.html",
  styleUrls: ["./pdf-dialog.component.css"],
})
export class PdfDialogComponent implements OnInit {
  //Formulario para ewl envío de WhatsApp
  public showForm = false;
  formSendWhatsApp: FormGroup = this.fb.group({
    numberSend: [
      "",
      [Validators.required, Validators.maxLength(10), Validators.minLength(10)],
    ],
  });

  constructor(
    public dialogRef: MatDialogRef<PdfDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private complementoService: ComplementoCartaPorteService
  ) {}

  ngOnInit() {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  goBack() {
    this.onNoClick();
  }

  // Envio de PDF por WhatsApp
  showFormSend() {
    if (this.showForm == false) {
      this.showForm = true;
    } else {
      this.showForm = false;
    }
  }

  // Envía el PDF por WhatsApp
  sendPDF(data: any) {
    this.formSendWhatsApp.markAllAsTouched;

    if (this.formSendWhatsApp.valid) {
      //Mapeo los datos con el modelos

      const pdfComplemento: PDFComplemento = {
        session: this.formSendWhatsApp.get("numberSend").value,
        folio: this.data.trip.idTrip,
        route: this.data.trip.fileRoute,
      };
      //Envía los datos al servicio
      this.complementoService.receivePDFWhatsApp(pdfComplemento).subscribe(
        (res) => {
          // Alerta para respuesta satisfactoria
          Swal.fire({
            icon: "success",
            title: "Se envió el PDF",
            text:
              "El PDF ha sido enviado al número: " +
              pdfComplemento.session +
              " revisalo por favor",
            showConfirmButton: true,
          });
        },
        (error) => {
          Swal.fire({
            icon: "error",
            title: "No se envió el PDF",
            text:
              "El PDF no se ha podido enviar, intentalo mas tarde los detalles son: " +
              error.message,
            showConfirmButton: true,
          });
        }
      );

      //Cierra el formulario
      this.showFormSend();
      this.formSendWhatsApp.reset();
    } else {
      console.log("No has ingresado nada");
    }
  }

  // Solo permite numeros
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  // Mensajes de validaciones
  getErrorForm() {
    return this.formSendWhatsApp.get("numberSend").hasError("minlength")
      ? "El número debe de ser igual a 10 digitos"
      : this.formSendWhatsApp.get("numberSend").hasError("maxlength")
      ? "El número debe de ser igual a 10 digitos"
      : this.formSendWhatsApp.get("numberSend").hasError("required")
      ? "El número es requerido"
      : "";
  }
}
