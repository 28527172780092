import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
//SERVICE 
import { TalonesService } from '../../../services/talones.service';

@Component({
  selector: 'app-estatus-sat-consecutivo',
  templateUrl: './estatus-sat-consecutivo.component.html',
  styleUrls: ['./estatus-sat-consecutivo.component.css']
})
export class EstatusSatConsecutivoComponent implements OnInit {

  cargandoVisible: boolean = true;
  estatusSat: Object;
  errorMessage: String = null;

  constructor(
    private talonesService: TalonesService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

  ngOnInit() {
    this.findStatusSatByTalon()
  }

  async findStatusSatByTalon() {
    let messageObject: String = null;
    let subscribe = this.talonesService.findStatusSatByTalon(this.data.claTalon, this.data.consCcp).subscribe(
      next => {
        this.estatusSat = next;
      },
      error => {
        messageObject = error.error.message
        this.openSnackBar('Problemas obtener el estatus intentalo más tarde.', '⛔', 4000);
      }).add(() => {
        if (messageObject)
          this.errorMessage = messageObject
        this.cargandoVisible = false;
        subscribe.unsubscribe()
      });
  }

    // Función generica para mostrar los mensajes con las notificaciones de los resultados de las acciones
    openSnackBar(message: string, action: string, tiempo: number): void {
      this.snackBar.open(message, action, {
        duration: tiempo
      });
    }
}
