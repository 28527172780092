/*
* Título                    : puesto.ts
* Descripción               : Estructura de puesto de acuerdo a la documentación
* Compañía                  : Transportes Castores de Baja California S.A. de C.V. – Área de Desarrollo
* Fecha de creación         : 2020-03-06
* Autor                     : Paulina Muñoz [Lavande94]
* Versión                   : 1.0.0
* ID Requerimiento/Ticket   : NULL
*/
import { Departamento } from './departamento';
import { Producto } from './producto';
export class Puesto {
  idPuesto: number;
  nombre: string;
  idEstatusPuesto: number;
  departamento: Departamento;
  productoAutorizadoPuestos: Producto;
}
