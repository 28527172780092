import { Component, OnInit, Inject, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatAutocompleteSelectedEvent } from '@angular/material';
import { FormControl } from '@angular/forms';
import { Oficina } from '../../../interfaces/oficina';
import { OficinaActual } from '../../../interfaces/oficina-actual';
import { AgenciaService } from '../../../services/agencia.service';
import { AuthService } from '../../../authentication/login/auth.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PolizaPagadosService } from '../../../services/poliza-pagados.service';
import { MatSnackBar } from '@angular/material';
import { MatDialog } from "@angular/material";
import { DOCUMENT, DatePipe  } from '@angular/common';
import { PolizaPagados } from '../../../interfaces/poliza-pagados';
import { analyzeAndValidateNgModules } from '@angular/compiler';
//import {PolizaPagadosComponent } from '../../../Systems/contabilidad/poliza-pagados/poliza-pagados.component';



@Component({
  selector: 'dialog-cambio-agencia',
  templateUrl: './dialog-cambio-agencia.component.html',
  styleUrls: ['./dialog-cambio-agencia.component.css']
})

/*
* Título                    : cambio-agencia
* Descripción               : Modal para cambio de agencia
* Compañía                  : Transportes Castores de Baja California S.A. de C.V. – Área de Desarrollo
* Fecha de creación         : 2020-10-12
* Autor                     : Pablo Velazquez Martinez
* Versión                   : 1.0.0
* ID Requerimiento/Ticket   : NULL
*/
export class DialogCambioAgenciaComponent implements OnInit, AfterContentChecked {
  private isDisabled: boolean;
  private dataOficina: any;
  private oficinaVirtualPlaza: any;
  private idAgenciaVirtualPlaza: any;
  private oficinaVirtualDatos: OficinaActual;
  private claveAgencia : string;
  statusError = 0;
  private oficinaControl = new FormControl();
  private filteredOptions: Observable<Oficina[]>;
  private datosOficinaSel: Oficina[];
  spinner: number = 0;
  private oficinaActualCombinada: any;
  ffInicio: any;
  ffFin: any;
  private idOficina: any;
  private agencia: any;
  private plazaOficina: any;
  private idUsuario: any;
  private cargandoVisible: any = false;
  //private polizaPagadosComponent: PolizaPagadosComponent;
  /* Inicialización de variables del constructor */
  constructor(public dialogRef: MatDialogRef<DialogCambioAgenciaComponent>,
    private agenciaService: AgenciaService,
    private authService: AuthService,
    private changeDetector: ChangeDetectorRef,
    private polizaPagados: PolizaPagadosService,
    public snackBar: MatSnackBar,    
    @Inject(MAT_DIALOG_DATA) public data) { }
  /* Inicializa las variables para comprobar la oficina actual y origen en caso de ser corporativo
     y manda llamar el cargado de oficinas */
  ngOnInit() {    

    this.authService.oficinaActual.plaza = '';
    if (this.authService.oficinaActual != undefined &&
      this.authService.oficinaActual.idOficina != undefined &&
      this.authService.oficinaActual.idOficina != null &&
      this.authService.oficinaActual.idOficina != 1 &&
      !isNaN(this.authService.oficinaActual.idOficina)) {
      //this.oficinaActualCombinada = " -> " + this.authService.oficinaActual.plaza;
      this.idOficina = (this.authService.usuario.idOficina == undefined ||
                        this.authService.usuario.idOficina == null)?
                        "" : this.authService.usuario.idOficina;
      this.plazaOficina = (this.authService.usuario.plazaOficina == undefined ||
                        this.authService.usuario.plazaOficina == null)?
                        "" : this.authService.usuario.plazaOficina;
      this.idUsuario = (this.authService.usuario.id == undefined ||
                        this.authService.usuario.id == null)?
                       "" : this.authService.usuario.id;
    }
    this.cargaOficinas();
  }
  /* Aplica los cambios ante la detección de cambios */
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
  /* Carga Todas las Oficinas */
  cargaOficinas(): void {
    this.spinner = 1;
    this.dataOficina = [];
    this.agenciaService.findOficinaAgenciaByIdOficina(this.data[0].idOficina).subscribe(
      response => {
        this.statusError = 1;
        this.dataOficina = response;
        this.filteredOptions = this.oficinaControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this._filter(value))
          );
        this.statusError = 0;
        this.spinner = 0;
      },
      error => {
        this.statusError = 1;
        this.spinner = 0;

      })
  }
  /* Comprueba que el valor seleccionado sea valido */
  compruebaDatos(valor): void {
    if (valor == '' || valor == undefined || valor != null) {
      this.datosOficinaSel = [];
    }
  }
  /* Ante un cambio de oficina, prepara los datos que seran enviados, correspondientes a la selección */

  onSelectionChanged(event: MatAutocompleteSelectedEvent): void {
    //setTimeout(() => {
      this.idAgenciaVirtualPlaza = 0;
      this.datosOficinaSel = event.option.value;
      this.oficinaVirtualPlaza = event.option.value.plaza;
      this.oficinaVirtualDatos = event.option.value;
      this.idAgenciaVirtualPlaza = event.option.value.idOficina;
    this.claveAgencia = event.option.value.clave;
    //});

  }
  /* Realiza el filtro ingresado por el Usuario */
  private _filter(nombre: string): Oficina[] {

    const filterValue = nombre.toString().toLowerCase();

    return this.dataOficina.filter(option => option.plaza.toLowerCase().includes(filterValue));
  }
  /* Genera el cierre del modal */
  cerrarDialogo(): void {
    let array = {
      'respuesta': false,
    }
    this.dialogRef.close(array);
  }
  /* Confirma la agencia seleccionada y envia los datos seleccionados */
  confirmado(): void {
    setTimeout(() => {
      this.authService.guardarOficinaActual(this.oficinaVirtualDatos);
      sessionStorage.removeItem('historiaPolizaPagados');
      let array = {
        'respuesta': true,
        'datos': this.oficinaVirtualDatos
      }
      this.dialogRef.close(array);
    })
  }

  /* Genera la contabilizacion de los talones de la oficina asignada */
  generarPolizasManual(): void
  {
    let datePipe = new DatePipe('es-Mx');
    if (this.validaInformacion(this.ffInicio) && this.validaInformacion(this.ffFin) && this.validaInformacion(this.idUsuario)) {
      this.spinner = 1;
      this.isDisabled = true;
      let ffInicio = datePipe.transform(this.ffInicio, 'yyyy-MM-dd');
      let ffFin = datePipe.transform(this.ffFin, 'yyyy-MM-dd');
      if (this.claveAgencia=='undefined'){//si no se he seleccionado ninguna agencia, lo deja en vacio
        this.claveAgencia = "''";
     }
                                                                                    //this.idAgenciaVirtualPlaza
      this.polizaPagados.procesaPolizaTalonByIdOficinaManual(this.data[0].idOficina, this.claveAgencia, this.idUsuario, ffInicio, ffFin).subscribe(
        //this.polizaPagados.procesaPolizaTalonByIdOficinaManual(100,'7',5728,'2021-10-01','2021-10-05').subscribe(
        response => {
          /** Status
          * 1 = Las polizas se contabilizaron con exito u Ya se contabilizaron las Talones
          * 2 = Parametros Inactivos, no se contabilizaron Talones
          * 3 = No tiene autorizado el día
          * 4 = Las polizas ya fueron contabilizadas
          * 5 = Error de Servidor
          * 6 = Contabilización Parcial
          */
          this.spinner = 0;
          this.isDisabled = false;
          if (response.status == "1") {
            this.openSnackBar(response.mensaje, '🟢', 1000);
            setTimeout(() => {
              this.cerrarDialogo();
            }, 3000);
          }  else  {
            this.openSnackBar(response.mensaje, '⛔', 3000);
            setTimeout(() => {
              this.cerrarDialogo();
            }, 3000);
          }
        },
        error => {
          this.spinner = 0;
          this.isDisabled = false;
          this.openSnackBar("Ocurrio un error del lado del servidor, Intente Nuevamente", '⛔', 3000);
        });

    }
    else {
      this.openSnackBar('Debes ingresar las fechas validas', '⛔', 2000);
    }
  }

   /* Función generica para mostrar los mensajes con las notificaciones de los resultados de las acciones*/
  openSnackBar(message: string, action: string, tiempo: number): void
  {
    this.snackBar.open(message, action, {
      duration: tiempo
    });
  }

  /* Función Generica que valida que la variable contenga informacion correcta*/
  validaInformacion(dato: any): boolean {
    if (dato != undefined && dato != null && dato != '' && dato != "Invalid Date") {
      return true;
    }
    else {
      return false;
    }
  }

  actionMethod($event: MouseEvent) {
    ($event.target as HTMLButtonElement).disabled = true;
    // Do actions.
  }

}
