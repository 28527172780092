import { Component } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AuthService } from '../../../authentication/login/auth.service';
import { Router } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { ConnectionService } from 'ng-connection-service';
import { AppsettingsComponent } from '../../../app-settings/appsettings.component';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent {
  conexion: string = 'heartbit';
  punto: string = 'point';
  icono: string =  'wifi';
  isConnected = true;
  usuario: string = this.authService.usuario.username;
  departamento: string = this.authService.usuario.departamento;
  constructor(private authService: AuthService, private router: Router,
    public snackBar: MatSnackBar, private ConnectionService: ConnectionService, public AppsettingsComponent: AppsettingsComponent) {
    let audio = new Audio();
    let audio2 = new Audio();
    audio.src = "../../../assets/sounds/beyond-doubt.mp3";
    audio2.src = "../../../assets/sounds/clearly.mp3";
    audio.load(); 
    audio2.load();

    this.ConnectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        audio2.play();
        this.icono= 'wifi';
        this.conexion = 'heartbit';
        this.punto = 'point';

        this.openSnackBar('Señal internet restablecida.', '🌐🙌');
      } else {
        audio.play();
        this.icono= 'wifi_off';
        this.conexion = 'heartbitOut';
        this.punto = 'pointOut';

        this.openSnackBar('Señal internet perdida.', '❗📡');
      }

    });
  }
  public config: PerfectScrollbarConfigInterface = {};

  // alert snack
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000
    });
  }

  //Cerrar sesión :)

  logout(): void {
    this.authService.cerrarSesion();
    this.openSnackBar('Sesión cerrada.', '👋');
    this.router.navigate(['/authentication/login']);
  }


  // This is for Notifications
  notifications: Object[] = [
    {
      round: 'round-danger',
      icon: 'ti-link',
      title: 'Launch Admin',
      subject: 'Just see the my new admin!',
      time: '9:30 AM'
    },
    {
      round: 'round-success',
      icon: 'ti-calendar',
      title: 'Event today',
      subject: 'Just a reminder that you have event',
      time: '9:10 AM'
    },
    {
      round: 'round-info',
      icon: 'ti-settings',
      title: 'Settings',
      subject: 'You can customize this template as you want',
      time: '9:08 AM'
    },
    {
      round: 'round-primary',
      icon: 'ti-user',
      title: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];

  // This is for Mymessages
  mymessages: Object[] = [
    {
      useravatar: 'assets/images/users/b2.png',
      status: 'online',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:30 AM'
    },
    {
      useravatar: 'assets/images/users/b2.png',
      status: 'busy',
      from: 'Sonu Nigam',
      subject: 'I have sung a song! See you at',
      time: '9:10 AM'
    },
    {
      useravatar: 'assets/images/users/b2.png',
      status: 'away',
      from: 'Arijit Sinh',
      subject: 'I am a singer!',
      time: '9:08 AM'
    },
    {
      useravatar: 'assets/images/users/b2.png',
      status: 'offline',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];
}
