import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource} from "@angular/material";
import { EyeService } from "../../../services/eye.service";
import { Talones } from "../../../interfaces/paqueteria/talones";
import { Tr } from "../../../interfaces/talones-oficina/tr";
import { DatosOrigenDestino } from "../../../interfaces/paqueteria/datos-origen-destino";
import { Cuotas } from "../../../interfaces/paqueteria/cuotas";
import { CdOrigenDestino } from "../../../interfaces/paqueteria/cd-origen-destino";
import { TalonesParametros } from "../../../interfaces/paqueteria/talones-parametros";
import { Detatr } from "../../../interfaces/paqueteria/detatr";
import { MetodoPago } from "../../../interfaces/paqueteria/metodopago";
import { CatalogoConceptoImporte } from "../../../interfaces/paqueteria/catalogo-concepto-importe";
import { Co } from "../../../interfaces/paqueteria/co";

@Component({
  selector: "app-ver-datos-talon",
  templateUrl: "./ver-datos-talon.component.html",
  styleUrls: ["./ver-datos-talon.component.css"],
})
export class VerDatosTalonComponent implements OnInit {
  columnasTabla1: string[] = ["cuotaTonelada","cuotaMetros","valorDeclarado","seguro","condicionesPago","tipoTalon"];
  columnasTabla2: string[] = ["cantidad","empaque","contiene", "peso","mts","pesoEst","precio"];
  dataSource = new MatTableDataSource<any>();
  existeTalon: boolean = true;
  datosOrigen: DatosOrigenDestino;
  datosDestino: DatosOrigenDestino;
  cuotas = new MatTableDataSource<Cuotas>([]);
  queContiene = new MatTableDataSource<Co>([]);
  fecha: Date = new Date();
  blnNuevoMetodoPago: boolean = false;
  loading: boolean = false;
  eye : string = "0.00";
  iva  : string= "0.00";
  subtotal : string = "0.00";
  total  : string = "0.00";

  constructor(
    private dialogRef: MatDialogRef<VerDatosTalonComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private eyeService: EyeService
  ) {
    this.datosOrigen = {
      numeroTalon: "",
      ciudad: "",
      remDest: "",
      noCte: "",
      domicilio1: "",
      domicilio2: "",
      recogerEnt: "",
      rfc: "",
      tel: "",
      metPago: "",
      noCuenta: "",
    };

    this.datosDestino = {
      numeroTalon: "",
      ciudad: "",
      remDest: "",
      noCte: "",
      domicilio1: "",
      domicilio2: "",
      recogerEnt: "",
      rfc: "",
      tel: "",
      metPago: "",
      noCuenta: "",
    };
  }

  ngOnInit() : void {
    this.obtenerDatosTalon();
  }

  obtenerDatosTalon() : void {
    this.eyeService
      .getTalonByClaTalon(this.data)
      .subscribe((talon: Talones) => {
        if (talon.claTalon == null) {
          this.loading = true;
          this.existeTalon = false;
          return;
        }

        this.eyeService
          .getTrByClaTalon(talon.tabla.toString(), talon.claTalon.toString())
          .subscribe((tr: Tr) => {
            if (tr.claTalon == null) {
              this.loading = true;
              this.existeTalon = false;
              return;
            }

            this.eyeService
              .getCdOrigenDestino(tr.idCdRec, tr.idCdDes)
              .subscribe((cdOrigenDestino: CdOrigenDestino[]) => {
                cdOrigenDestino.forEach((element : CdOrigenDestino) => {
                  if (element.idCiudad == tr.idCdRec) {
                    this.fecha = new Date(tr.fecha + "T" + tr.hora);
                    this.datosOrigen.ciudad = `${element.ciudad}`;
                  } else if (element.idCiudad == tr.idCdDes) {
                    this.datosDestino.ciudad = element.ciudad;
                  }
                });
              });

            this.datosOrigen.remDest = tr.nomOrigen.toString();
            this.datosOrigen.noCte = `${tr.idRemitente}${tr.idOfIrte}`;
            this.datosOrigen.domicilio1 = `${tr.calleOrigen} ${tr.noIntRte} ${tr.noExtRte} ${tr.coloniaOrigen} ${tr.cpOrigen}`;
            this.datosOrigen.domicilio2 = `${tr.calleRec} ${tr.noIntRec} ${tr.noExtRec} ${tr.colRec} ${tr.cpRec}`;
            this.datosOrigen.recogerEnt = tr.seRecogera.toString();
            this.datosOrigen.rfc = tr.rfcOrigen.toString();
            this.datosOrigen.tel = tr.telOrigen.toString();

            this.datosDestino.remDest = tr.nomDestino.toString();
            this.datosDestino.noCte = `${tr.idDestinatario}${tr.idOfIdest}`;
            this.datosDestino.domicilio1 = `${tr.calleDestino} ${tr.noIntDest} ${tr.noExtDest} ${tr.coloniaDestino} ${tr.cpDestino}`;
            this.datosDestino.domicilio2 = `${tr.calleDes} ${tr.noIntDes} ${tr.noExtDes} ${tr.colDes} ${tr.cpDes}`;
            this.datosDestino.recogerEnt = tr.seEntregara.toString();
            this.datosDestino.rfc = tr.rfcDestino.toString();
            this.datosDestino.tel = tr.telDestino.toString();

            this.eyeService
              .getTalonesParametrosByIdParametro(161)
              .subscribe((parametro: TalonesParametros) => {
                const fechaParametro = new Date(`${parametro.valor}T00:00:00`);
                const fechaTalon = new Date(`${tr.fecha}T${tr.hora}`);

                if (fechaTalon.getTime() > fechaParametro.getTime()) {
                  this.blnNuevoMetodoPago = true;
                }

                if (tr.tipoPago == 1 || tr.tipoPago == 2) {
                  this.eyeService
                    .getDetatrByClaTalon(tr.claTalon.toString())
                    .subscribe((detatr: Detatr) => {
                      if (detatr.metodoPago != null) {
                        if (!this.blnNuevoMetodoPago) {
                          this.eyeService
                            .getMetodoPagoById(detatr.metodoPago)
                            .subscribe((metodoPago: MetodoPago) => {
                              this.datosOrigen.metPago = metodoPago.descripcion;
                            });
                        } else {
                          this.eyeService
                            .getMetodoPagoByClave(detatr.metodoPago)
                            .subscribe((metodoPago: MetodoPago) => {
                              this.datosOrigen.metPago = metodoPago.descripcion;
                            });
                        }

                        this.datosOrigen.noCuenta = detatr.noCuentaPago;
                      }
                    });
                }

                if (tr.tipoPago == 1 || tr.tipoPago == 3) {
                  this.eyeService
                    .getDetatrByClaTalon(tr.claTalon.toString())
                    .subscribe((detatr: Detatr) => {
                      if (detatr.metodoPago != null) {
                        if (!this.blnNuevoMetodoPago) {
                          this.eyeService
                            .getMetodoPagoById(detatr.metodoPago)
                            .subscribe((metodoPago: MetodoPago) => {
                              this.datosDestino.metPago =
                                metodoPago.descripcion;
                            });
                        } else {
                          this.eyeService
                            .getMetodoPagoByClave(detatr.metodoPago)
                            .subscribe((metodoPago: MetodoPago) => {
                              this.datosDestino.metPago =
                                metodoPago.descripcion;
                            });
                        }

                        this.datosDestino.noCuenta = detatr.noCuentaPago;
                      }
                    });
                }
              });

            var condicionPago : string = "";

            if (tr.tipoPago == 1) {
              condicionPago = "Pagado Pagado";
            } else if (tr.tipoPago == 2) {
              condicionPago = "Cobrar Origen";
            } else {
              condicionPago = "Cobrar Destino";
            }

            var tipo : string = tr.nombreTipoDocumento != null ? tr.nombreTipoDocumento : "";
      
            const cuota: Cuotas = {
              cuotaPorTonelada: (
                Math.round(parseInt(tr.cpt.toString()) * 100) / 100
              ).toFixed(2),
              cuotaPorMetros: (
                Math.round(parseInt(tr.cmt.toString()) * 100) / 100
              ).toFixed(2),
              valorDeclarado: (
                Math.round(parseInt(tr.valDecl.toString()) * 100) / 100
              ).toFixed(2),
              porcentajeSeguro: (
                Math.round(parseInt(tr.porSeguro.toString()) * 100) / 100
              ).toFixed(2),
              condicionesPago: condicionPago,
              tipoTalon: tipo,
            };

            this.cuotas.data.push(cuota);
            this.cuotas._updateChangeSubscription();

            this.eyeService
              .getCatalogoConceptoImporteByParams(
                talon.tabla.toString(),
                "53",
                talon.claTalon.toString()
              )
              .subscribe((catalogo: CatalogoConceptoImporte) => {
                this.eye = ((Math.round(catalogo.importe) * 100) / 100).toFixed(
                  2
                );
              });

            this.iva = (
              Math.round(parseInt(tr.importeIva.toString()) * 100) / 100
            ).toFixed(2);
            this.subtotal = (
              Math.round(parseInt(tr.importeSubtotal.toString()) * 100) / 100
            ).toFixed(2);
            this.total = (
              Math.round(parseInt(tr.importeTotal.toString()) * 100) / 100
            ).toFixed(2);

            this.eyeService
              .getQueContiene(talon.tabla.toString(), talon.claTalon.toString())
              .subscribe((lstCo: Co[]) => {
                lstCo.forEach(element => {
                  this.queContiene.data.push(element);
                });

                this.queContiene._updateChangeSubscription();
                this.loading = true;
              });
          });
      });
  }

  cerrar() : void {
    this.dialogRef.close();
  }
}
