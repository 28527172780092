import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrintService } from '../../../../services/print.service';
import { MatPaginator, MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-print-detalle-poliza-pagados',
  templateUrl: './print-detalle-poliza-pagados.component.html',
  styleUrls: ['./print-detalle-poliza-pagados.component.css']
})
export class PrintDetallePolizaPagadosComponent implements OnInit {
  /* Declaración de variables */
  private dataSource: any[];
  private folioPoliza: any;
  private conceptoPoliza: any;
  private referencia: any;
  private fecha: any;
  private tipo: any;
  private sumaCargos: any;
  private sumaAbonos: any;
  private movimientos: any;
  public displayedColumns =  ['cuenta', 'nombre', 'concepto',
         'referencia', 'cargo', 'abono'];
  /* Inicializacion de variables para cargar métodos */
  constructor(private printService: PrintService) { }
  /* Carga de Datos para Impresión */
  ngOnInit() {
     this.dataSource = JSON.parse(sessionStorage.getItem("datosImpresion"));
     console.log(this.dataSource);
     this.folioPoliza = this.dataSource[0]['datos'][0]['folioPoliza'];
     this.conceptoPoliza = this.dataSource[0]['datos'][0]['conceptoPoliza'];
     this.referencia = this.dataSource[0]['datos'][0]['fechaRegistro'];
     this.fecha = this.dataSource[0]['datos'][0]['fechaRegistro'];
     this.tipo = this.dataSource[0]['datos'][0]['tipoReferencia'];
     this.movimientos = this.dataSource[0]['movimientos'];
     this.sumaCargos = this.dataSource[0]['sumaCargos'];
     this.sumaAbonos = this.dataSource[0]['sumaAbonos'];
  }
  /* Manda LLamar el servicio para la impresión de datos */
  ngAfterViewInit()
  {
    setTimeout(() => {
      console.log(this.dataSource);
      this.printService.isPrinting = true;
      console.log("Desde Detalle Poliza Pagados: " +this.printService.isPrinting);
      this.printService.datosListos();
    });

  }

}