import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IncentivoCorte } from '../../../interfaces/nomina-estibadores/incentivo-corte';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AuthService } from '../../../authentication/login/auth.service';
import { IncentivoDetalleCorte } from '../../../interfaces/nomina-estibadores/incentivo-detalle-corte';

const SISTEMA: number = 13;
const MODULO: number = 75;

const INCENTIVO: number = 30.00;

@Component({
  selector: 'app-dialog-incentivo-corte',
  templateUrl: './dialog-incentivo-corte.component.html',
  styleUrls: ['./dialog-incentivo-corte.component.css']
})
export class DialogIncentivoCorteComponent implements OnInit {
  private permisos;

  incentivoFormGroup: FormGroup;
  incentivoCorte: IncentivoCorte;
  lstFechasPeriodoCorte: IncentivoDetalleCorte[] = [];
  lstFechasPeriodoCorteInitialSelected: IncentivoDetalleCorte[] = [];

  montoLocalIncentivo: number = 0;

  diaDeCorte: boolean = false;

  constructor(public dialogRef: MatDialogRef<DialogIncentivoCorteComponent>,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { incentivoCorte: IncentivoCorte, lstFechasPeriodoCorte: IncentivoDetalleCorte[], diaDeCorte: boolean }) {
    this.permisos = this.authService.obtenerPermisosModulo(SISTEMA, MODULO);

    this.incentivoCorte = data.incentivoCorte;
    this.lstFechasPeriodoCorte = data.lstFechasPeriodoCorte;
    this.diaDeCorte = data.diaDeCorte;
    this.montoLocalIncentivo = this.incentivoCorte.montoIncentivo;


    this.incentivoCorte.lstIncentivoDetalleCorte.forEach(incentivoDetalle => {
      this.lstFechasPeriodoCorteInitialSelected.push(
        this.lstFechasPeriodoCorte[this.lstFechasPeriodoCorte.findIndex(fecha => new Date(fecha.fechaIncentivo).getTime() == new Date(incentivoDetalle.fechaIncentivo).getTime())]
      )
    });

  }

  ngOnInit(): void {
    this.incentivoFormGroup = this.formBuilder.group({
      comentarioAutorizaControl: [this.incentivoCorte.comentarioAutoriza, Validators.required],
      fechasPeriodoControl: [[]]
    });

    this.incentivoFormGroup.controls['fechasPeriodoControl'].setValidators(Validators.required);
    this.incentivoFormGroup.controls['fechasPeriodoControl'].setValue(this.lstFechasPeriodoCorteInitialSelected);

    if (this.incentivoCorte.idIncentivo != null) {

      this.incentivoFormGroup.controls['fechasPeriodoControl'].disable();
      this.incentivoFormGroup.controls['comentarioAutorizaControl'].disable();
    }
  }

  cerrarDialogo(): void {
    const array = {
      autorizar: false
    };
    this.dialogRef.close(array);
  }

  autorizar(): void {
    this.incentivoCorte.montoIncentivo = this.montoLocalIncentivo;
    this.incentivoCorte.comentarioAutoriza = this.incentivoFormGroup.controls['comentarioAutorizaControl'].value;
    this.incentivoCorte.lstIncentivoDetalleCorte = [];
    this.incentivoFormGroup.controls['fechasPeriodoControl'].value.forEach(fechaPeriodo => this.incentivoCorte.lstIncentivoDetalleCorte.push(fechaPeriodo));

    const array = {
      autorizar: true,
      incentivoCorte: this.incentivoCorte
    };
    this.dialogRef.close(array);
  }

  eliminar(): void {
    const array = {
      eliminar: true
    };
    this.dialogRef.close(array);
  }

  selectDays() {
    this.montoLocalIncentivo = INCENTIVO * (this.incentivoFormGroup.controls['fechasPeriodoControl'].value as IncentivoDetalleCorte[]).length;
  }

  getDateTime(date: string): string {
    let fecha: Date = new Date(date);
    return fecha.toLocaleString('es-MX',
      {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      }
    );
  }

}
