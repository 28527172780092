/*
* Título                    : oficina-actual.ts
* Descripción               : Estructura para los datos de la Oficina Actual
* Compañía                  : Transportes Castores de Baja California S.A. de C.V. – Área de Desarrollo
* Fecha de creación         : 2020-07-07
* Autor                     : German Galaviz [germagafra]
* Versión                   : 1.0.0
* ID Requerimiento/Ticket   : NULL
*/
export class OficinaActual {
  idOficina: number;
  clave: string;
  prefijo: string;
  plaza: string;
}
