import { Directive, ElementRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '../../authentication/login/auth.service';

@Directive({
  selector: '[hasPermission]'
})
export class HasPermissionDirectiveDirective {
  private currentUser;
  private systemModule = [];
  private permissions = [];
  private notOperator = false;
  private isHidden = true;

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.currentUser = this.authService.usuario;
    this.updateView();
  }

  @Input()
  set hasPermissionSystemModule(permSystemModule) {
    this.systemModule = permSystemModule;
    this.updateView();
  }

  @Input()
  set hasPermission(val) {
    this.permissions = val;
    this.updateView();
  }

  @Input()
  set hasPermissionDontShow(operator) {
    this.notOperator = operator;
    this.updateView();
  }

  private updateView() {
    if (this.checkPermission()) {
      if (this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }

  private checkPermission() {
    let hasPermission = false;

    if (!this.currentUser) return hasPermission;
    if (!this.currentUser.permisos) return hasPermission;

    let userPermissions;
    const systemFound = this.currentUser.permisos.find(permission => {
      if (permission.idSistema == this.systemModule[0]) {
        const moduleFound = permission.module.find(module => module.idModulo === this.systemModule[1]);
        if (moduleFound) {
          userPermissions = moduleFound.permission;
          return true;
        }
      }
    });

    if (!userPermissions) return hasPermission;

    this.permissions.forEach(permission => {
      if (userPermissions[permission] === 1) {
        if (!this.notOperator) {
          // Has permission and show
          hasPermission = true;
        }
      } else {
        if (this.notOperator) {
          // Has not permission but show
          hasPermission = true;
        }
      }
    });

    return hasPermission;
  }
}
