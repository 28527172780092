import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MAT_DIALOG_DATA, MatDialogRef, MatSelect } from '@angular/material';
import { Oficina } from '../../../interfaces/oficina';
import { ReplaySubject, Subject, forkJoin } from 'rxjs';
import { NominaEstibadoresService } from '../../../services/nomina-estibadores.service';
import { AuthService } from '../../../authentication/login/auth.service';
import { take, takeUntil } from 'rxjs/operators';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import * as _moment from 'moment';
import { EstibadorNuevo } from '../../../interfaces/nomina-estibadores/estibador-nuevo';

const SISTEMA: number = 13;
const MODULO: number = 75;

const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-dialog-periodo-corte',
  templateUrl: './dialog-periodo-corte.component.html',
  styleUrls: ['./dialog-periodo-corte.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class DialogPeriodoCorteComponent {
  public isLoading: boolean = false;
  private permisos;

  periodoCorteFormGroup: FormGroup;
  private oficinas: Oficina[];
  public oficinasFiltradas: ReplaySubject<Oficina[]> = new ReplaySubject<Oficina[]>(1);
  protected _onDestroy = new Subject<void>();

  public numeroEstibadoresNuevos: number = 0;

  @ViewChild('singleSelect', { static: false }) singleSelect: MatSelect;

  constructor(public dialogRef: MatDialogRef<DialogPeriodoCorteComponent>,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private nominaEstibadoresService: NominaEstibadoresService,
    @Inject(MAT_DIALOG_DATA) public data: {}) {
    this.permisos = this.authService.obtenerPermisosModulo(SISTEMA, MODULO);

    this.periodoCorteFormGroup = this.formBuilder.group({
      oficinaControl: ['', Validators.required],
      oficinaFiltroControl: '',
      fechaAperturaControl: [new FormControl(moment()), Validators.required],
      fechaCierreControl: [new FormControl(moment()), Validators.required]
    });

    if (this.permisos.g == 0) this.periodoCorteFormGroup.controls['oficinaControl'].disable();

    this.cargaOficinas();
    this.obtenerEstibadoresNuevos(parseInt(this.authService.usuario.idOficina));
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  protected setInitialValue() {
    this.oficinasFiltradas
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.singleSelect.compareWith = (a: Oficina, b: Oficina) => a && b && a.clave === b.clave;
      });
  }

  protected filtrarOficinas() {
    if (this.oficinas) {
      let filtro = this.periodoCorteFormGroup.controls['oficinaFiltroControl'].value;
      (filtro && (filtro = filtro.toLowerCase()))
       ? this.oficinasFiltradas.next(this.oficinas.filter(oficina => oficina.plaza.toLowerCase().indexOf(filtro) > -1 || oficina.clave.toLowerCase().indexOf(filtro) > -1))
       : this.oficinasFiltradas.next(this.oficinas.slice());
    }
  }

  /* Carga Todas las Oficinas */
  cargaOficinas(): void {
    this.isLoading = true;
    this.nominaEstibadoresService.obtenerOficinasConConfiguracionSinMatriz().subscribe(
      response => {
        this.oficinas = response as Oficina[];

        this.periodoCorteFormGroup.controls['oficinaControl'].setValue(this.oficinas[this.oficinas.findIndex(oficina => oficina.clave == this.authService.usuario.claveOficina)]);

        this.oficinasFiltradas.next(this.oficinas.slice());

        this.periodoCorteFormGroup.controls['oficinaFiltroControl'].valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filtrarOficinas();
          });

        this.isLoading = false;
      });
  }

  cerrarDialogo(): void {
    const array = {
      calcular: false
    };
    this.dialogRef.close(array);
  }

  calcular() {
    const array = {
      calcular: true,
      idOficina: this.periodoCorteFormGroup.controls['oficinaControl'].value.oficina,
      fechaAperturaCorte: this.periodoCorteFormGroup.controls['fechaAperturaControl'].value.toDate(),
      fechaCierreCorte: this.periodoCorteFormGroup.controls['fechaCierreControl'].value.toDate(),
      hayEstibadoresNuevos: this.numeroEstibadoresNuevos
    };
    this.dialogRef.close(array);
  }

  cambioDeOficina(): void {
    this.obtenerEstibadoresNuevos(this.periodoCorteFormGroup.controls['oficinaControl'].value.oficina);
  }

  obtenerEstibadoresNuevos(idOficina: number): void {
    this.isLoading = true;
    this.nominaEstibadoresService.obtenerEstibadoresNuevosActivos(idOficina).subscribe(response => {
      this.numeroEstibadoresNuevos += (response as EstibadorNuevo[]).length;

      this.obtenerEstibadoresNuevosAgenciasDeMatriz(idOficina);
    });
  }

  obtenerEstibadoresNuevosAgenciasDeMatriz(idOficina: number) {
    this.nominaEstibadoresService.obtenerOficinasAgenciasDeMatriz(idOficina.toString()).subscribe(response => {
      let arrOficinasAgencias = response;
      if (arrOficinasAgencias.length > 0) {
        var allObservablesEstibadoresNuevosAgencias = [];
        arrOficinasAgencias.forEach(oficina => {
          allObservablesEstibadoresNuevosAgencias.push(this.nominaEstibadoresService.obtenerEstibadoresNuevosActivos(oficina.oficina));
        });

        forkJoin(allObservablesEstibadoresNuevosAgencias)
          .subscribe((response) => {
            arrOficinasAgencias.forEach((_oficina, index) => {
              this.numeroEstibadoresNuevos += (response[index] as EstibadorNuevo[]).length;
            });
            this.isLoading = false;
          });
      } else {
        this.isLoading = false;
      }
    });
  }

}
