import { Component, Input, OnInit } from "@angular/core";
import { AuthService } from "../../../authentication/login/auth.service";

@Component({
  selector: "app-header-paqueteria",
  templateUrl: "./header-paqueteria.component.html",
  styleUrls: ["./header-paqueteria.component.css"],
})
export class HeaderPaqueteriaComponent implements OnInit {
  oficina = this.authService.usuario.plazaOficina;
  fecha: number = Date.now();
  
  @Input() tituloHeader: string;

  constructor(
    private authService: AuthService
  ) {}

  ngOnInit() {}
}
