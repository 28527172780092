import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { CorteEstibadorNuevo } from '../../../interfaces/nomina-estibadores/corte-estibador-nuevo';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-dialog-corte-estibador-nuevo',
  templateUrl: './dialog-corte-estibador-nuevo.component.html',
  styleUrls: ['./dialog-corte-estibador-nuevo.component.css']
})
export class DialogCorteEstibadorNuevoComponent {

  public displayedColumns = ['estibadorNombre', 'estibadorClasificacion', 'estibadorOrden', 'totalCorte'];
  private dataSourceCorteEstibadorNuevo: any = new MatTableDataSource();

  public lstCorteEstibadorNuevo: CorteEstibadorNuevo[] = [];

  @ViewChild('TablaCorteEstibadorNuevoPaginator', { static: false }) set tablaCorteEstibadorNuevoPaginator(tablaCorteEstibadorNuevoPaginator: MatPaginator) {
    if (tablaCorteEstibadorNuevoPaginator != undefined && tablaCorteEstibadorNuevoPaginator != null) {
      this.dataSourceCorteEstibadorNuevo.paginator = tablaCorteEstibadorNuevoPaginator;
      this.dataSourceCorteEstibadorNuevo.paginator._intl.itemsPerPageLabel = 'Resultados por página';
    }
  }

  @ViewChild('TablaCorteEstibadorNuevoSort', { static: false }) set tablaCorteEstibadorNuevoSort(tablaCorteEstibadorNuevoSort: MatSort) {
    if (tablaCorteEstibadorNuevoSort != undefined && tablaCorteEstibadorNuevoSort != null) {
      this.dataSourceCorteEstibadorNuevo.sort = tablaCorteEstibadorNuevoSort;
    }
  }

  constructor(
    public dialogRef: MatDialogRef<DialogCorteEstibadorNuevoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { lstCorteEstibadorNuevo: CorteEstibadorNuevo[] }) {

    this.lstCorteEstibadorNuevo = data.lstCorteEstibadorNuevo as CorteEstibadorNuevo[];
    this.dataSourceCorteEstibadorNuevo = new MatTableDataSource<CorteEstibadorNuevo>(this.lstCorteEstibadorNuevo);
    this.dataSourceCorteEstibadorNuevo.sort = this.tablaCorteEstibadorNuevoSort;
  }

  cerrarDialogo(): void {
    const array = {
      'respuesta': false,
    };
    this.dialogRef.close(array);
  }

  getTotal(): number {
    return this.lstCorteEstibadorNuevo.map(t => (t.montoSueldoFijo + (t.montoAgencias ? t.montoAgencias : 0))).reduce((acc, value) => acc + value, 0);
  }

}
