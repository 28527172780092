import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { iSolicitudEyE } from '../../../interfaces/paqueteria/solicitud-material-eye';

@Component({
  selector: 'app-confirmar-datos-solicitud',
  templateUrl: './confirmar-datos-solicitud.component.html',
  styleUrls: ['./confirmar-datos-solicitud.component.css']
})
export class ConfirmarDatosSolicitudComponent implements OnInit {

  displayedColumns = ['position', 'talon', 'nombreMaterial', 'cantidad'];

  constructor(
    public dialogRef: MatDialogRef<ConfirmarDatosSolicitudComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {datosSolicitud: iSolicitudEyE[]}
  ) { 
    if (data.datosSolicitud[0].talon == "") {
      this.displayedColumns.splice(1, 1);
    }
  }

  ngOnInit() {
  }

  aceptar() {
    this.dialogRef.close(true);
  }

  cancelar() {
    this.dialogRef.close(false);
  }

}
