import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { AuthService } from "../authentication/login/auth.service";
import { AppsettingsComponent } from "../app-settings/appsettings.component";

import { MatSnackBar } from "@angular/material";
import { Usuario } from "../interfaces/usuario";
import { Personal } from "../interfaces/personal";
/*
 * Título                    : cat-prod-aut.service.ts
 * Descripción               : Service para el apartado de Catálogo Producto Autenticado
 * Compañía                  : Transportes Castores de Baja California S.A. de C.V. – Área de Desarrollo
 * Fecha de creación         : 2020-03-06
 * Autor                     : Paulina Muñoz [Lavande94]
 * Versión                   : 1.0.0
 * ID Requerimiento/Ticket   : NULL
 */
@Injectable({
  providedIn: "root",
})
export class UsuarioService {
  constructor(
    private appsettings: AppsettingsComponent,
    private authService: AuthService,
    private http: HttpClient,
    private router: Router,
    public snackBar: MatSnackBar
  ) {}
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 10000,
    });
  }

  /*
 
	* Título                    : updateCredenciales
	* Descripción               : actualización de Credenciales al iniciar por primera vez
	* Fecha de creación         : 2020-03-06
	* Autor                     : Paulina Muñoz [Lavande94]
	* Versión                   : 1.0.0
	* ID Requerimiento/Ticket   : NULL
	*/
  updateCredenciales(idUsuario: number, passaAc: string, passNew: string) {
    return this.http
      .get(
        `${this.appsettings.API_ENDPOINT}personal/updatePassword/${idUsuario}/${passaAc}/${passNew}`
      )
      .subscribe(
        (data) => {
          this.router.navigate(["/authentication/login"]);
          this.openSnackBar(
            "Credenciales actualizadas, todo listo para iniciar sesión.",
            "☑️"
          );
        },
        (error) => this.openSnackBar(error.error.message, "🔔")
      );
  }

  /*
 
	* Título                    : resetPassword
	* Descripción               : actualización dede Credenciales por soporte
	* Fecha de creación         : 2020-03-06
	* Autor                     : Paulina Muñoz [Lavande94]
	* Versión                   : 1.0.0
	* ID Requerimiento/Ticket   : NULL
	*/
  resetPassword(idPersonal: number, idUsuarioModificacion: number) {
    return this.http
      .get(
        `${this.appsettings.API_ENDPOINT}personal/resetPassword/${idPersonal}/${idUsuarioModificacion}`
      )
      .pipe(
        map((response: any) => response),
        catchError((e) => {
          // Badrequest validacion desde el backend
          if (e.status === 400) {
            return throwError(e);
          }
          if (e.error.mensaje) {
            console.error(e.error.mensaje);
          }
          console.error(e.error.mensaje);

          return throwError(e);
        })
      );
  }

  getUsuarioByIdUsuario(idUsuario: number): Observable<Usuario> {
    return this.http.get<Usuario>(
      this.appsettings.API_ENDPOINT +
        `personal/findUsuarioByidUsuario/${idUsuario}`
    );
  }
}
