import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatDialogRef, MAT_DIALOG_DATA, MatAutocompleteSelectedEvent, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { InformacionValesCorte } from '../../../interfaces/nomina-estibadores/informacion-vales-corte';

@Component({
  selector: 'app-vales-nomina-dialog',
  templateUrl: './vales-nomina-dialog.component.html',
  styleUrls: ['./vales-nomina-dialog.component.css']
})
export class ValesNominaDialogComponent {

  public displayedColumns = ['idVale', 'total', 'unidad', 'folioViaje', 'noGuia', 'fecha', 'elaboro'];
  private dataSourceManiobrasNaturales: any = new MatTableDataSource();
  private dataSourceManiobrasEspeciales: any = new MatTableDataSource();

  public lstValesManiobrasNaturales: InformacionValesCorte[] = [];
  public lstValesManiobrasEspeciales: InformacionValesCorte[] = [];

  @ViewChild('TablaManiobrasNaturalesPaginator', { static: false }) set tablaManiobrasNaturalesPaginator(tablaManiobrasNaturalesPaginator: MatPaginator) {
    if (tablaManiobrasNaturalesPaginator != undefined && tablaManiobrasNaturalesPaginator != null) {
      this.dataSourceManiobrasNaturales.paginator = tablaManiobrasNaturalesPaginator;
      this.dataSourceManiobrasNaturales.paginator._intl.itemsPerPageLabel = 'Resultados por página';
    }
  }

  @ViewChild('TablaManiobrasNaturalesSort', { static: false }) set tablaManiobrasNaturalesSort(tablaManiobrasNaturalesSort: MatSort) {
    if (tablaManiobrasNaturalesSort != undefined && tablaManiobrasNaturalesSort != null) {
      this.dataSourceManiobrasNaturales.sort = tablaManiobrasNaturalesSort;
    }
  }

  @ViewChild('TablaManiobrasEspecialesPaginator', { static: false }) set tablaManiobrasEspecialesPaginator(tablaManiobrasEspecialesPaginator: MatPaginator) {
    if (tablaManiobrasEspecialesPaginator != undefined && tablaManiobrasEspecialesPaginator != null) {
      this.dataSourceManiobrasEspeciales.paginator = tablaManiobrasEspecialesPaginator;
      this.dataSourceManiobrasEspeciales.paginator._intl.itemsPerPageLabel = 'Resultados por página';
    }
  }

  @ViewChild('TablaManiobrasEspecialesSort', { static: false }) set tablaManiobrasEspecialesSort(tablaManiobrasEspecialesSort: MatSort) {
    if (tablaManiobrasEspecialesSort != undefined && tablaManiobrasEspecialesSort != null) {
      this.dataSourceManiobrasEspeciales.sort = tablaManiobrasEspecialesSort;
    }
  }

  constructor(
    public dialogRef: MatDialogRef<ValesNominaDialogComponent>,
    breakpointObserver: BreakpointObserver,
    @Inject(MAT_DIALOG_DATA) public data: { lstValesManiobrasNaturales: InformacionValesCorte[], lstValesManiobrasEspeciales: InformacionValesCorte[] }) {

    this.lstValesManiobrasNaturales = data.lstValesManiobrasNaturales as InformacionValesCorte[];
    this.dataSourceManiobrasNaturales = new MatTableDataSource<InformacionValesCorte>(this.lstValesManiobrasNaturales);
    this.dataSourceManiobrasNaturales.sort = this.tablaManiobrasNaturalesSort;

    this.lstValesManiobrasEspeciales = data.lstValesManiobrasEspeciales as InformacionValesCorte[];
    this.dataSourceManiobrasEspeciales = new MatTableDataSource<InformacionValesCorte>(this.lstValesManiobrasEspeciales);
    this.dataSourceManiobrasEspeciales.sort = this.tablaManiobrasEspecialesSort;
  }

  cerrarDialogo(): void {
    const array = {
      'respuesta': false,
    };
    this.dialogRef.close(array);
  }

  getTotalMN(): number {
    return this.lstValesManiobrasNaturales.map(t => t.total).reduce((acc, value) => acc + value, 0);
  }

  getTotalME(): number {
    return this.lstValesManiobrasEspeciales.map(t => t.total).reduce((acc, value) => acc + value, 0);
  }

}
