import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { Oficina } from "../interfaces/oficina";
import { OficinaCompleta } from "../interfaces/paqueteria/Oficina";

import { AppsettingsComponent } from "../app-settings/appsettings.component";
/*
 * Título                    : oficina.service.ts
 * Descripción               : Service para el apartado de Catálogo de Oficinas
 * Compañía                  : Transportes Castores de Baja California S.A. de C.V. – Área de Desarrollo
 * Fecha de creación         : 2020-07-13
 * Autor                     : German Galaviz [germagafra]
 * Versión                   : 1.0.0
 * ID Requerimiento/Ticket   : NULL
 */
@Injectable()
export class OficinaService {
  constructor(
    private appsettings: AppsettingsComponent,
    private http: HttpClient,
    private router: Router
  ) { }

  /*
   * Título                    : findPolizaPagadosByAll
   * Descripción               : Obtención de las oficinas Activas
   * Fecha de creación         : 2020-07-13
   * Autor                     : German Galaviz [germagafra]
   * Versión                   : 1.0.0
   * ID Requerimiento/Ticket   : NULL
   */
  findOficinasByAll(): Observable<any> {
    return this.http.get<Oficina[]>(
      this.appsettings.API_ENDPOINT + `oficina/findOficinaByAll`
    );
  }

  findOficinaByClave(clave: string): Observable<OficinaCompleta> {
    return this.http.get<OficinaCompleta>(
      this.appsettings.API_ENDPOINT + `oficina/findOficinaByClave/${clave}`
    );
  }

  findOficinaById(idOficina: number): Observable<OficinaCompleta> {
    return this.http.get<OficinaCompleta>(this.appsettings.API_ENDPOINT + `oficina/findOficinaByIdOficina/${idOficina}`);
  }
}
