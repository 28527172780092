import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { data } from 'jquery';
import { AuthService } from '../../../authentication/login/auth.service';
import { OficinaActual } from '../../../interfaces/oficina-actual';
import { Trip } from '../../../interfaces/trip';
import { Viaje } from '../../../interfaces/viaje';
import { ComplementoCartaPorteService } from '../../../services/complemento-carta-porte.service';
import { PrintService } from '../../../services/print.service';
import { TalonesService } from '../../../services/talones.service';
import { CorrecionTimbradoComponent } from '../../modals/correcion-timbrado/correcion-timbrado.component';
import { PdfDialogComponent } from '../../modals/pdf-dialog/pdf-dialog.component';

@Component({
  selector: 'app-guides-accordion',
  templateUrl: './guides-accordion.component.html',
  styleUrls: ['./guides-accordion.component.css']
})
export class GuidesAccordionComponent implements OnInit {
  @Input() trip: Viaje;
  tripFile: Trip;
  guides = [];
  showLoading = false;
  error: string;

  constructor(
    public talonesService: TalonesService,
    public ccpService: ComplementoCartaPorteService,
    private authService: AuthService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public printService: PrintService
    ) { }

  ngOnInit() {

    // this.findTalonesByViaje(this.trip.idviaje, this.trip.idoficina, this.trip.fechaviaje)
  }

  async findTalonesByViaje(idviaje: any, idoficina: any, fechaviaje: any) {
    try {
      this.showLoading = true;
      this.guides = await this.talonesService.findTalonesByViaje(
        idviaje,
        idoficina,
        fechaviaje
      );
      if (Object.entries(this.guides).length <= 0) {
        this.guides = [];
      }
      this.showLoading = false;
    } catch (error) {
      this.guides = [];
      console.error(error);
      this.showLoading = false;
    }
  }

  async openFixTimbradoDiagolg(talon) {
    // const loaderDialogRef = this.dialog.open(CustomLoaderComponent);
    try {
      // loaderDialogRef.close();
      const oficina = await this.authService.oficinaActual;
      const dialogRef = this.dialog.open(CorrecionTimbradoComponent, {
        width: '55%',
        height: '80%',
        data: {
          talon: talon,
          oficina: oficina
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        // loaderDialogRef.close();
        console.log('The dialog was closed');
      });
    } catch (error) {
      // loaderDialogRef.close();
      this.snackBar.open('Error, aún no existe el PDF', '⛔', {
        duration: 3000
      });
    }
  }
}

export interface Talon {
  'ccp': number;
  'no_guia': string;
  'cla_talon': string;
  'foliosat': string;
}
