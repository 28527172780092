import { Component, OnInit } from '@angular/core';
import { CabeceraImp } from '../../../interfaces/cabeceraimp';

@Component({
  selector: 'app-layout-header-print',
  templateUrl: './layout-print-header.component.html',
  styleUrls: ['./layout-print-header.component.css']
})
/*
* Título                    : layout-header-print
* Descripción               : Layout - Header para imprimir
* Compañía                  : Transportes Castores de Baja California S.A. de C.V. – Área de Desarrollo
* Fecha de creación         : 2020-06-29
* Autor                     : German Galaviz [germagafra]
* Versión                   : 1.0.0
* ID Requerimiento/Ticket   : NULL
*/
export class LayoutPrintHeaderComponent implements OnInit {
	fechaImpresion: any = new Date();
	dataCabeceras:CabeceraImp[];
  	constructor() { }
  /* Obtiene los datos de las cabeceras para impresión */
  ngOnInit() {
  	setTimeout(() => {
  		this.dataCabeceras = JSON.parse(sessionStorage.getItem("cabecerasImpresion"));
  		console.log(this.dataCabeceras);
  	});
  }
}
