import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef } from '@angular/material/dialog';
// SERVICES
import { TalonesErrorService } from '../../../services/talones-error.service';

export interface TableData {
  nombre: string;
  descripcion: string;
}

@Component({
  selector: 'app-correccion-timbrado-tipo',
  templateUrl: './correccion-timbrado-tipo.component.html',
  styleUrls: ['./correccion-timbrado-tipo.component.css']
})

export class CorreccionTimbradoTipoComponent implements OnInit {
  // Config tabla
  displayedColumns: string[] = ['nombre', 'descripcion', 'error'];
  dataSource: MatTableDataSource<TableData>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public dialogRef: MatDialogRef<CorreccionTimbradoTipoComponent>,
    public talonesService: TalonesErrorService) {
    // Obtener el catálogo de tipos de correcciones
    this.talonesService.findAllTipoError().subscribe(
      respuesta => {
        this.dataSource = new MatTableDataSource<TableData>(respuesta as TableData[])
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    );
  }

  ngOnInit() {}

  // Filtro de la tabla
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  // Cerrar modal
  onNoClick(): void {
    this.dialogRef.close();
  }
}
