import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource } from "@angular/material";
import { SolicitarMaterialComponent } from "../../../systems/paqueteria/solicitar-material/solicitar-material.component";
import { ReporteSolicitudesMaterial } from "../../../interfaces/paqueteria/reporte-solicitudes-material";
import { DatosSeguimiento, DatosSeguimientoSap, SalidaMaterial, Material } from '../../../interfaces/paqueteria/datos-seguimiento';
import { EyeService } from "../../../services/eye.service";
import { Materiales } from "../../../interfaces/paqueteria/materiales";
import { cadenaMaterialesCompleta } from "../../helpers/helpers";

export interface PeriodicElement {
  name: string;
  weight: number;
  symbol: string;
}

@Component({
  selector: "app-seguimiento-solicitud-eye",
  templateUrl: "./seguimiento-solicitud-eye.component.html",
  styleUrls: ["./seguimiento-solicitud-eye.component.css"],
})
export class SeguimientoSolicitudEyeComponent implements OnInit {
  estatus: string = "salida";
  lstDatosSeguimiento: DatosSeguimiento[] = [];
  public lstMateriales: SalidaMaterial[] = [];
  private  cadenaMateriales: string[] = cadenaMaterialesCompleta;
  displayedColumns = ["nombreMaterial", "cantidadSolicitada",  "cantidadSurtida", "unidadMedida", "fecha", "talon", "docLogistico"];
  private nombreMateriales: Materiales[] = [];
  public lstDatosSeguimientoSap: DatosSeguimientoSap = {} as DatosSeguimientoSap;

  dataSource = new MatTableDataSource<SalidaMaterial>([]);
  constructor(
    public dialogRef: MatDialogRef<SolicitarMaterialComponent>,
    @Inject(MAT_DIALOG_DATA) public datosReporte: ReporteSolicitudesMaterial,
    private eyeService: EyeService,
  ) { }

  async ngOnInit() {
    this.obtenerDatos();
    await this.getMateriales();
    await this.getNombreMateriales();
  }

  obtenerDatos() {
    this.eyeService
      .getDatosSeguimientoByIdSolicitud(this.datosReporte.idSolicitud)
      .subscribe((lstDatos: DatosSeguimiento[]) => {
        lstDatos.forEach((element) => {
          const fechaHora = new Date(`${element.fecha}T${element.hora}`);
          element.fecha = Date.parse(fechaHora.toDateString()).toString();
          element.hora = `${fechaHora.getHours()}:${fechaHora.getMinutes()}:${fechaHora.getSeconds()}`;
          this.lstDatosSeguimiento.push(element);
        });

        const lastIndex = this.lstDatosSeguimiento.length - 1;
        this.lstDatosSeguimiento[lastIndex].ultimo = true;
         

      });
  }

  private async getMateriales() {
    await this.eyeService.getTalonesMaterial(this.datosReporte.idSolicitud, this.datosReporte.tipoSolicitud).toPromise().then((lstSalidaMaterial: SalidaMaterial[]) => {
       let unidadesMedida: string[] = [];
        lstSalidaMaterial.map((element : SalidaMaterial) => {
          const material: SalidaMaterial = this.lstMateriales.find((material) => material.idMaterial === element.idMaterial);
          if (material) {
            unidadesMedida.push(element.unidadMedida);
            material.cantidadSolicitada += element.cantidadSolicitada;
            material.unidadMedida = unidadesMedida.sort((a,b) => unidadesMedida.filter(v => v===a).length - unidadesMedida.filter(v => v===b).length).pop();
          } else {
            unidadesMedida = [];
            this.lstMateriales.push(element);
          }
        });

        if(this.datosReporte.tipoSolicitud != "venta"){
          this.lstMateriales.map((element) => {
            if (element.unidadMedida == "mts") {
              element.cantidadSolicitada = this.eyeService.calcularCantidad(element.idMaterial, element.cantidadSolicitada);
              element.unidadMedida = this.eyeService.convertirUnidadMedida(element.idMaterial, element.unidadMedida);
            }
          });
        }
      });
  }

  private getSeguimientoSolicitudSap() {
    this.eyeService.getSolicitudSap(this.datosReporte.folio.toString()).subscribe({
      next: (response: DatosSeguimientoSap) => {
        this.lstDatosSeguimientoSap = response;
        this.dataSource.data.map((element) => {
          const material : Material = this.lstDatosSeguimientoSap.listMaterial.find((material) => material.material == element.claveMaterial.toString());
          if (material) {
            element.cantidadSurtida = parseInt(material.listSurtido[0].cantidadSurtida);
            element.docLogistico = material.listSurtido[0].docLogistico;
            element.talon = material.listSurtido[0].talon;
            element.fecha = material.listSurtido[0].fechaRegistro.replace(/(\d{4})(\d{2})(\d{2})/, '$2/$3/$1');
          }
        });
      },
    });
  }
    

  private async getNombreMateriales() {
  await this.eyeService.getMaterialesByNombre(this.cadenaMateriales).toPromise().then((lstMateriales: Materiales[]) => {
    this.nombreMateriales = lstMateriales;
    this.lstMateriales.map((element) => {
      const material = this.nombreMateriales.find((material) => material.idMaterial === element.idMaterial);
      element.nombreMaterial = material.nombre;
    });
    this.dataSource = new MatTableDataSource<SalidaMaterial>(this.lstMateriales);

    let estatusFilter: string[] = ["pendiente", "rechazada"];
    if (!estatusFilter.includes(this.datosReporte.estatus.toLowerCase())) {
      this.getSeguimientoSolicitudSap();
    }
  });

}

  cerrar() {
    this.dialogRef.close();
  }
}
